import React, {useState} from 'react';
import {Link, withRouter} from "react-router-dom";
import {Helmet} from "react-helmet";
import localization from "../../localization";



const CheckInbox = ({history}) => {
    return (
        <div className='page-landing'>
            <Helmet>
                <title>Check Inbox | {process.env.REACT_APP_PROJECT_NAME}</title>
                <meta name="description" content="Create an account for Last Link." />
            </Helmet>
            <div className='landing--content'>
                <div className='landing--logo'>
                    <img alt='logo' src={`/images/${process.env.REACT_APP_PROJECT}-logo-big.svg`} />
                </div>
                <div className='landing--title-smaller'>
                    {localization.landingCheckCheck}
                </div>
                <div className='landing--subtitle'>
                    {localization.landingCheckEmailed} {localStorage.getItem('email')}. Click the link to access your account.
                </div>
                <button
                    className='submit-button'
                    onClick={() => history.push('/login')}
                >
                    {localization.landingCheckOk}
                </button>
            </div>
        </div>
    );
};

export default withRouter(CheckInbox);