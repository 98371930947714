import React, {useContext, useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Link, withRouter} from "react-router-dom";
import classNames from "classnames";
import Page from "../../components/page/Page";
import moment from "moment";
import api from "../../api";
import {store} from "../../store";


const events = [
    {
        name: 'Introduction to XR',
        type: 'Confirmed',
        date: 'Sat, 29 May, 10:00AM - 4:00PM AST',
        link: ''
    },
    {
        name: 'Ideas, Collaboration and IP',
        type: 'Confirmed',
        date: 'Fri, 4 Jun, 4:00PM - 10:00PM AST',
        link: ''
    },
    {
        name: 'XR Content and Ethics',
        type: 'Confirmed',
        date: 'Sat, 5 Jun, 12:30PM - 6:30PM AST',
        link: ''
    },
    {
        name: 'XR Storytelling',
        type: 'Confirmed',
        date: 'Fri, 11 Jun, 3:00PM - 8:05PM AST',
        link: ''
    },
    {
        name: 'XR Storytelling',
        type: 'Confirmed',
        date: 'Sat, 12 Jun, 12:30PM - 4:05PM AST',
        link: ''
    },
    {
        name: 'UX Design for XR',
        type: 'Confirmed',
        date: 'Fri, 18 Jun, 4:00PM - 10:00PM AST',
        link: ''
    },
    {
        name: 'Gaming Engines',
        type: 'Confirmed',
        date: 'Sat, 19 Jun, 12:30PM - 5:30PM AST',
        link: ''
    },
    {
        name: 'Interactivity in XR',
        type: 'Confirmed',
        date: 'Fri, 25 Jun, 4:00PM - 10:00PM AST',
        link: ''
    },
    {
        name: 'Interactivity in XR',
        type: 'Confirmed',
        date: 'Sat, 26 Jun, 12:30PM - 6:30PM AST',
        link: ''
    },
    {
        name: 'Producing VR 360 Content',
        type: 'Confirmed',
        date: 'Fri, 2 Jul, 4:00PM - 10:00PM AST',
        link: ''
    },
    {
        name: 'Producing VR 360 Content',
        type: 'Confirmed',
        date: 'Sat, 3 Jul, 12:30PM - 6:30PM AST',
        link: ''
    },
    {
        name: 'Producing AR and 3D Assets',
        type: 'Confirmed',
        date: 'Fri, 9 Jul, 4:00PM - 10:00PM AST',
        link: ''
    },
    {
        name: 'Producing AR and 3D Assets',
        type: 'Confirmed',
        date: 'Sat, 10 Jul, 12:30PM - 6:30PM AST',
        link: ''
    },
    {
        name: 'XR Audio Production',
        type: 'Confirmed',
        date: 'Fri, 16 Jul, 4:00PM - 10:00PM AST',
        link: ''
    },
    {
        name: 'XR Audio Production',
        type: 'Confirmed',
        date: 'Sat, 17 Jul, 12:30PM - 6:30PM AST',
        link: ''
    },
    {
        name: 'Motion Capture for XR',
        type: 'Confirmed',
        date: 'Fri, 30 Jul, 4:00PM - 10:00PM AST',
        link: ''
    },
    {
        name: 'Pitching Ideas',
        type: 'Confirmed',
        date: 'Sat, 31 Jul, 12:30PM - 6:30PM AST',
        link: ''
    },
];

const Reception = ({history}) => {
    const {currentUser} = useContext(store);
    const [tab, setTab] = useState(0);
    return (
        <Page
            className='reception'
        >
            <Helmet>
                <title>Reception | {process.env.REACT_APP_PROJECT_NAME}</title>
                <meta name="description" content="Ithra | About Ithra" />
            </Helmet>
            <div className='page-1'>
                <img alt='lock' src='/images/reception.png' />
                <div className='page-1--content'>
                    <div className='page-1--content--title'>
                        Welcome to Creative Solutions Program 2021
                    </div>
                    <video controls>
                        <source src='https://creativesolutions.ithra.com/video/Creative-Solutions-Landing-Page.mp4' type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
            <div className='page-2'>
                <div className='page-2--content'>
                    <div className='page-2--content--title'>
                        Quick Links
                    </div>
                    <div className='page-2--content--blocks'>
                        <Link to='/account/edit'>
                            <div className='page-2--content--block'>
                                <img alt='lock' src='/images/reception-1.png' />
                                <div className='page-2--content--block--title'>
                                    Update my Profile
                                </div>
                                <div className='page-2--content--block--content'>
                                    Help others learn and connect with you
                                </div>
                            </div>
                        </Link>
                        <Link to='/w/CSPonboarding'>
                            <div className='page-2--content--block'>
                                <img alt='lock' src='/images/reception-2.png' />
                                <div className='page-2--content--block--title'>
                                    Onboarding Room
                                </div>
                                <div className='page-2--content--block--content'>
                                    Meet, Greet, and Learn more...
                                </div>
                            </div>
                        </Link>
                        <Link to='/contacts'>
                            <div className='page-2--content--block'>
                                <img alt='lock' src='/images/reception-3.png' />
                                <div className='page-2--content--block--title'>
                                    Meet the Cohort and Everyone else
                                </div>
                                <div className='page-2--content--block--content'>
                                    Find and start making connections
                                </div>
                            </div>
                        </Link>
                        <Link to='/announce'>
                            <div className='page-2--content--block'>
                                <img alt='lock' src='/images/reception-4.png' />
                                <div className='page-2--content--block--title'>
                                    Announcements
                                </div>
                                <div className='page-2--content--block--content'>
                                    Stay up-to-date with announcements
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='reception--page-3'>
                <div>
                    <div className='reception--page-3--title'>
                        Agenda
                    </div>
                    {/*<div className='reception--page-3--tabs'>
                        <div
                            onClick={() => setTab(0)}
                            className={classNames({'reception--page-3--tabs--active': tab === 0})}
                        >Sun, May 30th
                        </div>
                        <div
                            onClick={() => setTab(1)}
                            className={classNames({'reception--page-3--tabs--active': tab === 1})}
                        >Mon, May 31st
                        </div>
                        <div
                            onClick={() => setTab(2)}
                            className={classNames({'reception--page-3--tabs--active': tab === 2})}
                        >
                            Tue, Jun 1st
                        </div>
                        <div
                            onClick={() => setTab(3)}
                            className={classNames({'reception--page-3--tabs--active': tab === 3})}
                        >
                            Wed, Jun 2nd
                        </div>
                        <div
                            onClick={() => setTab(4)}
                            className={classNames({'reception--page-3--tabs--active': tab === 4})}
                        >
                            Thu, Jun 4th
                        </div>
                        <div
                            onClick={() => setTab(5)}
                            className={classNames({'reception--page-3--tabs--active': tab === 5})}
                        >
                            Fri, Jun 5th
                        </div>
                        <div
                            onClick={() => setTab(6)}
                            className={classNames({'reception--page-3--tabs--active': tab === 6})}
                        >
                            Sat, Jun 6th
                        </div>
                    </div>*/}
                    {/*{tab === 0 ? (*/}
                    {true ? (
                        <div>
                            {events.map((item, index) => (
                                <div
                                    key={index}
                                    className='reception--page-3--item'
                                >
                                    <div className='flex-between'>
                                        <div className='reception--page-3--item--title'>
                                            {item.name}
                                        </div>
                                        <div className='reception--page-3--item--time'>
                                            {item.date}
                                        </div>
                                    </div>
                                    <div className='reception--page-3--item--type'>
                                        {item.type}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : null}
                </div>
            </div>
        </Page>
    );
};

export default withRouter(Reception);