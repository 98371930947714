import React from 'react';
import classNames from 'classnames';
import Avatar from "./Avatar";


const Person = ({image, title, subtitle, description, content, fullName, isSmaller, isImageNotRounded, isBlackDescription, selectPerson}) => {
    return (
        <div className={classNames('person', {'person--smaller': isSmaller})}>
            <Avatar
                className={classNames('person--image', {'person--image--not-rounded': isImageNotRounded})}
                image={image}
                fullName={fullName || title}
                onClick={selectPerson}
            />
            <div>
                <div
                    className='person--title'
                    onClick={selectPerson}
                >
                    <div>{title}</div>
                    <div className='person--title--subtitle'>{subtitle}</div>
                </div>
                <div className={classNames('person--description', {'person--description--black': isBlackDescription})}>
                    {description}
                </div>
                {content ? (
                    <div className='person--content'>
                        {content}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default Person;
