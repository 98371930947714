import React from 'react';
import classNames from 'classnames';
import localization from "../../localization";

const ModalConfirmation = ({title, description, confirm, cancel, onCancel, onConfirm}) => {
    return (
        <div className={classNames('modal-confirmation')}>
            <div className='modal-confirmation--background'/>
            <div className='modal-confirmation--dialog'>
                <div className='modal-confirmation--content'>
                    {/*<img alt='grid' src='/images/attention.png' />*/}
                    <div>
                        <div className='modal-confirmation--title'>
                            {title}
                        </div>
                        <div className='modal-confirmation--description'>
                            {description}
                        </div>
                    </div>
                </div>
                <div className='modal-confirmation--actions'>
                    <button
                        className='bordered-button'
                        onClick={onCancel}
                    >
                        {cancel}
                    </button>
                    <button
                        className='action-button'
                        onClick={() => {
                            onConfirm();
                            onCancel();
                        }}
                    >
                        {confirm}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ModalConfirmation;