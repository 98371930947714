import React from 'react';
import classNames from 'classnames';


const Block = ({title, children, isContentGray, isNoPadding}) => {
    return (
        <div className={classNames('block', {'block--no-padding': isNoPadding})}>
            {title ? (
                <div className='block--title'>
                    {title}
                </div>
            ) : null}
            {children ? (
                <div className={classNames('block--content', {'block--content--gray': isContentGray})}>
                    {children}
                </div>
            ) : null}
        </div>
    );
};

export default Block;
