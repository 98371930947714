import React, {useContext, useEffect, useState} from 'react';
import { ZoomMtg } from "@zoomus/websdk";
import {store} from "../../../store";
import "@zoomus/websdk/dist/css/bootstrap.css"
import "@zoomus/websdk/dist/css/react-select.css"


ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.6/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();

ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');


const Call = ({data, setSelectedOrganizationId, eventParticipants, onClose}) => {
    const {currentUser} = useContext(store);

    useEffect(() => {
        const API_KEY = "FnW2oTQAQeO1ine0MFHFJw";
        const API_SECRET = "3pdBGzhzQZSR3UTYlua8u4ZobmVxUuk6nkBX";
        const meetingNumber = 95070186445;
        const passWord = '026550';

        ZoomMtg.generateSignature({
            meetingNumber,
            apiKey: API_KEY,
            apiSecret: API_SECRET,
            role: 0,
            success: function ({result}) {
                ZoomMtg.init({
                    leaveUrl: window.location.href,
                    isSupportAV: true,
                    success: (success) => {
                        ZoomMtg.join({
                            signature: result,
                            meetingNumber: meetingNumber,
                            userName: `${currentUser.firstName} ${currentUser.lastName}`,
                            apiKey: API_KEY,
                            userEmail: currentUser.email,
                            passWord: passWord,
                            success: (success) => {
                                console.log(success)
                            },
                            error: (error) => {
                                console.log(error)
                            }
                        })

                    },
                    error: (error) => {
                        console.log(error)
                    }
                })
            },
        });
        document.getElementById('zmmtg-root').style.display = 'block';
    }, []);

    return null;
    // return (
    //     <div className='call'>
    //         <div className='call--back'>
    //             <button className='action-button' onClick={onClose}>
    //                 Close
    //             </button>
    //         </div>
    //         {/*<div className='call--header'>*/}
    //         {/*    {data.name}*/}
    //         {/*</div>*/}
    //         <div className='call--content'>
    //             {/*{meetingUrl ? (
    //                 <iframe
    //                     src='http://127.0.0.1:9999/meeting.html?name=TG9jYWwxLjkuNldpbjEwI2Nocm9tZS85MS4wLjQ0NzIuNzc%3D&mn=97535164327&email=&pwd=Bf0RtW&role=0&lang=en-US&signature=Rm5XMm9UUUFRZU8xaW5lME1GSEZKdy45NzUzNTE2NDMyNy4xNjI0MjA3NTk3NDMxLjAuSzVENHRSVmx0ZitmR2FGZ3JwQTNOQTZCUkVibXJsU3BJKzdiSm85RDRDUT0&china=0&apiKey=FnW2oTQAQeO1ine0MFHFJw'
    //                     src={meetingUrl}
    //                     allow='camera; microphone; fullscreen; speaker; display-capture'
    //                 ></iframe>
    //             ) : null}*/}
    //             {/*<div className='call--left'>
    //                 <div>
    //                     <div className='call--left--image'>
    //                         <img alt='star' src='/images/pre-event.png' />
    //                     </div>
    //                     {(data.eventSponsorsOrganizations || []).length ? (
    //                         <Block
    //                             title={localization.webinarAboutPresented}
    //                             isNoPadding
    //                         >
    //                             <div className='call--left--presented'>
    //                                 {(data.eventSponsorsOrganizations || []).map((item) => (
    //                                     <div onClick={() => setSelectedOrganizationId(item.organization.id)}>
    //                                         <Avatar
    //                                             image={item.organization.logo}
    //                                             fullName={item.organization.name}
    //                                         />
    //                                         <div>{item.organization.name}</div>
    //                                     </div>
    //                                 ))}
    //                             </div>
    //                         </Block>
    //                     ) : null}
    //                 </div>
    //             </div>
    //             <div className='call--right'>
    //                 <Tabs
    //                     items={[
    //                         localization.webinarQnA,
    //                         localization.webinarDiscussion
    //                     ]}
    //                     selected={selectedTab}
    //                     onChange={(index) => setSelectedTab(index)}
    //                 >
    //                 </Tabs>
    //                 {selectedTab === 0 ? (
    //                     <QnA
    //                         eventParticipants={eventParticipants}
    //                         webinarId={data.id}
    //                         webinarName={data.name}
    //                         webinarLink={data.lastLink}
    //                         chatId={data.twilioGroupChatId}
    //                         selectPerson={data}
    //                         loggedInUserEventParticipant={data.loggedInUserEventParticipant}
    //                     />
    //                 ) : null}
    //                 {selectedTab === 1 ? (
    //                     <Discussion
    //                         eventParticipants={eventParticipants}
    //                         webinarId={data.id}
    //                         webinarName={data.name}
    //                         webinarLink={data.lastLink}
    //                         chatId={data.twilioGroupChatId}
    //                         selectPerson={data}
    //                         loggedInUserEventParticipant={data.loggedInUserEventParticipant}
    //                     />
    //                 ) : null}
    //             </div>*/}
    //         </div>
    //     </div>
    // );
};

export default Call;
