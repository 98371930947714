import React, {useContext, useEffect, useState} from 'react';
import MessagingActions from "../../../components/messaging/MessagingActions";
import Dropdown from "../../../components/content/Dropdown";
import localization from "../../../localization";
import Messaging from "../../../components/messaging/Messaging";
import {store} from "../../../store";
import api from "../../../api";


const Discussion = ({chatId, webinarName, webinarLink, webinarId, eventParticipants, selectPerson, loggedInUserEventParticipant}) => {
    const [messages, setMessages] = useState([]);
    const [participants, setParticipants] = useState(eventParticipants);
    const [mutedChats, setMutedChats] = useState([]);
    const [chat, setChat] = useState(null);
    const {currentUser, twilioClient, setActiveChat, discussionUnread, setDiscussionUnread} = useContext(store);
    const memberChatName = 'twilioGroupChannelMemberId';
    useEffect(() => {
        if (twilioClient) {
            twilioClient.getSubscribedChannels().then((paginator) => {
                let chat = paginator.items.filter(({sid}) => sid === chatId)[0];
                const loadChanel = (paginator) => {
                    if (!chat) {
                        if (paginator.hasNextPage) {
                            paginator.nextPage().then((paginator) => {
                                chat = paginator.items.filter(({sid}) => sid === chatId)[0];
                                loadChanel(paginator);
                            })
                        }
                    } else {
                        setChat(chat);
                    }
                };
                loadChanel(paginator);
            });
        }
    }, [twilioClient]);
    useEffect(() => {
        if (chat) {
            setActiveChat(chatId);
            const messages = [];
            chat.getMessages().then((data) => {
                data.items.forEach((item) => {
                    const user = participants.filter((participant) => participant[memberChatName] === item.state.memberSid)[0].participantUser;
                    messages.push({
                        ...item.state,
                        baseMessage: item,
                        user,
                        isCreator: currentUser.id === user.id
                    });
                });
                setMessages([...messages]);
                chat.updateLastConsumedMessageIndex(Math.min(messages.length, 30));
                setDiscussionUnread({...discussionUnread, [chat.sid]: 0});
            });
            chat.on('messageAdded', (item) => {
                const user = participants.filter((participant) => participant[memberChatName] === item.state.memberSid)[0];
                messages.push({
                    ...item.state,
                    baseMessage: item,
                    user: user ? user.participantUser : {},
                    isCreator: currentUser.id === (user.participantUser || {}).id
                });
                setMessages([...messages]);
                if (chat.sid === localStorage.getItem('activeChat')) {
                    chat.updateLastConsumedMessageIndex(Math.min(messages.length, 30));
                    setDiscussionUnread({...discussionUnread, [chat.sid]: 0});
                }
                if (!user) {
                    api.get(`/api/v1/event/${webinarId}/participants`).then(({data}) => {
                        setParticipants(data);
                        const user = data.filter((item) => item[memberChatName] === item.state.memberSid)[0];
                        messages.forEach((message) => {
                            message.user = user ? user.participantUser : {};
                            message.isCreator = currentUser.id === (user.participantUser || {}).id;
                        });
                        setMessages([...messages]);
                    });
                }
            });
        }
        return () => setActiveChat(null);
    }, [chat]);
    return (
        <>
            <MessagingActions>
                <Dropdown
                    button={(<img alt='elipses' src='/images/elipses-vertical.svg' />)}
                >
                    <button
                        onClick={() => {
                            // mute
                        }}
                    >
                        {mutedChats.indexOf(chatId) >= 0 ? localization.messagingUnmute : localization.messagingMute}
                    </button>
                </Dropdown>
            </MessagingActions>
            <Messaging
                key={chat}
                messages={messages}
                sendMessage={(message) => {
                    if (chat) {
                        chat.sendMessage(message, {
                            title: `${currentUser.firstName} ${currentUser.lastName} in ${webinarName}`,
                            webinarLink,
                            chatId,
                            userId: currentUser.id
                        });
                    }
                }}
                hoverActions={(message) => (
                    <>
                        <button
                            onClick={() => {
                                setMessages(messages.filter(({sid}) => message.sid !== sid));
                                message.baseMessage.remove();
                            }}
                            disabled={message.isCreator ? false : (!loggedInUserEventParticipant.host && !loggedInUserEventParticipant.admin)}
                        >
                            {localization.deletE}
                        </button>
                    </>
                )}
                selectPerson={selectPerson}
            />
        </>
    );
};

export default Discussion;
