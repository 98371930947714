import React from 'react';
import classNames from 'classnames';
import Input from "./Input";


const InputPair = ({value, onChange, placeholder, type, min, max, error, disabled, onBlur, required, label}) => {
    return (
        <div className={classNames('input-pair', {'input-pair--required': required})}>
            <div className='input-pair--label'>{label}</div>
            <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                placeholder={placeholder}
                type={type}
                disabled={disabled}
                min={min}
                max={max}
                error={error}
            />
        </div>
    );
};

export default InputPair;
