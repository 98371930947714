import React, {useContext, useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {withRouter} from "react-router-dom";
import InputPair from "../../components/form/InputPair";
import api from "../../api";
import ExpandableBlock from "./ExpandableBlock";
import ImageUpload from "../../components/form/ImageUpload";
import Input from "../../components/form/Input";
import localization from "../../localization";
import Block from "../../components/content/Block";
import Select from "react-select";
import DatePicker from "../../components/form/DatePicker";
import RichTextInput from "../../components/form/RichTextInput";
import TextArea from "../../components/form/TextArea";
import {store} from "../../store";

const organizationOptions = [{
    label: 'Yes',
    value: true
}, {
    label: 'No',
    value: false
}];

const CreativeSolutionsMentor = ({history, setLogOut, initialData, setInitialData, setConfirmationToast}) => {
    const {currentUser} = useContext(store);
    const [formData, setFormData] = useState({email: currentUser.email});
    const [errors, setErrors] = useState({});
    useEffect(() => {
        if (initialData.id) {
            setFormData({
                ...initialData,
                email: currentUser.email
            });
        }
    }, [initialData]);
    return (
        <div>
            {/*<div className='creative-solutions--header'>
                <div className='creative-solutions--header--content'>
                    <div className='creative-solutions--tabs'>
                        <button className='creative-solutions--tab creative-solutions--tab--active' onClick={() => history.push('/apply')}>
                            Application
                        </button>
                        <div className='creative-solutions--tab' onClick={() => history.push('/apply/evaluation')}>
                            Evaluation
                        </div>
                    </div>
                </div>
            </div>*/}
            {/*<div className='creative-solutions--title'>
                Mentor Registration Form
                <div className='creative-solutions--subtitle'>
                    Creative Solutions Program - Year 2021
                </div>
            </div>*/}
            <div className='creative-solutions--applicant' style={{marginTop: 20}}>
                <div className='creative-solutions--questions'>
                    <div className='creative-solutions--questions--block'>
                        <div className='creative-solutions--questions--block--name'>
                            ABOUT YOU
                        </div>
                        <div className='creative-solutions--questions--block--title'>
                            First Name
                        </div>
                        <div className='creative-solutions--questions--block--description'>
                            Provide name as per your identification proof (Ex. Passport)
                        </div>
                        <Input
                            error={errors.firstName}
                            value={formData.firstName}
                            onChange={(value) => setFormData({...formData, firstName: value})}
                            placeholder='First Name'
                        />
                        <div className='creative-solutions--questions--block--title'>
                            Surname / Last Name
                        </div>
                        <div className='creative-solutions--questions--block--description'>
                            Provide name as per your identification proof (Ex. Passport)
                        </div>
                        <Input
                            error={errors.surname}
                            value={formData.surname}
                            onChange={(value) => setFormData({...formData, surname: value})}
                            placeholder='Surname'
                        />
                        <div className='creative-solutions--questions--block--title'>
                            Email Address
                        </div>
                        <div className='creative-solutions--questions--block--description'>
                            We will register this email for communications and program
                        </div>
                        <Input
                            error={errors.email}
                            value={formData.email}
                            onChange={(value) => setFormData({...formData, email: value})}
                            placeholder='Email Address'
                            disabled
                        />
                        <div className='creative-solutions--questions--block--title'>
                            Telephone Number (Daytime)
                        </div>
                        <Input
                            error={errors.telephoneNumber}
                            value={formData.telephoneNumber}
                            onChange={(value) => setFormData({...formData, telephoneNumber: value})}
                            placeholder='Number (+1-54112321031)'
                        />
                        <div className='creative-solutions--questions--block--title'>
                            Linkedin Profile URL
                        </div>
                        <Input
                            error={errors.linkedInProfile}
                            value={formData.linkedInProfile}
                            onChange={(value) => setFormData({...formData, linkedInProfile: value})}
                            placeholder='Paste your Linkedin Profile URL'
                        />
                        <div className='creative-solutions--questions--block--title'>
                            Why would you like to be a mentor? And, how do you expect to contribute?
                        </div>
                        <TextArea
                            error={errors.description}
                            value={formData.description}
                            onChange={(value) => setFormData({...formData, description: value})}
                            placeholder='Type your response...'
                            rows={5}
                        />
                    </div>
                    <div className='creative-solutions--questions--actions'>
                        <button
                            onClick={() => {
                                if (!formData.firstName) {
                                    errors.firstName = 'Please fill your first name.';
                                }
                                if (!formData.surname) {
                                    errors.surname = 'Please fill your surname.';
                                }
                                if (!formData.telephoneNumber) {
                                    errors.telephoneNumber = 'Please fill your telephone number in the format +966-XXXXXXXXX.';
                                }
                                if (!formData.linkedInProfile) {
                                    errors.linkedInProfile = 'Please fill your linkedin profile.';
                                }
                                if (!formData.description) {
                                    errors.description = 'Please fill your description.';
                                }
                                ['description'].forEach((field) => {
                                    if ((formData[field] || '').split(' ').length > 500) {
                                        errors[field] = 'Maximum 500 words allowed.'
                                    }
                                });
                                setErrors(errors);
                                if (!Object.keys(errors).length) {
                                    api.post(`/api/v1/mentor-registration`, formData).then(({data}) => {
                                        setInitialData(data);
                                        setConfirmationToast('Application Saved');
                                        setTimeout(() => setConfirmationToast(null), 2500);
                                        history.push(`/apply/completed${window.location.search}`);
                                    });
                                }
                            }}
                        >
                            Submit Application
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(CreativeSolutionsMentor);