import React, {useContext, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import classNames from 'classnames';
import localization from "../../localization";
import Badge from "../content/Badge";
import {store} from "../../store";
import Avatar from '../content/Avatar'

const MoreNavigation = ({location}) => {
    const {currentUser, eventNotifications, discussionUnread} = useContext(store);
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className='more-navigation'>
            <div className={classNames('page-navigation--item', {'page-navigation--item--active': isOpen})} onClick={() => setIsOpen(!isOpen)}>
                <img alt='webinars' src={`/images/${process.env.REACT_APP_PROJECT}-more-inactive.png`} />
                <img alt='webinars' src={`/images/${process.env.REACT_APP_PROJECT}-more.png`} />
                <div className='page-navigation--item--text'>More</div>
            </div>
            {isOpen ? (
                <div className='more-navigation--body' onClick={() => setIsOpen(!isOpen)}>
                    <Link to='/apply'>
                        <div className={classNames('page-navigation--item', {'page-navigation--item--active': location.pathname === '/apply' || location.pathname.substring(0, 7) === '/apply/'})}>
                            <img alt='webinars' src={`/images/${process.env.REACT_APP_PROJECT}-apply-inactive.png`} />
                            <img alt='webinars' src={`/images/${process.env.REACT_APP_PROJECT}-apply.png`} />
                            <div className='page-navigation--item--text'>Apply</div>
                        </div>
                    </Link>
                    <Link to='/announce'>
                        <div className={classNames('page-navigation--item', {'page-navigation--item--active': location.pathname === '/announce'})}>
                            <img alt='webinars' src={`/images/${process.env.REACT_APP_PROJECT}-announce-inactive.png`} />
                            <img alt='webinars' src={`/images/${process.env.REACT_APP_PROJECT}-announce.png`} />
                            <div className='page-navigation--item--text'>Announce</div>
                        </div>
                    </Link>
                    <Link to='/w'>
                        <div className={classNames('page-navigation--item', {'page-navigation--item--active': location.pathname === '/w' || location.pathname.substring(0, 3) === '/w/'})}>
                            <Badge count={eventNotifications.length + Object.values(discussionUnread).reduce((count, item) => (count + item), 0)}/>
                            <img alt='webinars' src={`/images/${process.env.REACT_APP_PROJECT}-webinars-inactive.png`} />
                            <img alt='webinars' src={`/images/${process.env.REACT_APP_PROJECT}-webinars.png`} />
                            <div className='page-navigation--item--text'>Rooms</div>
                        </div>
                    </Link>
                    <Link to='/forum'>
                        <div className={classNames('page-navigation--item', {'page-navigation--item--active': location.pathname === '/forum'})}>
                            <img alt='contacts' src={`/images/${process.env.REACT_APP_PROJECT}-forum-inactive.png`} />
                            <img alt='contacts' src={`/images/${process.env.REACT_APP_PROJECT}-forum.png`} />
                            <div className='page-navigation--item--text'>Forum</div>
                        </div>
                    </Link>
                    <Link to='/meet' style={{display: 'none'}}>
                        <div className={classNames('page-navigation--item', {'page-navigation--item--active': location.pathname === '/meet'})}>
                            <img alt='meet' src={`/images/${process.env.REACT_APP_PROJECT}-meet-inactive.png`} />
                            <img alt='meet' src={`/images/${process.env.REACT_APP_PROJECT}-meet.png`} />
                            <div className='page-navigation--item--text'>Meet</div>
                        </div>
                    </Link>
                    <Link to='/table'>
                        <div className={classNames('page-navigation--item', {'page-navigation--item--active': location.pathname === '/table'})}>
                            <img alt='meet' src={`/images/${process.env.REACT_APP_PROJECT}-table-inactive.png`} />
                            <img alt='meet' src={`/images/${process.env.REACT_APP_PROJECT}-table.png`} />
                            <div className='page-navigation--item--text'>Table</div>
                        </div>
                    </Link>
                    <Link to='/account'>
                        <div className={classNames('page-navigation--item', 'page-navigation--avatar', {'page-navigation--item--active': location.pathname.indexOf('/account') === 0})}>
                            <Avatar
                                image={currentUser.image}
                                fullName={`${currentUser.firstName} ${currentUser.lastName}`}
                            />
                            <div className='page-navigation--item--text'>{localization.navigationAccount}</div>
                        </div>
                    </Link>
                </div>
            ) : null}
        </div>
    );
};

export default withRouter(MoreNavigation);