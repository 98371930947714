import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router";
import Page from "../../components/page/Page";
import Navigation from "../../components/navigation/Navigation";
import NavigationItem from "../../components/navigation/NavigationItem";
import Person from "../../components/content/Person";
import ProfileDetails from "../../components/profile/ProfileDetails";
import {store} from "../../store";
import api from "../../api";
import PageContent from "../../components/page/PageContent";
import Profile from "../../components/profile/Profile";
import {Helmet} from "react-helmet";
import copyToClipboard from "../../utils/copyToClipboard";
import localization from "../../localization";
import NavigationGroup from "../../components/navigation/NavigationGroup";
import Input from "../../components/form/Input";


const Contacts = ({}) => {
    const [selectedPersonId, setSelectedPersonId] = useState(null);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [confirmationToast, setConfirmationToast] = useState(null);
    const [eventParticipants, setEventParticipants] =  useState([]);
    const [openedFavorites, setOpenedFavorites] = useState(true);
    const [openedPeople, setOpenedPeople] = useState(true);
    const [search, setSearch] = useState('');
    const {currentUser, favorites, setFavorites} = useContext(store);

    const webinarId = 3;

    useEffect(() => {
        if (!favorites.length) {
            api.get(`/api/v1/favourite-contact/user/${currentUser.id}`).then(({data}) => {
                setFavorites(data);
            });
        }
        api.get(`/api/v1/event/${webinarId}/participants`).then(({data}) => {
            setEventParticipants(data);
        });
    }, []);
    return (
        <Page
            className='page-contacts'
        >
            <Helmet>
                <title>People | {process.env.REACT_APP_PROJECT_NAME}</title>
                <meta name="description" content="All your favorite contacts in place. See their contact, message and video chat with them, keep notes, and stay connected." />
            </Helmet>
            <Navigation
                title={(
                    <div className='flex-between'>
                        <div>People</div>
                        <div className='flex-between'>
                            {/*<button
                                className='button--search'
                                onClick={() => {
                            }}>
                                <img alt='search' src='/images/search.svg' />
                            </button>*/}
                            {/*<div className='button--filter'>
                                <Dropdown
                                    button={(<img alt='filter' src='/images/filter.svg' />)}
                                >
                                    <button
                                        onClick={() => {
                                            setFilter(null);
                                        }}
                                    >
                                        {localization.contactsAll}
                                    </button>
                                    <button
                                        onClick={() => {
                                            setFilter('EVENT');
                                        }}
                                    >
                                        {localization.contactsEvent}
                                    </button>
                                    <button
                                        onClick={() => {
                                            setFilter('DIGITIZED');
                                        }}
                                    >
                                        {localization.contactsDigitized}
                                    </button>
                                </Dropdown>
                            </div>*/}
                        </div>
                    </div>
                )}
                subtitle={(
                    <div className='people--search'>
                        <Input
                            placeholder='Search People'
                            value={search}
                            onChange={(value) => setSearch(value)}
                        />
                        <img alt='search-gray' src='/images/search-gray.svg' />
                    </div>
                )}
            >
                <NavigationGroup
                    title={`Favorites`}
                    collapsable
                    opened={openedFavorites}
                    setOpened={() => setOpenedFavorites(!openedFavorites)}
                >
                    <NavigationItem
                        content={(
                            <Person
                                image={currentUser.image}
                                title={`${localization.contactsMyContact} (${currentUser.firstName})`}
                                fullName={`${currentUser.firstName} ${currentUser.lastName}`}
                                description={`${currentUser.position}, ${currentUser.organization}`}
                            />
                        )}
                        onClick={() => {
                            setSelectedPersonId(null);
                            setSelectedProfile(true);
                        }}
                    />
                    {favorites.filter((item) => !search || `${item.user2.firstName} ${item.user2.lastName}`.toLowerCase().indexOf(search.toLowerCase()) >= 0).map((item, index) => (
                        <NavigationItem
                            key={index}
                            content={(
                                <Person
                                    image={item.user2.image}
                                    title={`${item.user2.firstName} ${item.user2.lastName}`}
                                    description={`${item.user2.position}, ${item.user2.organization}`}
                                />
                            )}
                            action={(
                                <button>
                                    <img alt='move' src='/images/move.svg' />
                                </button>
                            )}
                            onClick={() => {
                                setSelectedProfile(false);
                                setSelectedPersonId(null);
                                setTimeout(() => setSelectedPersonId(item.user2.id), 0);
                            }}
                            isActive={selectedPersonId === item.user2.id}
                        />
                    ))}
                </NavigationGroup>
                <NavigationGroup
                    title={`People`}
                    collapsable
                    opened={openedPeople}
                    setOpened={() => setOpenedPeople(!openedPeople)}
                >
                    {eventParticipants.filter((item) => !search || `${item.participantUser.firstName} ${item.participantUser.lastName}`.toLowerCase().indexOf(search.toLowerCase()) >= 0).map((item, index) =>
                        item.participantUser.id === currentUser.id ? (
                                <NavigationItem
                                    content={(
                                        <Person
                                            image={currentUser.image}
                                            title={`${currentUser.firstName} ${currentUser.lastName}`}
                                            fullName={`${currentUser.firstName} ${currentUser.lastName}`}
                                            description={`${currentUser.position}, ${currentUser.organization}`}
                                        />
                                    )}
                                    action={(
                                        <button>
                                            <img alt='move' src='/images/move.svg' />
                                        </button>
                                    )}
                                    onClick={() => {
                                        setSelectedPersonId(null);
                                        setSelectedProfile(true);
                                    }}
                                />
                            ) : (
                                <NavigationItem
                                    key={index}
                                    content={(
                                        <Person
                                            image={item.participantUser.image}
                                            title={`${item.participantUser.firstName} ${item.participantUser.lastName}`}
                                            description={`${item.participantUser.position}, ${item.participantUser.organization}`}
                                        />
                                    )}
                                    action={(
                                        <button>
                                            <img alt='move' src='/images/move.svg' />
                                        </button>
                                    )}
                                    onClick={() => {
                                        setSelectedProfile(false);
                                        setSelectedPersonId(null);
                                        setTimeout(() => setSelectedPersonId(item.participantUser.id), 0);
                                    }}
                                    isActive={selectedPersonId === item.participantUser.id}
                                />
                            ))}
                </NavigationGroup>
            </Navigation>
            {selectedPersonId ? (
                <ProfileDetails
                    personId={selectedPersonId}
                    backAction={() => setSelectedPersonId(null)}
                    noBackOnDesktop
                />
            ) : null}
            {selectedProfile ? (
                <PageContent
                    title={`${currentUser.firstName}${localization.profile}`}
                    backAction={() => setSelectedProfile(false)}
                    noBackOnDesktop
                >
                    <Helmet>
                        <title>{currentUser.firstName} {currentUser.lastName} | {process.env.REACT_APP_PROJECT_NAME}</title>
                        <meta name="description" content='Everything about your account on Last Link' />
                    </Helmet>
                    <Profile
                        user={currentUser}
                    />
                </PageContent>
            ) : null}
            {confirmationToast ? (
                <div className='toast--confirmation'>
                    {confirmationToast}
                </div>
            ) : null}
        </Page>
    );
};

export default withRouter(Contacts);
