import React, {useContext, useState} from 'react';
import {LinkedIn} from 'react-linkedin-login-oauth2';
import {Helmet} from "react-helmet";
import {withRouter} from "react-router-dom";
import localization from "../../localization";
import InputPair from "../../components/form/InputPair";
import api from "../../api";
import {store} from "../../store";



const SignIn = ({history}) => {
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const {setCurrentUser} = useContext(store);
    return (
        <div className='page-landing'>
            <Helmet>
                <title>Login | {process.env.REACT_APP_PROJECT_NAME}</title>
                <meta name="description" content="Log in to Last Link." />
            </Helmet>
            <form className='landing--content'>
                <div className='landing--logo'>
                    <img alt='logo' src={`/images/${process.env.REACT_APP_PROJECT}-logo-big.svg`} />
                </div>
                <div className='landing--title'>
                    {localization.landingWelcome}
                </div>
                <div className='landing--subtitle'>
                    {localization.landingLogBack}
                </div>
                <InputPair
                    label={localization.landingLoginEmailLabel}
                    value={formData.email}
                    onChange={(value) => setFormData({...formData, email: value.trim()})}
                    placeholder={localization.landingLoginEmailPlaceholder}
                    error={errors.email}
                />
                <InputPair
                    label='Password'
                    value={formData.password}
                    onChange={(value) => setFormData({...formData, password: value})}
                    type='password'
                    error={errors.password}
                />
                <button
                    className='submit-button'
                    type='submit'
                    onClick={(event) => {
                        event.preventDefault();
                        const errors = {};
                        if (!formData.email || formData.email.length > 150 || !/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(formData.email)) {
                            errors.email = localization.notValid;
                        }
                        if (!formData.password) {
                            errors.password = localization.notValid;
                        }
                        setErrors(errors);
                        if (!Object.keys(errors).length) {
                            api.post(
                                `/api/v1/users/ticket-master/signin`,
                                {email: formData.email.trim(), password: formData.password},
                                {headers: {'issigninrequest': 'yes'}}
                            ).then(({data}) => {
                                const {accessToken, user} = data;
                                localStorage.setItem('accessToken', accessToken);
                                setCurrentUser(user);
                                if (!user.position || !user.organization || !user.timeZoneId) {
                                    history.push('/onboarding');
                                } else {
                                    if (localStorage.getItem('profileSuffix')) {
                                        history.push(`/${localStorage.getItem('profileSuffix')}`);
                                    } else if (localStorage.getItem('organizationSuffix')) {
                                        history.push(`/o/${localStorage.getItem('organizationSuffix')}`);
                                    } else {
                                        history.push('/');
                                    }
                                }
                            }).catch(() => {
                                history.push('/');
                            });
                        }
                    }}
                >
                    Sign In
                </button>
                <div className='landing--footer'>
                    {localization.landingByClicking} <a href='https://tickets.ithra.com/en/showTerms.html' target='_blank'>{localization.landingTerms}</a> & <a href='https://www.ithra.com/en/privacy-policy' target='_blank'>{localization.landingPrivacy}</a>.
                </div>
            </form>
        </div>
    );
};

export default withRouter(SignIn);