import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {withRouter} from "react-router-dom";
import InputPair from "../../components/form/InputPair";
import api from "../../api";



const ExpandableBlock = ({title, children}) => {
    const [isOpen, setIsOpen] = useState(true);
    return (
        <div className='expandable-block'>
            <div
                className='expandable-block--title'
                onClick={() => setIsOpen(!isOpen)}
            >
                {title}
                {/*<img alt='logo' src={`${process.env.REACT_APP_FILE_SOURCE}images/arrow-down.svg`} className={isOpen ? 'expandable-block--open' : null} />*/}
            </div>
            {isOpen ? (
                <div className='expandable-block--content'>
                    {children}
                </div>
            ) : null}
        </div>
    );
};

export default withRouter(ExpandableBlock);