import React, {useContext, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';
import api from '../../api';
import {store} from '../../store.js';



const ZoomLogIn = ({location, history}) => {
    const {currentUser, setCurrentUser} = useContext(store);
    useEffect(() => {
        const {code} = queryString.parse(location.search);
        if (currentUser) {
            api.get(`/api/v1/zoom/link?code=${code}`).then(({data}) => {
                history.push('/w');
            }).catch(() => {
                history.push('/w');
            });
        } else {
            api.get(`/api/v1/users/zoom-signin?code=${code}`, {
                headers: {
                    'issigninrequest': 'yes',
                }
            }).then(({data}) => {
                const {accessToken, user} = data;
                localStorage.setItem('accessToken', accessToken);
                setCurrentUser(user);
                if (!user.firstName || !user.lastName) {
                    history.push('/pre-onboarding');
                } else  if (!user.position || !user.organization || !user.timeZoneId) {
                    history.push('/onboarding');
                } else {
                    if (localStorage.getItem('profileSuffix')) {
                        history.push(`/${localStorage.getItem('profileSuffix')}`);
                    } else if (localStorage.getItem('organizationSuffix')) {
                        history.push(`/o/${localStorage.getItem('organizationSuffix')}`);
                    } else {
                        history.push('/w');
                    }
                }
            }).catch(() => {
                history.push('/');
            });
        }
    }, []);
    return (
        <div/>
    );
};

export default withRouter(ZoomLogIn);