import React, {useContext, useState} from 'react';
import Input from "../../../components/form/Input";
import {store} from "../../../store";
import localization from "../../../localization";
import api from "../../../api";
import copyToClipboard from "../../../utils/copyToClipboard";
import SuffixInput from "../../../components/form/SuffixInput";



const Default = ({handleCreate, setConfirmationToast, setCreateWebinar}) => {
    const [duplicate, setDuplicate] = useState(null);
    const [suffixValid, setSuffixValid] = useState(true);
    const [formData, setFormData] = useState({});
    const {currentUser} = useContext(store);
    return (
        <div className='default'>
            <div className='default--content'>
                <img alt='img' src='/images/webinar-create.svg' />
                <div className='default--title'>
                    {/*{localization.webinarDefaultTitle}*/}
                    Collaborate in Rooms
                </div>
                {/*<div className='default--body'>
                    <SuffixInput
                        value={formData.lastLinkSuffix}
                        error={duplicate}
                        onChange={(value) => setFormData({...formData, lastLinkSuffix: value})}
                        prefix={`${window.location.origin.split('//')[1]}/w/`}
                        placeholder='myevent'
                        getUrl={(value) => `/api/v1/event/lastlink/${value}/exists`}
                        validate={(isValid) => setSuffixValid(isValid)}
                    />
                    <button
                        className='submit-button'
                        disabled={!formData.lastLinkSuffix || !formData.lastLinkSuffix.trim() || !suffixValid}
                        onClick={() => {
                            const data = {
                                timeZoneId: currentUser.timeZoneId,
                                timeZoneName: currentUser.timeZoneName,
                                isPrivate: true,
                                name: 'My Last Link',
                                description: null,
                                callDetails: null,
                                tags: null,
                                meetingDuration: 0,
                                lastLinkSuffix: formData.lastLinkSuffix,
                                maxNumberOfParticipants: 1000,
                                eventExpiryDate: null,
                                meetingStartTime: null
                            };
                            api.post(`/api/v1/event`, data).then(({data}) => {
                                copyToClipboard(data.lastLink);
                                setFormData({});
                                setDuplicate(null);
                                handleCreate(data);
                                setConfirmationToast(localization.webinarCreateCopied);
                                setTimeout(() => setConfirmationToast(null), 2500);
                            }).catch((response) => {
                                const {message} = response.response.data;
                                if (message === localization.webinarCreateTaken) {
                                    setDuplicate(message);
                                }
                            });
                        }}
                    >
                        {localization.create}
                    </button>
                </div>*/}
                {/*{duplicate ? (
                    <div className='default--error'>
                        {duplicate}
                    </div>
                ) : null}
                <div className='default--footer'>
                    {localization.webinarDefaultIsPrivate} <a onClick={() => {
                    setCreateWebinar(true);
                    setFormData({});
                }}>{localization.webinarDefaultAddDetails}</a> {localization.webinarDefaultOrLater}.
                </div>*/}
            </div>
        </div>
    );
};

export default Default;
