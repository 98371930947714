import React, {useState} from 'react';
import classNames from 'classnames';
import toBase64 from "../../utils/toBase64";
import api from "../../api";


const ImageUpload = ({userId, image, onChange, imageType}) => {
    const [uploadedImage, setUploadedImage] = useState(null);
    return (
        <div
            className={classNames('image-upload')}
        >
            <div className='image-upload--content'>
                {(uploadedImage || image) ? (
                    <img alt='camera' src={uploadedImage || image}  className='image-upload--content--uploaded'/>
                ) : (
                    <img alt='camera' src={'/images/camera.svg'} />
                )}
                <input
                    type='file'
                    accept='image/png,image/jpg,image/jpeg'
                    onChange={(event) =>{
                        const file = event.target.files[0];
                        if (file) {
                            toBase64(file).then((base64) => {
                                const data = {
                                    userId,
                                    imageType,
                                    fileName: file.name,
                                    base64: base64.split('base64,')[1]
                                };
                                api.post(`/api/v1/files`, data).then(({data}) => {
                                    setUploadedImage(data.presignedUrl);
                                    onChange(data.presignedUrl);
                                });
                            })
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default ImageUpload;
