import React, {useState} from 'react';
import {LinkedIn} from 'react-linkedin-login-oauth2';
import {Helmet} from "react-helmet";
import {withRouter} from "react-router-dom";
import localization from "../../localization";
import InputPair from "../../components/form/InputPair";
import api from "../../api";



const LogIn = ({history}) => {
    const [formData, setFormData] = useState({});
    return (
        <div className='page-landing'>
            <Helmet>
                <title>Login | {process.env.REACT_APP_PROJECT_NAME}</title>
                <meta name="description" content="Log in to Last Link." />
            </Helmet>
            <div className='landing--content'>
                <div className='landing--logo'>
                    <img alt='logo' src={`/images/${process.env.REACT_APP_PROJECT}-logo-big.svg`} />
                </div>
                <div className='landing--title'>
                    Access your account at
                    Creative Solutions 2021
                </div>
                <InputPair
                    label="Email Address"
                    value={formData.email}
                    onChange={(value) => setFormData({...formData, email: value.trim()})}
                    placeholder="youremail@example.com"
                    required
                />
                <button
                    className='submit-button'
                    onClick={() => {
                        if (formData.email || /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(formData.email) || formData.email.length < 150) {
                            api.post(
                                `/api/v1/users/email-signin`,
                                {email: formData.email.trim()},
                                {headers: {'issigninrequest': 'yes', 'tenant': 'CREATIVE_SOLUTIONS'}}
                            ).then(({}) => {
                                localStorage.setItem('email', formData.email);
                                history.push('/check-inbox');
                            });
                        }
                    }}
                    disabled={!formData.email || formData.email.length > 150}
                >
                    Email me the Magic Link
                </button>
                <div className='landing--separator'/>
                <LinkedIn
                    clientId="861ongbwecsr8p"
                    redirectUri={`${window.location.origin}/linkedin-signup`}
                    scope='r_liteprofile,r_emailaddress'
                    onSuccess={() => {}}
                    onFailure={() => {}}
                    renderElement={({onClick, disabled}) => (
                        <button
                            className='landing--linkedin'
                            onClick={onClick}
                            disabled={disabled}
                        >
                            <img alt='linkedin' src='/images/linkedin-white.svg' />
                            <div>{localization.landingLoginLinkedin}</div>
                        </button>
                    )}
                />
                {/*<button
                    className='landing--zoom'
                    onClick={() => {
                        const clientId = 'O7Ibt4c8Qay_Qm0BfWq5Ug';
                        const redirectUrl = `${window.location.origin}/zoom-signup`;
                        window.open(`https://zoom.us/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}`);
                    }}
                >
                    <img alt='mail' src='/images/zoom.svg' />
                    <div>{localization.landingLoginZoom}</div>
                </button>*/}
                {/*<div className='landing--footer'>
                    {localization.landingByClicking} <a href='https://www.lastlink.us/legal/terms-of-service' target='_blank'>{localization.landingTerms}</a> & <a href='https://www.lastlink.us/legal/privacy-policy' target='_blank'>{localization.landingPrivacy}</a>.
                </div>*/}
            </div>
        </div>
    );
};

export default withRouter(LogIn);