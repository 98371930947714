import React from 'react';
import classNames from 'classnames';
import PageNavigation from './PageNavigation';

const Page = ({className, children}) => {
    return (
        <div className={classNames('page', className)}>
            <PageNavigation/>
            {children}
        </div>
    );
};

export default Page;