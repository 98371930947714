import React, {useContext, useEffect, useState} from 'react';
import classNames from 'classnames';
import {Helmet} from "react-helmet";
import {withRouter} from "react-router-dom";
import InputPair from "../../components/form/InputPair";
import api from "../../api";
import ExpandableBlock from "./ExpandableBlock";
import ImageUpload from "../../components/form/ImageUpload";
import Input from "../../components/form/Input";
import localization from "../../localization";
import Block from "../../components/content/Block";
import Select from "react-select";
import DatePicker from "../../components/form/DatePicker";
import RichTextInput from "../../components/form/RichTextInput";
import TextArea from "../../components/form/TextArea";
import {store} from "../../store";
import moment from "moment";

const organizationOptions = [{
    label: 'Yes',
    value: true
}, {
    label: 'No',
    value: false
}];

const employmentOptions = [{
    label: 'Student',
    value: 'Student'
}, {
    label: 'Freelance',
    value: 'Freelance'
}, {
    label: 'Self-employed',
    value: 'Self-employed'
}, {
    label: 'Employed',
    value: 'Employed'
}, {
    label: 'Unemployed',
    value: 'Unemployed'
}];

const englishOptions = [{
    label: 'Basic',
    value: 'Basic'
}, {
    label: 'Conversational',
    value: 'Conversational'
}, {
    label: 'Fluent',
    value: 'Fluent'
}, {
    label: 'Native or Bilingual',
    value: 'Native or Bilingual'
}];

const CreativeSolutionsApplicant = ({history, initialData, setInitialData, setConfirmationToast, setLogOut}) => {
    const {currentUser} = useContext(store);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({email: currentUser.email});
    const [links, setLinks] = useState({0: ''});
    const [date, setDate] = useState({});
    const [dateErrors, setDateErrors] = useState({});
    useEffect(() => {
        if (initialData.id) {
            setFormData({
                ...initialData,
                email: currentUser.email
            });
            setLinks((initialData.referenceLinks || '').split(','));
            if (initialData.dateOfBirth) {
                setDate({
                    day: initialData.dateOfBirth.split('/')[0],
                    month: initialData.dateOfBirth.split('/')[1],
                    year: initialData.dateOfBirth.split('/')[2]
                });
            }
        }
    }, [initialData]);
    return (
        <div>
            <div className='creative-solutions--header'>
                <div className='creative-solutions--header--content'>
                    <div className='creative-solutions--tabs'>
                        {/*<button className='creative-solutions--tab creative-solutions--tab--active' onClick={() => history.push('/apply')}>
                            Application
                        </button>
                        <div className='creative-solutions--tab' onClick={() => history.push('/apply/evaluation')}>
                            Evaluation
                        </div>*/}
                    </div>
                    {/*<div>
                        <button
                            className="creative-solutions--header--save"
                            onClick={() => {
                                const payload = {
                                    ...formData,
                                    submissionStatus: 'DRAFT',
                                    dateOfBirth: date.day && date.month && date.year ? `${date.day}/${date.month}/${date.year}` : null,
                                    referenceLinks: Object.values(links).join(',')
                                }
                                api.post(`/api/v1/application-form`, payload).then(({data}) => {
                                    setInitialData(data);
                                    setConfirmationToast('Application Saved');
                                    setTimeout(() => setConfirmationToast(null), 2500);
                                });
                            }}
                        >
                            Save for Later
                        </button>
                    </div>*/}
                </div>
            </div>
            {/*<div className='creative-solutions--title'>
                Application Form - Applicant
                <div className='creative-solutions--subtitle'>
                    Creative Solutions Program - Year 2021
                </div>
            </div>*/}
            <div className='creative-solutions--applicant'>
                <div className='creative-solutions--questions'>
                    <div id='section_1' className='creative-solutions--questions--block'>
                        <div className='creative-solutions--questions--block--name'>
                            APPLICANT
                        </div>
                        {/*<div className='creative-solutions--questions--block--title'>
                            Are you representing an organisation and completing this section of the form on their behalf?
                        </div>
                        <div className='creative-solutions--questions--block--description'>
                            If you are not representing an organisation, for example if you are a freelancer, select No.
                        </div>*/}
                        {/*<div className={classNames('creative-solutions--questions--block--select', {'select--error': errors.representingOrganization})}>
                            <Select
                                value={organizationOptions.filter(({value}) => formData.representingOrganization === value)[0]}
                                onChange={({value}) => setFormData({...formData, representingOrganization: value})}
                                placeholder='Choose Yes/No'
                                options={organizationOptions}
                            />
                            {!formData.representingOrganization && errors.representingOrganization ? (
                                <div className='select--message'>{errors.representingOrganization}</div>
                            ) : null}
                        </div>*/}
                        {/*{formData.representingOrganization ? (
                            <div>
                                <div className='creative-solutions--questions--block--title'>
                                    Organization Name
                                </div>
                                <Input
                                    error={errors.organizationName}
                                    value={formData.organizationName}
                                    onChange={(value) => setFormData({...formData, organizationName: value})}
                                    placeholder='Organization Name'
                                />
                            </div>
                        ) : null}*/}
                        <div className='creative-solutions--questions--block--title'>
                            First Name
                        </div>
                        <div className='creative-solutions--questions--block--description'>
                            Provide name as per your identification proof (Ex. Passport)
                        </div>
                        <Input
                            error={errors.firstName}
                            value={formData.firstName}
                            onChange={(value) => setFormData({...formData, firstName: value})}
                            placeholder='First Name'
                        />
                        <div className='creative-solutions--questions--block--title'>
                            Surname / Last Name
                        </div>
                        <div className='creative-solutions--questions--block--description'>
                            Provide name as per your identification proof (Ex. Passport)
                        </div>
                        <Input
                            error={errors.surname}
                            value={formData.surname}
                            onChange={(value) => setFormData({...formData, surname: value})}
                            placeholder='Surname'
                        />
                        <div className='creative-solutions--questions--block--title'>
                            Date of Birth
                        </div>
                        <div className='creative-solutions--questions--block--description'>
                            Format: DD/MM/YYYY
                        </div>
                        <div className='custom-datepicker'>
                            <Input
                                error={dateErrors.day}
                                value={date.day}
                                onChange={(value) => setDate({...date, day: value})}
                                type='number'
                                min={1}
                                max={31}
                                placeholder='DD'
                            />
                            <Input
                                error={dateErrors.month}
                                value={date.month}
                                onChange={(value) => setDate({...date, month: value})}
                                type='number'
                                min={1}
                                max={12}
                                placeholder='MM'
                            />
                            <Input
                                error={dateErrors.year}
                                value={date.year}
                                onChange={(value) => setDate({...date, year: value})}
                                type='number'
                                min={1900}
                                max={2004}
                                placeholder='YYYY'
                            />
                        </div>
                        <div className='creative-solutions--questions--block--title'>
                            City
                        </div>
                        <Input
                            error={errors.city}
                            value={formData.city}
                            onChange={(value) => setFormData({...formData, city: value})}
                            placeholder='Enter City you reside in'
                        />
                        <div className='creative-solutions--questions--block--title'>
                            Region
                        </div>
                        <Input
                            error={errors.region}
                            value={formData.region}
                            onChange={(value) => setFormData({...formData, region: value})}
                            placeholder='Enter the Region you reside in'
                        />
                    </div>
                    <div id='section_2' className='creative-solutions--questions--block'>
                        <div className='creative-solutions--questions--block--name'>
                            CONTACT
                        </div>
                        <div className='creative-solutions--questions--block--title'>
                            Email Address
                        </div>
                        <div className='creative-solutions--questions--block--description'>
                            We will register this email for communications and program
                        </div>
                        <Input
                            error={errors.email}
                            value={formData.email}
                            onChange={(value) => setFormData({...formData, email: value})}
                            placeholder='Email Address'
                            disabled
                        />
                        <div className='creative-solutions--questions--block--title'>
                            Telephone Number (Daytime)
                        </div>
                        <Input
                            error={errors.telephoneNumber}
                            value={formData.telephoneNumber}
                            onChange={(value) => setFormData({...formData, telephoneNumber: value})}
                            placeholder='Number (+966-541123210)'
                        />
                    </div>
                    <div id='section_3' className='creative-solutions--questions--block'>
                        <div className='creative-solutions--questions--block--name'>
                            DETAILS
                        </div>
                        <div className='creative-solutions--questions--block--title'>
                            Are you a Saudi Arabian citizen or resident?
                        </div>
                        <div className={classNames('creative-solutions--questions--block--select', {'select--error': errors.saudiCitizenOrResident})}>
                            <Select
                                value={organizationOptions.filter(({value}) => formData.saudiCitizenOrResident === value)[0]}
                                onChange={({value}) => setFormData({...formData, saudiCitizenOrResident: value})}
                                placeholder='Choose Yes/No'
                                options={organizationOptions}
                            />
                            {!formData.saudiCitizenOrResident && errors.saudiCitizenOrResident ? (
                                <div className='select--message'>{errors.saudiCitizenOrResident}</div>
                            ) : null}
                        </div>
                        <div className='creative-solutions--questions--block--title'>
                            Please enter  your National ID or Iqama number:
                        </div>
                        <Input
                            error={errors.nationalId}
                            value={formData.nationalId}
                            onChange={(value) => setFormData({...formData, nationalId: value})}
                            placeholder='Saudi National ID or Iqama Number'
                        />
                        <div className='creative-solutions--questions--block--title'>
                            Employment status
                        </div>
                        <div className='creative-solutions--questions--block--description'>
                            Choose that is most appropriate to you at the time of application
                        </div>
                        <div className={classNames('creative-solutions--questions--block--select', {'select--error': errors.employmentStatus})}>
                            <Select
                                value={employmentOptions.filter(({value}) => formData.employmentStatus === value)[0]}
                                onChange={({value}) => setFormData({...formData, employmentStatus: value})}
                                placeholder='Choose one'
                                options={employmentOptions}
                            />
                            {!formData.employmentStatus && errors.employmentStatus ? (
                                <div className='select--message'>{errors.employmentStatus}</div>
                            ) : null}
                        </div>
                        <div className='creative-solutions--questions--block--title'>
                            English Proficiency
                        </div>
                        <div className='creative-solutions--questions--block--description'>
                            The program is delivered in English. So we encourage participants with good communication in english.
                        </div>
                        <div className={classNames('creative-solutions--questions--block--select', {'select--error': errors.englishProficiency})}>
                            <Select
                                value={englishOptions.filter(({value}) => formData.englishProficiency === value)[0]}
                                onChange={({value}) => setFormData({...formData, englishProficiency: value})}
                                placeholder='Choose one'
                                options={englishOptions}
                            />
                            {!formData.englishProficiency && errors.englishProficiency ? (
                                <div className='select--message'>{errors.englishProficiency}</div>
                            ) : null}
                        </div>
                        <div className='creative-solutions--questions--block--title'>
                            Please provide a short biography or summary of your career or educational highlights
                        </div>
                        <div className='creative-solutions--questions--block--description'>
                            (including personal website links etc.) (Max. 500 words)
                        </div>
                        <TextArea
                            error={errors.careerHighlights}
                            value={formData.careerHighlights}
                            onChange={(value) => setFormData({...formData, careerHighlights: value})}
                            placeholder='Type your response...'
                            rows={5}
                        />
                        {/*<div className='creative-solutions--questions--block--title'>*/}
                        {/*    Does you have any specialisms or special interests relevant to the Creative Solutions Program?*/}
                        {/*</div>*/}
                        {/*<div className='creative-solutions--questions--block--description'>(Max. 500 words)</div>*/}
                        {/*<TextArea*/}
                        {/*    error={errors.specialism}*/}
                        {/*    value={formData.specialism}*/}
                        {/*    onChange={(value) => setFormData({...formData, specialism: value})}*/}
                        {/*    placeholder='Type your response...'*/}
                        {/*    rows={5}*/}
                        {/*/>*/}
                        <div className='creative-solutions--questions--block--title'>
                            What skills, qualities and experience will you bring to the Creative Solutions Program?
                        </div>
                        <div className='creative-solutions--questions--block--description'>
                            (Max. 500 words)
                        </div>
                        <TextArea
                            error={errors.skillSet}
                            value={formData.skillSet}
                            onChange={(value) => setFormData({...formData, skillSet: value})}
                            placeholder='Type your response...'
                            rows={5}
                        />
                        <div className='creative-solutions--questions--block--title'>
                            Do you have any previous experience of using immersive technologies (Virtual Realities, Augmented Realities, Mixed Realities, etc.)?
                        </div>
                        <div className='creative-solutions--questions--block--description'>
                            (Choose Yes/No. The fact that you might not have previous  experience with immersive technology or content will not impact the assessment of your application)
                        </div>
                        <div className={classNames('creative-solutions--questions--block--select', {'select--error': errors.previousExperience})}>
                            <Select
                                value={organizationOptions.filter(({value}) => formData.previousExperience === value)[0]}
                                onChange={({value}) => setFormData({...formData, previousExperience: value})}
                                placeholder='Choose one'
                                options={organizationOptions}
                            />
                            {!formData.previousExperience && errors.previousExperience ? (
                                <div className='select--message'>{errors.previousExperience}</div>
                            ) : null}
                        </div>
                        <div className='creative-solutions--questions--block--title'>
                            What is your idea for an immersive experience for the Creative Solutions Program?
                        </div>
                        <div className='creative-solutions--questions--block--description'>
                            (Please describe the initial idea of the immersive content/solution that you want to develop) (Max. 500 words)
                        </div>
                        <TextArea
                            error={errors.vision}
                            value={formData.vision}
                            onChange={(value) => setFormData({...formData, vision: value})}
                            placeholder='Type your response...'
                            rows={5}
                        />
                        <div className='creative-solutions--questions--block--title'>
                            Please tell us why your idea is important? What problem/opportunity do you want to solve/address?
                        </div>
                        <div className='creative-solutions--questions--block--description'>
                            (Max. 500 words)
                        </div>
                        <TextArea
                            error={errors.envisionedOpportunity}
                            value={formData.envisionedOpportunity}
                            onChange={(value) => setFormData({...formData, envisionedOpportunity: value})}
                            placeholder='Type your response...'
                            rows={5}
                        />
                        <div className='creative-solutions--questions--block--title'>
                            Who is the target audience for your idea? How do you believe that they will engage with your prototype?
                        </div>
                        <div className='creative-solutions--questions--block--description'>
                            (Max. 500 words)
                        </div>
                        <TextArea
                            error={errors.targetAudience}
                            value={formData.targetAudience}
                            onChange={(value) => setFormData({...formData, targetAudience: value})}
                            placeholder='Type your response...'
                            rows={5}
                        />
                        <div className='creative-solutions--questions--block--title'>
                            What motivates you to apply to Creative Solutions program?
                        </div>
                        <div className='creative-solutions--questions--block--description'>
                            (Tell us why you are applying and describe how the program can support you to achieve your objectives) (Max. 500 words)
                        </div>
                        <TextArea
                            error={errors.motivation}
                            value={formData.motivation}
                            onChange={(value) => setFormData({...formData, motivation: value})}
                            placeholder='Type your response...'
                            rows={5}
                        />
                        <div className='creative-solutions--questions--block--title'>
                            Please provide a link to any other documents that can support your application.
                            <span className='creative-solutions--questions--block--description'> (Optional)</span>
                        </div>
                        <div className='creative-solutions--questions--block--description'>
                            (I.e. CV, Portfolio, Images, Videos, Presentations, etc.)
                        </div>
                        {Object.keys(links).map((item) => (
                            <Input
                                key={item}
                                value={links[item]}
                                onChange={(value) => setLinks({...links, [item]: value})}
                                placeholder='Type/Paste a link here'
                            />
                        ))}
                        <a
                            className='creative-solutions--questions--block--add'
                            onClick={() => setLinks({...links, [Object.keys(links).length + 1]: ''})}
                        >
                            Add another link
                        </a>
                    </div>
                    <div id='section_4' className='creative-solutions--questions--actions'>
                        <button
                            onClick={() => {
                                const dateErrors = {};
                                if (!date.day) {
                                    dateErrors.day = 'Please fill day';
                                }
                                if (!date.month) {
                                    dateErrors.month = 'Please fill month';
                                }
                                if (!date.year) {
                                    dateErrors.year = 'Please fill year';
                                }
                                setDateErrors(dateErrors);
                                const errors = {};
                                // if (!formData.hasOwnProperty('representingOrganization')) {
                                //     errors.representingOrganization = 'Choose an option';
                                // }
                                // if (formData.representingOrganization && !formData.organizationName) {
                                //     errors.organizationName = 'Please fill your organization name.';
                                // }
                                if (!formData.firstName) {
                                    errors.firstName = 'Please fill your first name.';
                                }
                                if (!formData.surname) {
                                    errors.surname = 'Please fill your surname.';
                                }
                                if (!formData.city) {
                                    errors.city = 'Please fill the city you reside in.';
                                }
                                if (!formData.region) {
                                    errors.region = 'Please fill the region you reside in.';
                                }
                                if (!formData.email) {
                                    errors.email = 'Please fill your email.';
                                }
                                if (!formData.telephoneNumber) {
                                    errors.telephoneNumber = 'Please fill your telephone number in the format +966-XXXXXXXXX.';
                                }
                                // else if (formData.telephoneNumber.indexOf('+966-') !== 0 || formData.telephoneNumber.length !== 14) {
                                //     errors.telephoneNumber = 'Please fill your telephone number in the format +966-XXXXXXXXX.';
                                // }
                                if (!formData.hasOwnProperty('saudiCitizenOrResident')) {
                                    errors.saudiCitizenOrResident = 'Choose an option';
                                }
                                if (formData.saudiCitizenOrResident && !formData.nationalId) {
                                    errors.nationalId = 'Please fill your Saudi National ID or Iqama Number.';
                                }
                                if (!formData.careerHighlights) {
                                    errors.careerHighlights = 'Please answer this question.';
                                }
                                if (!formData.employmentStatus) {
                                    errors.employmentStatus = 'Choose an employment status';
                                }
                                if (!formData.englishProficiency) {
                                    errors.englishProficiency = 'Choose your english proficiency';
                                }
                                if (!formData.hasOwnProperty('previousExperience')) {
                                    errors.previousExperience = 'Choose an option';
                                }
                                // if (!formData.specialism) {
                                //     errors.specialism = 'Please answer this question.';
                                // }
                                if (!formData.skillSet) {
                                    errors.skillSet = 'Please answer this question.';
                                }
                                if (!formData.vision) {
                                    errors.vision = 'Please answer this question.';
                                }
                                if (!formData.envisionedOpportunity) {
                                    errors.envisionedOpportunity = 'Please answer this question.';
                                }
                                if (!formData.targetAudience) {
                                    errors.targetAudience = 'Please answer this question.';
                                }
                                if (!formData.motivation) {
                                    errors.motivation = 'Please answer this question.';
                                }
                                ['careerHighlights', 'specialism', 'skillSet', 'vision', 'envisionedOpportunity', 'targetAudience', 'motivation'].forEach((field) => {
                                    if ((formData[field] || '').split(' ').length > 500) {
                                        errors[field] = 'Maximum 500 words allowed.'
                                    }
                                });
                                setErrors(errors);
                                if (!Object.keys(errors).length && !Object.keys(dateErrors).length) {
                                    const payload = {
                                        ...formData,
                                        submissionStatus: 'SUBMITTED',
                                        dateOfBirth: `${date.day}/${date.month}/${date.year}`,
                                        referenceLinks: Object.values(links).join(',')
                                    }
                                    api.post(`/api/v1/application-form`, payload).then(({data}) => {
                                        setInitialData(data);
                                        setConfirmationToast('Application Saved');
                                        setTimeout(() => setConfirmationToast(null), 2500);
                                        if (data.submissionStatus === 'SUBMITTED') {
                                            history.push(`/apply/completed${window.location.search}`);
                                        }
                                    });
                                }
                            }}
                        >
                            Submit Application
                        </button>
                    </div>
                </div>
                <div className='creative-solutions--sidebar'>
                    <div className='creative-solutions--sidebar--header'>
                        Contents
                    </div>
                    <div className='creative-solutions--sidebar--navigation'>
                        <a className='creative-solutions--sidebar--item'>
                            1. Applicant
                        </a>
                        <a className='creative-solutions--sidebar--item'>
                            2. Contact
                        </a>
                        <a className='creative-solutions--sidebar--item'>
                            3. Details
                        </a>
                        <a className='creative-solutions--sidebar--item'>
                            4. Submit
                        </a>
                    </div>
                    <div className='creative-solutions--sidebar--header'>
                        Elsewhere
                    </div>
                    <a className='creative-solutions--sidebar--item' target="_blank" href={`/creative-solutions/application${window.location.search}`}>
                        Judging Criteria
                    </a>
                    <a className='creative-solutions--sidebar--item' target="_blank" href={`/creative-solutions/application${window.location.search}`}>
                        Frequently Asked Questions
                    </a>
                    <a className='creative-solutions--sidebar--item' target="_blank" href={`/creative-solutions/home${window.location.search}`}>
                        About Creative Program
                    </a>
                </div>
            </div>
        </div>
    );
};

export default withRouter(CreativeSolutionsApplicant);