import React, {useEffect} from 'react';



const LinkedinSignUp = () => {
    useEffect(() => {
        window.opener.location.href = `${window.opener.location.origin}/linkedin-login/${window.location.search}`;
        window.close();
    }, []);
    return (
        <div/>
    );
};

export default LinkedinSignUp;