import React, {useState} from 'react';
import classNames from 'classnames';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import moment from "moment";


const DatePicker = ({value, onChange, placeholder, error, minDate}) => {
    const [isShowing, setIsShowing] = useState(true);
    return (
        <div className={classNames('form-input', {'form-input--error': error})}>
            {isShowing ? (
                <DayPickerInput
                    onDayChange ={(newValue, modifiers, dayPickerInput) => {
                        const changedValue = newValue || (dayPickerInput.getInput().value ? moment(dayPickerInput.getInput().value, 'MM/DD/YYYY').toDate() : null);
                        if (changedValue && changedValue.setHours(0,0,0,0) >= new Date().setHours(0,0,0,0)) {
                            onChange(changedValue);
                        } else {
                            setIsShowing(false);
                            onChange(value);
                            setTimeout(() => setIsShowing(true), 0);
                        }
                    }}
                    value={value}
                    placeholder={placeholder}
                    formatDate={() => value && moment(value).format('MM/DD/YYYY')}
                />
            ) : null}
            {error ? (
                <div className='form-input--message'>{error}</div>
            ) : null}
        </div>
    );
};

export default DatePicker;
