import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router";
import api from "../../api";
import {Helmet} from "react-helmet";
import localization from "../../localization";
import InputPair from "../../components/form/InputPair";
import {LinkedIn} from "react-linkedin-login-oauth2";
import {Link} from "react-router-dom";


const Join = ({location, history, onJoinToWebinar}) => {
    const [webinar, setWebinar] = useState({});
    useEffect(() => {
        const webinarLink = location.pathname.split('/w/')[1];
        api.get(`/api/v1/event/public/link/${webinarLink}`).then(({data}) => {
            setWebinar(data);
        });
    }, []);
    return (
        <div className='page-landing join'>
            <Helmet>
                <title>Login | {process.env.REACT_APP_PROJECT_NAME}</title>
                <meta name="description" content="Log in to Last Link." />
            </Helmet>
            <div className='landing--content'>
                <div className='landing--logo'>
                    <img alt='logo' src={`/images/${process.env.REACT_APP_PROJECT}-logo-big.svg`} />
                </div>
                <div className='landing--title'>
                    Welcome to {webinar.name}
                </div>
                <div className='landing--subtitle'>
                    If you’re already registered:
                </div>
                <Link to='/sign-in'>
                    <button
                        className='submit-button'
                        onClick={() => {
                            localStorage.setItem('joinWebinar', webinar.id);
                        }}
                    >
                        Sign-in to my account
                    </button>
                </Link>
                <div className='join--footer'>
                    Need to Register? <a href='https://www.ithra.com/en/special-programs/tanween-2020/get-tickets/' target='_blank'>Go to Ithra.com</a>
                </div>
            </div>
        </div>
    );
};

export default withRouter(Join);
