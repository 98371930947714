import React, {useState} from 'react';
import {LinkedIn} from 'react-linkedin-login-oauth2';
import {Helmet} from "react-helmet";
import {withRouter} from "react-router-dom";
import localization from "../../localization";
import InputPair from "../../components/form/InputPair";
import api from "../../api";



const Root = ({history}) => {
    const [formData, setFormData] = useState({});
    return (
        <div className='page-landing root'>
            <Helmet>
                <title>Login | {process.env.REACT_APP_PROJECT_NAME}</title>
                <meta name="description" content="Ithra | About Ithra" />
            </Helmet>
            <div className='landing--content'>
                <div className='landing--logo'>
                    <img alt='logo' src={`/images/${process.env.REACT_APP_PROJECT}-logo-big.svg`} />
                </div>
                <div className='landing--title'>
                    {localization.landingRootTitle}
                </div>
                <div className='landing--subtitle'>
                    {localization.landingRootDescription}
                </div>
                <a
                    className='submit-button'
                    href='https://www.ithra.com/en/about-us/about-ithra/'
                    target='_blank'
                >
                    {localization.landingRootVisit}
                </a>
            </div>
        </div>
    );
};

export default withRouter(Root);