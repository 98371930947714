import React from 'react';
import classNames from 'classnames';

const Navigation = ({title, subtitle, children}) => {
    return (
        <div className='navigation'>
            <div className='navigation--title'>
                {title}
            </div>
            {subtitle ? (
                <div className='navigation--subtitle'>
                    {subtitle}
                </div>
            ) : null}
            <div className={classNames('navigation--content', {'navigation--content--with-subtitle': subtitle})}>
                {children}
            </div>
        </div>
    );
};

export default Navigation;