import React, {useContext, useEffect, useState} from 'react';
import Messaging from "../messaging/Messaging";
import api from "../../api";
import {store} from "../../store";
import Dropdown from "../content/Dropdown";
import MessagingActions from "./MessagingActions";
import localization from "../../localization";



const ProfileDetails = ({chatId, setChatId, senderSid, sender, receiver}) => {
    const [messages, setMessages] = useState([]);
    const [mutedChats, setMutedChats] = useState([]);
    const [chat, setChat] = useState(null);
    const {twilioClient, setActiveChat, oneToOneUnread, setOneToOneUnread} = useContext(store);
    useEffect(() => {
        if (twilioClient) {
            twilioClient.getSubscribedChannels().then((paginator) => {
                let chat = paginator.items.filter(({sid}) => sid === chatId)[0];
                const loadChanel = (paginator) => {
                    if (!chat) {
                        if (paginator.hasNextPage) {
                            paginator.nextPage().then((paginator) => {
                                chat = paginator.items.filter(({sid}) => sid === chatId)[0];
                                loadChanel(paginator);
                            })
                        }
                    } else {
                        setChat(chat);
                    }
                };
                loadChanel(paginator);
            });
        }
    }, [twilioClient]);
    useEffect(() => {
        if (chat) {
            setActiveChat(chatId);
            const messages = [];
            chat.getMessages().then((data) => {
                data.items.forEach((item) => {
                    messages.push({
                        ...item.state,
                        baseMessage: item,
                        user: item.state.memberSid === senderSid ? sender : receiver,
                        isCreator: item.state.memberSid === senderSid
                    });
                });
                setMessages([...messages]);
                chat.updateLastConsumedMessageIndex(Math.min(messages.length, 30));
                setOneToOneUnread({...oneToOneUnread, [chat.sid]: 0});
            });
            chat.on('messageAdded', (item) => {
                messages.push({
                    ...item.state,
                    baseMessage: item,
                    user: item.state.memberSid === senderSid ? sender : receiver,
                    isCreator: item.state.memberSid === senderSid
                });
                setMessages([...messages]);
                if (chat.sid === localStorage.getItem('activeChat')) {
                    chat.updateLastConsumedMessageIndex(Math.min(messages.length, 30));
                    setOneToOneUnread({...oneToOneUnread, [chat.sid]: 0});
                }
            });
        }
        return () => setActiveChat(null);
    }, [chat]);
    return (
        <>
            <MessagingActions>
                <Dropdown
                    button={(<img alt='elipses' src='/images/elipses-vertical.svg' />)}
                >
                    <button
                        onClick={() => {
                            // mute
                        }}
                    >
                        {mutedChats.indexOf(chatId) >= 0 ? localization.messagingUnmute : localization.messagingMute}
                    </button>
                    <button
                        onClick={() => {
                        }}
                    >
                        {localization.messagingBlock}
                    </button>
                </Dropdown>
            </MessagingActions>
            <Messaging
                key={chat}
                messages={messages}
                hoverActions={(message) => (
                    <>
                        <button
                            onClick={() => {
                                setMessages(messages.filter(({sid}) => message.sid !== sid));
                                message.baseMessage.remove();
                            }}
                            disabled={!message.isCreator}
                        >
                            {localization.deletE}
                        </button>
                    </>
                )}
                sendMessage={(message) => {
                    if (chat) {
                        chat.sendMessage(message, {
                            title: `${sender.firstName} ${sender.lastName}`,
                            link: `${sender.myLastLink}?tab=chat`,
                            chatId,
                            userId: sender.id
                        });
                    }
                }}
            />
        </>
    );
};

export default ProfileDetails;