import React, {useRef} from 'react';
import classNames from 'classnames';
import Badge from "../content/Badge";


const Tabs = ({items, badges, selected, scrollArrows, onChange}) => {
    const tabsContainer = useRef();
    return (
        <div className='tabs'>
            {scrollArrows ? (
                <button
                    className='tabs--arrow-left'
                    onClick={() => {
                        tabsContainer.current.scrollLeft -= 120;
                    }}
                >
                    <img alt='back' src='/images/back.svg'/>
                </button>
            ) : null}
            <div
                className='tabs--content'
                ref={tabsContainer}
            >
                {items.map((item, index) => (
                    <button
                        key={index}
                        className={classNames('tabs--item', {'tabs--item--active': index === selected})}
                        onClick={() => onChange(index)}
                    >
                        {item}
                        {badges ? (
                            <Badge count={badges[index]}/>
                        ) : null}
                    </button>
                ))}
            </div>
            {scrollArrows ? (
                <button
                    className='tabs--arrow-right'
                    onClick={() => {
                        tabsContainer.current.scrollLeft += 120;
                    }}
                >
                    <img alt='back' src='/images/back.svg'/>
                </button>
            ) : null}
        </div>
    );
};

export default Tabs;
