import React, {useEffect, useState} from 'react';
import TextArea from "../../../components/form/TextArea";
import api from "../../../api";
import localization from "../../../localization";
import ModalCentral from "../../../components/modal/ModalCentral";

const Note = ({eventId, eventParticipant, handleUpdate, handleClose}) => {
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    useEffect(() => {
        setFormData({notes: eventParticipant.notes});
    }, [eventParticipant]);
    return (
        <ModalCentral
            title={localization.webinarPeopleAddNote}
            submitLabel={localization.save}
            handleClose={handleClose}
            onSubmit={() => {
                const data = {
                    eventId,
                    eventParticipantId: eventParticipant.id,
                    notes: formData.notes
                };
                const errors = ['notes'].filter((item) => !data[item]).reduce((obj, item) => {
                    obj[item] = localization.mandatory;
                    return obj;
                }, {});
                setErrors(errors);
                if (!Object.keys(errors).length) {
                    api.put(`/api/v1/event/participants/note`, data).then(({data}) => {
                        handleUpdate(data);
                        handleClose();
                    });
                }
            }}
        >
            <TextArea
                value={formData.notes}
                error={!formData.notes && errors.notes}
                onChange={(value) => setFormData({...formData, notes: value})}
                placeholder={localization.webinarNotePlaceholder}
                rows={6}
            />
        </ModalCentral>
    );
};

export default Note;