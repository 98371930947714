import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router";
import timezones from 'timezones.json';
import api from "../../api";
import {store} from "../../store";
import {linkPrefixes} from "../../utils/constants";
import Input from "../../components/form/Input";
import LinkInput from "../../components/form/LinkInput";
import Avatar from "../../components/content/Avatar";
import LocationInput from "../../components/form/LocationInput";
import {Helmet} from "react-helmet";
import localization from "../../localization";
import InputPair from "../../components/form/InputPair";



const Onboarding = ({history}) => {
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const {currentUser, setCurrentUser} = useContext(store);
    const [initializaed, setInitializaed] = useState(false);
    useEffect(() => {
        if (!currentUser.position && currentUser.organization && currentUser.timeZoneId) {
            if (localStorage.getItem('profileSuffix')) {
                history.push(`/${localStorage.getItem('profileSuffix')}`);
            } else if (localStorage.getItem('organizationSuffix')) {
                history.push(`/o/${localStorage.getItem('organizationSuffix')}`);
            } else {
                history.push('/');
            }
        } else {
            const userData = {
                image: currentUser.image,
                firstName: currentUser.firstName,
                lastName: currentUser.lastName,
                bio: currentUser.bio,
                organization: currentUser.organization,
                position: currentUser.position,
                logo: currentUser.logo,
                linkedInProfileLink: currentUser.linkedInProfileLink,
            };
            setFormData(userData);
            api.get(`https://ipinfo.io/json?token=${process.env.REACT_APP_IP_INFO}`).then(({data}) => {
                setFormData({
                    ...userData,
                    ...{
                        location: currentUser.location || `${data.city}, ${data.region}, ${data.country}`,
                        timeZoneId: data.timezone,
                        timeZoneName: (timezones.filter(({utc}) => utc.indexOf(data.timezone) >= 0)[0] || {}).value
                    }
                });
                setInitializaed(true);
            });
        }
    }, [currentUser]);
    return (
        <div className='page-landing onboarding'>
            <Helmet>
                <title>Onboarding | {process.env.REACT_APP_PROJECT_NAME}</title>
                <meta name="description" content="Create an account for Last Link." />
            </Helmet>
            <form className='landing--content'>
                <div className='landing--logo'>
                    <img alt='logo' src={`/images/${process.env.REACT_APP_PROJECT}-logo-big.svg`} />
                </div>
                <Avatar
                    className='onboarding--image'
                    image={formData.image}
                    fullName={`${formData.firstName} ${formData.lastName}`}
                />
                <div className='landing--title-smaller'>
                    {localization.onboardingWelcome} {formData.firstName} {formData.lastName}.
                </div>
                <div className='landing--subtitle'>
                    {localization.onboardingAlmostThere}
                </div>
                <InputPair
                    value={formData.organization}
                    error={formData.organization && errors.organization}
                    onChange={(value) => setFormData({...formData, organization: value})}
                    label={localization.onboardingCompany}
                />
                <InputPair
                    value={formData.position}
                    error={formData.position && errors.position}
                    onChange={(value) => setFormData({...formData, position: value})}
                    label={localization.onboardingPosition}
                />
                <div className='input-pair'>
                    <div className='input-pair--label'>{localization.onboardingLinkedin}</div>
                    <LinkInput
                        image={(<img alt='linkedin' src='/images/linkedin.svg' />)}
                        prefix={linkPrefixes[0]}
                        error={formData.linkedInProfileLink && errors.linkedInProfileLink}
                        value={formData.linkedInProfileLink}
                        onChange={(value) => setFormData({...formData, linkedInProfileLink: value})}
                    />
                    <div className='flex-between'>
                        <div/>
                        <a href={formData.linkedInProfileLink ? (formData.linkedInProfileLink.indexOf('https://') === 0 ? formData.linkedInProfileLink : `https://www.linkedin.com/in/${formData.linkedInProfileLink}`) : 'https://www.linkedin.com/in/'} target='_blank'>{localization.onboardingLinkedinGo}</a>
                    </div>
                </div>
                {initializaed ? (
                    <div className='input-pair'>
                        <div className='input-pair--label'>{localization.onboardingLocation}</div>
                        <LocationInput
                            initialValue={formData.location}
                            onChange={(value) => setFormData({...formData, location: value})}
                            error={formData.location && errors.location}
                        />
                    </div>
                ) : null}
                <button
                    className='submit-button'
                    onClick={(event) => {
                        event.preventDefault();
                        const errors = {};
                        setErrors(errors);
                        if (!formData.organization || formData.organization.length > 100) {
                            errors.organization = localization.notValid;
                        }
                        if (!formData.position || formData.position.length > 100) {
                            errors.position = localization.notValid;
                        }
                        if (!formData.location || formData.location.length > 100) {
                            errors.location = localization.notValid;
                        }
                        if (!Object.keys(errors).length) {
                            const data = {
                                ...formData,
                                linkedInProfileLink: formData.linkedInProfileLink,
                                socialLinks: []
                            };
                            api.put(`/api/v1/users`, data).then(({data}) => {
                                setCurrentUser(data);
                                if (localStorage.getItem('profileSuffix')) {
                                    history.push(`/${localStorage.getItem('profileSuffix')}`);
                                } else if (localStorage.getItem('organizationSuffix')) {
                                    history.push(`/o/${localStorage.getItem('organizationSuffix')}`);
                                } else {
                                    history.push('/w');
                                }
                            });
                        }
                    }}
                    disabled={!(formData.organization && formData.organization.trim() && formData.position && formData.position.trim())}
                >
                    Complete
                </button>
                <br/>
            </form>
        </div>
    );
};

export default withRouter(Onboarding);
