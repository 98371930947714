import React, {useContext} from 'react';
import {Link, withRouter} from 'react-router-dom';
import classNames from 'classnames';
import localization from "../../localization";
import Badge from "../content/Badge";
import {store} from "../../store";
import Avatar from '../content/Avatar'
import MoreNavigation from "./MoreNavigation";

const PageNavigation = ({location}) => {
    const {currentUser, eventNotifications, oneToOneUnread, discussionUnread} = useContext(store);
    return (
        <div className='page-navigation'>
            <Link
                to='/'
                className='page-navigation--logo'
            >
                <img alt='logo' src={`/images/${process.env.REACT_APP_PROJECT}-logo.svg`} />
            </Link>
            <Link to='/'>
                <div className={classNames('page-navigation--item', {'page-navigation--item--active': location.pathname === '/'})}>
                    <img alt='webinars' src={`/images/${process.env.REACT_APP_PROJECT}-reception-inactive.png`} />
                    <img alt='webinars' src={`/images/${process.env.REACT_APP_PROJECT}-reception.png`} />
                    <div className='page-navigation--item--text'>Reception</div>
                </div>
            </Link>
            <Link to='/apply'>
                <div className={classNames('page-navigation--item', {'page-navigation--item--active': location.pathname === '/apply' || location.pathname.substring(0, 7) === '/apply/'})}>
                    <img alt='webinars' src={`/images/${process.env.REACT_APP_PROJECT}-apply-inactive.png`} />
                    <img alt='webinars' src={`/images/${process.env.REACT_APP_PROJECT}-apply.png`} />
                    <div className='page-navigation--item--text'>Apply</div>
                </div>
            </Link>
            <Link to='/announce'>
                <div className={classNames('page-navigation--item', {'page-navigation--item--active': location.pathname === '/announce'})}>
                    <img alt='webinars' src={`/images/${process.env.REACT_APP_PROJECT}-announce-inactive.png`} />
                    <img alt='webinars' src={`/images/${process.env.REACT_APP_PROJECT}-announce.png`} />
                    <div className='page-navigation--item--text'>Announce</div>
                </div>
            </Link>
            <Link to='/w'>
                <div className={classNames('page-navigation--item', {'page-navigation--item--active': location.pathname === '/w' || location.pathname.substring(0, 3) === '/w/'})}>
                    <Badge count={eventNotifications.length + Object.values(discussionUnread).reduce((count, item) => (count + item), 0)}/>
                    <img alt='webinars' src={`/images/${process.env.REACT_APP_PROJECT}-webinars-inactive.png`} />
                    <img alt='webinars' src={`/images/${process.env.REACT_APP_PROJECT}-webinars.png`} />
                    <div className='page-navigation--item--text'>Rooms</div>
                </div>
            </Link>
            <Link to='/forum'>
                <div className={classNames('page-navigation--item', {'page-navigation--item--active': location.pathname === '/forum'})}>
                    <img alt='contacts' src={`/images/${process.env.REACT_APP_PROJECT}-forum-inactive.png`} />
                    <img alt='contacts' src={`/images/${process.env.REACT_APP_PROJECT}-forum.png`} />
                    <div className='page-navigation--item--text'>Forum</div>
                </div>
            </Link>
            <Link to='/contacts'>
                <div className={classNames('page-navigation--item', {'page-navigation--item--active': location.pathname === '/contacts'})}>
                    <img alt='contacts' src={`/images/${process.env.REACT_APP_PROJECT}-contacts-inactive.png`} />
                    <img alt='contacts' src={`/images/${process.env.REACT_APP_PROJECT}-contacts.png`} />
                    <div className='page-navigation--item--text'>People</div>
                </div>
            </Link>
            <Link to='/messages'>
                <div className={classNames('page-navigation--item', {'page-navigation--item--active': location.pathname === '/messages'})}>
                    <Badge count={Object.values(oneToOneUnread).reduce((count, item) => (count + item), 0)}/>
                    <img alt='chat' src={`/images/${process.env.REACT_APP_PROJECT}-chat-inactive.png`} />
                    <img alt='chat' src={`/images/${process.env.REACT_APP_PROJECT}-chat.png`} />
                    <div className='page-navigation--item--text'>Chat</div>
                </div>
            </Link>
            <Link to='/meet' style={{display: 'none'}}>
                <div className={classNames('page-navigation--item', {'page-navigation--item--active': location.pathname === '/meet'})}>
                    <img alt='meet' src={`/images/${process.env.REACT_APP_PROJECT}-meet-inactive.png`} />
                    <img alt='meet' src={`/images/${process.env.REACT_APP_PROJECT}-meet.png`} />
                    <div className='page-navigation--item--text'>Meet</div>
                </div>
            </Link>
            <Link to='/table'>
                <div className={classNames('page-navigation--item', {'page-navigation--item--active': location.pathname === '/table'})}>
                    <img alt='meet' src={`/images/${process.env.REACT_APP_PROJECT}-table-inactive.png`} />
                    <img alt='meet' src={`/images/${process.env.REACT_APP_PROJECT}-table.png`} />
                    <div className='page-navigation--item--text'>Table</div>
                </div>
            </Link>
            <Link to='/account'>
                <div className={classNames('page-navigation--item', 'page-navigation--avatar', {'page-navigation--item--active': location.pathname.indexOf('/account') === 0})}>
                    <Avatar
                        image={currentUser.image}
                        fullName={`${currentUser.firstName} ${currentUser.lastName}`}
                    />
                    <div className='page-navigation--item--text'>{localization.navigationAccount}</div>
                </div>
            </Link>
            <MoreNavigation/>
        </div>
    );
};

export default withRouter(PageNavigation);