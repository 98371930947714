import React, {useState, useEffect, useContext} from 'react';
import {Redirect, withRouter} from "react-router";
import {Route, Switch} from "react-router-dom";
import Page from '../../components/page/Page';
import PageContent from '../../components/page/PageContent';
import Navigation from '../../components/navigation/Navigation';
import NavigationGroup from '../../components/navigation/NavigationGroup';
import NavigationItem from '../../components/navigation/NavigationItem';
import Tabs from '../../components/tabs/Tabs';
import api from '../../api';
import {store} from "../../store";
import About from './components/About';
import People from './components/People';
import Discussion from "./components/Discussion";
import CreateWebinar from "./components/CreateWebinar";
import ProfileDetails from "../../components/profile/ProfileDetails";
import OrganizatonView from "../../components/organization/OrganizatonView";
import QnA from "./components/QnA";
import Answers from "./components/Answers";
import Join from "../join/Join";
import Resources from "./components/Resources";
import JoinRegistration from './components/JoinRegistration';
import {Helmet} from "react-helmet";
import NotApproved from "./components/NotApproved";
import Registrants from "./components/Registrants";
import localization from '../../localization';
import Default from "./components/Default";
import Input from "../../components/form/Input";
import GridView from "./components/GridView";



const Webinars = ({location, history}) => {
    const [eventsCreatedByMe, setEventsCreatedByMe] = useState([]);
    const [eventsAttendedByMe, setEventsAttendedByMe] = useState([]);
    const [selectedWebinarId, setSelectedWebinarId] = useState(null);
    const [selectedWebinar, setSelectedWebinar] = useState(null);
    const [eventParticipants, setEventParticipants] = useState(null);
    const [selectedOrganizationId, setSelectedOrganizationId] = useState(null);
    const [createWebinar, setCreateWebinar] = useState(false);
    const [editWebinar, setEditWebinar] = useState(false);
    const [joinToWebinar, setJoinToWebinar] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [defaultTab, setDefaultTab] = useState(0);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [selectedPersonId, setSelectedPersonId] = useState(null);
    const [confirmationToast, setConfirmationToast] = useState(null);
    const [gridView, setGridView] = useState(false);
    const [search, setSearch] = useState('');
    const [openedGroup, setOpenedGroup] = useState('attended');
    const {currentUser, eventNotifications, discussionUnread} = useContext(store);

    useEffect(() => {
        api.get(`/api/v2/event`).then(({data}) => {
            const {eventsCreatedByMe, eventsAttendedByMe, organizationEventsCreatedToWhichIAmAdmin, organizationEventsAttendedByMe} = data;
            const createdEvents = [
                ...eventsCreatedByMe,
                ...organizationEventsCreatedToWhichIAmAdmin
            ];
            const attendedEvents = [
                ...eventsAttendedByMe.filter((item) => item.eventParticipantStatus !== 'BLOCKED' && [1,2,3].indexOf(item.id) < 0),
                ...organizationEventsAttendedByMe.filter((item) => item.eventParticipantStatus !== 'BLOCKED' && [1,2,3].indexOf(item.id) < 0),
            ];
            setEventsCreatedByMe(createdEvents);
            setEventsAttendedByMe(attendedEvents);
            const webinarLink = location.pathname.split('/')[2];
            setSelectedTab(routes.indexOf(location.pathname.split('/')[3]) >= 0 ? routes.indexOf(location.pathname.split('/')[3]) : 0);
            if (webinarLink) {
                let webinar = null;
                createdEvents.forEach((item) => {
                    if (item.lastLink.split('/w/')[1].toLowerCase() === webinarLink.toLowerCase()) {
                        setSelectedWebinarId(item.id);
                        webinar = item;
                    }
                });
                attendedEvents.forEach((item) => {
                    if (item.lastLink.split('/w/')[1].toLowerCase() === webinarLink.toLowerCase()) {
                        setSelectedWebinarId(item.id);
                        webinar = item;
                    }
                });
                if (!webinar) {
                    setJoinToWebinar(true);
                }
            }
            const joinWebinar = localStorage.getItem('joinWebinar');
            if (joinWebinar) {
                let webinar = null;
                createdEvents.forEach((item) => {
                    if (item.id === parseInt(joinWebinar)) {
                        setSelectedWebinarId(item.id);
                        webinar = item;
                    }
                });
                attendedEvents.forEach((item) => {
                    if (item.id === parseInt(joinWebinar)) {
                        setSelectedWebinarId(item.id);
                        webinar = item;
                    }
                });
                if (!webinar) {
                    const data = {
                        eventId: joinWebinar,
                        participantUserId: currentUser.id
                    };
                    api.post(`/api/v1/event/participants`, data).then(({data}) => {
                        const existingWebinar = attendedEvents.filter(({id}) => id === data.id)[0] || createdEvents.filter(({id}) => id === data.id)[0];
                        if (existingWebinar) {
                            setSelectedWebinarId(existingWebinar.id);
                        } else {
                            setEventsAttendedByMe([...attendedEvents.filter((item) => item.eventParticipantStatus !== 'BLOCKED'), data]);
                            setSelectedWebinarId(data.id);
                        }
                        history.push(`/w/${data.lastLink.split('/w/')[1]}`);
                        setJoinToWebinar(false);
                    });
                }
                localStorage.removeItem('joinWebinar');
            }
        });
    }, []);
    useEffect(() => {
        if (selectedWebinarId) {
            api.get(`/api/v2/event/${selectedWebinarId}`).then(({data}) => {
                setSelectedWebinar(data);
            });
            api.get(`/api/v1/event/${selectedWebinarId}/participants`).then(({data}) => {
                setEventParticipants(data);
            });
        } else {
            setSelectedWebinar(null);
            setEventParticipants(null);
        }
    }, [selectedWebinarId]);
    useEffect(() => {
        if (location.pathname.split('/')[3]) {
            setSelectedTab(routes.indexOf(location.pathname.split('/')[3]));
        }
    }, [location.pathname]);

    const renderNavigationItem = (item, index, selectedWebinar) => (
        <NavigationItem
            key={index}
            image={(<img alt='video' src={item.coverImage} />)}
            title={item.name}
            description={item.lastLink}
            onClick={() => {
                setSelectedPersonId(null);
                setSelectedMessage(null);
                setSelectedWebinarId(null);
                setSelectedOrganizationId(null);
                setSelectedTab(0);
                history.push(`/w/${item.lastLink.split('/w/')[1]}`);
                setTimeout(() => setSelectedWebinarId(item.id), 0);
            }}
            isActive={selectedWebinar && (item.id === selectedWebinar.id)}
            badge={eventNotifications.filter(({objectId}) => objectId === item.id).length + (discussionUnread[Object.keys(discussionUnread).filter((id) => id === item.twilioGroupChatId)[0]] || 0)}
        />
    );
    const routes = ['about', 'people', 'discussion', 'qna', 'resources', 'registrants'];
    return (
        <Page
            className='page-webinars'
        >
            <Helmet>
                <title>Your Rooms | {process.env.REACT_APP_PROJECT_NAME}</title>
                <meta name="description" content="Create Last Links and share with people to form Virtual Conferences, Groups, Communities, and more." />
            </Helmet>
            {!gridView ? (
                <Navigation
                    title={(
                        <div className='flex-between'>
                            <div>{localization.webinarLinks}</div>
                            <div className='flex-between'>
                                {/*<button
                                    className='page-webinars--toggle'
                                    onClick={() => setGridView(true)}
                                >
                                    <img alt='grid' src='/images/grid.svg' />
                                </button>*/}
                                {currentUser.id === 176 || currentUser.id === 173 ? (
                                    <button
                                        className='action-button'
                                        onClick={() => setCreateWebinar(true)}
                                    >
                                        {localization.webinarCreateLL}
                                    </button>
                                ) : null}
                            </div>
                        </div>
                    )}
                    subtitle={(
                        <div className='page-webinars--search'>
                            <Input
                                placeholder={localization.webinarSearch}
                                value={search}
                                onChange={(value) => setSearch(value)}
                            />
                            <img alt='search-gray' src='/images/search-gray.svg' />
                        </div>
                    )}
                >
                    {/*<NavigationGroup
                        title={`${localization.webinarHosted} Creative Solution`}
                        collapsable
                        opened={openedGroup === 'created'}
                        setOpened={() => setOpenedGroup(openedGroup === 'created' ? null : 'created')}
                    >
                        {eventsCreatedByMe.filter((({eventType, name}) => eventType === 'INDIVIDUAL' && (name || '').indexOf(search) >= 0)).length ? (
                            eventsCreatedByMe.filter((({eventType, name}) => eventType === 'INDIVIDUAL' && (name || '').indexOf(search) >= 0)).map((item, index) => (
                                renderNavigationItem(item, index, selectedWebinar)
                            ))
                        ) : (
                            <NavigationItem
                                content={(<div>None hosted yet. <a href='https://www.loom.com/share/3e40df9c7ef445e98deebe435a657707' target='_blank'>Learn how to</a></div>)}
                            />
                        )}
                    </NavigationGroup>*/}
                    {Object.values(eventsCreatedByMe.filter((({eventType, name}) => eventType === 'ORGANIZATION' && (name || '').toLowerCase().indexOf(search.toLowerCase()) >= 0)).reduce((object, item) => {
                        if (!object[item.creatorOrganization.name]) {
                            object[item.creatorOrganization.name] = {
                                name: item.creatorOrganization.name,
                                items: []
                            };
                        }
                        object[item.creatorOrganization.name].items.push(item);
                        return object;
                    }, {})).map((item, index) => (
                        <NavigationGroup
                            key={index}
                            title={`${localization.webinarHosted} ${item.name}`}
                            collapsable
                            opened={openedGroup === item.name}
                            setOpened={() => setOpenedGroup(openedGroup === item.name ? null : item.name)}
                        >
                            {item.items.map((item, index) => (
                                renderNavigationItem(item, index, selectedWebinar)
                            ))}
                        </NavigationGroup>
                    ))}
                    <NavigationGroup
                        title={`Attended`}
                        collapsable
                        opened={openedGroup === 'attended'}
                        setOpened={() => setOpenedGroup(openedGroup === 'attended' ? null : 'attended')}
                    >
                        {eventsAttendedByMe.length ? (
                            eventsAttendedByMe.filter((({name}) => (name || '').toLowerCase().indexOf(search.toLowerCase()) >= 0)).map((item, index) => (
                                renderNavigationItem(item, index, selectedWebinar)
                            ))
                        ) : (
                            <NavigationItem
                                content={(<div>None attended yet.</div>)}
                            />
                        )}
                    </NavigationGroup>
                </Navigation>
            ) : null}
            <Switch>
                <Route
                    path='/w/:webinarLink'
                    render={() => (
                        selectedWebinar && eventParticipants ? (
                            <PageContent
                                title={selectedWebinar.name}
                                subtitle={(
                                    <Tabs
                                        items={[
                                            localization.webinarAbout,
                                            localization.webinarPeople,
                                            localization.webinarDiscussion,
                                            localization.webinarQnA,
                                            localization.webinarResources,
                                            ...(selectedWebinar.loggedInUserEventParticipant.admin ? [localization.webinarRegistrants] : [])
                                        ]}
                                        badges={(() => {
                                            const notifications = eventNotifications.filter(({objectId}) => objectId === selectedWebinar.id);
                                            return [
                                                notifications.filter(({notificationsType}) => notificationsType === 'EVENT_ABOUT').length,
                                                notifications.filter(({notificationsType}) => notificationsType === 'EVENT_PEOPLE').length,
                                                (discussionUnread[Object.keys(discussionUnread).filter((id) => id === selectedWebinar.twilioGroupChatId)[0]] || 0),
                                                notifications.filter(({notificationsType}) => notificationsType === 'EVENT_QNA').length,
                                                notifications.filter(({notificationsType}) => notificationsType === 'EVENT_RESOURCE').length,
                                                notifications.filter(({notificationsType}) => notificationsType === 'EVENT_REGISTRANTS').length,
                                            ]
                                        })()}
                                        selected={selectedTab}
                                        onChange={(index) => {
                                            history.push(`/w/${location.pathname.split('/')[2]}/${routes[index]}`)
                                        }}
                                        scrollArrows
                                    />
                                )}
                                backAction={() => {
                                    setSelectedWebinarId(null);
                                    history.push('/w')
                                }}
                                isFullWidth={[0, 1, 2, 3, 4, 5].indexOf(selectedTab) >= 0}
                                isNoPadding={[2, 3].indexOf(selectedTab) >= 0}
                                isWithInfiniteScroll
                                noBackOnDesktop={!gridView}
                                dialog={(
                                    <>
                                        {selectedPersonId ? (
                                            <ProfileDetails
                                                personId={selectedPersonId}
                                                defaultTab={defaultTab}
                                                backAction={() => {
                                                    setDefaultTab(0);
                                                    setSelectedPersonId(null);
                                                }}
                                            />
                                        ) : null}
                                        {selectedOrganizationId ? (
                                            <OrganizatonView
                                                organizationId={selectedOrganizationId}
                                                backAction={() => setSelectedOrganizationId(null)}
                                            />
                                        ) : null}
                                    </>
                                )}
                                action={selectedWebinar.loggedInUserEventParticipant.host || selectedWebinar.loggedInUserEventParticipant.admin ? (
                                    <button
                                        className='bordered-button'
                                        onClick={() => setEditWebinar(selectedWebinar)}
                                    >
                                        {localization.webinarManage}
                                    </button>
                                ) : null}
                            >
                                <Helmet>
                                    <title>{selectedWebinar.name || ''} | {process.env.REACT_APP_PROJECT_NAME}</title>
                                    <meta name="description" content={selectedWebinar.description} />
                                </Helmet>
                                {selectedWebinar.dynamicFormValuesAdded === 'NOT_DONE' ? (
                                    <JoinRegistration
                                        data={selectedWebinar}
                                        onSubmit={(data) => {
                                            setEventsAttendedByMe(
                                                eventsAttendedByMe.map((item) => item.id === data.id ? data : item)
                                            );
                                            setSelectedWebinarId(data.id);
                                            setSelectedWebinar(data);
                                        }}
                                    />
                                ) : (
                                    <>
                                        {selectedWebinar.eventParticipantStatus === 'TO_BE_APPROVED' ? (
                                            <NotApproved
                                                title={localization.webinarNotApprovedTilte}
                                                description={localization.webinarNotApprovedDescription}
                                            />
                                        ) : null}
                                        {selectedWebinar.eventParticipantStatus === 'DENIED' ? (
                                            <NotApproved
                                                title={localization.webinarDeniedTitle}
                                                description={localization.webinarDeniedDescription}
                                            />
                                        ) : null}
                                        {selectedWebinar.eventParticipantStatus === 'REMOVED' ? (
                                            <NotApproved
                                                title={localization.webinarRemovedTitle}
                                                description={localization.webinarRemovedDescription}
                                            />
                                        ) : null}
                                        {selectedWebinar.eventParticipantStatus === 'APPROVED' ? (
                                            <>
                                                <Route
                                                    path='/w/:webinarLink'
                                                    render={() => !location.pathname.split('/')[3] ? (<Redirect to={`/w/${location.pathname.split('/')[2]}/about`} />) : null}
                                                />
                                                <Route
                                                    path={`/w/:webinarLink/${routes[0]}`}
                                                    render={() => (
                                                        <About
                                                            data={selectedWebinar}
                                                            setConfirmationToast={setConfirmationToast}
                                                            selectPerson={setSelectedPersonId}
                                                            setSelectedOrganizationId={setSelectedOrganizationId}
                                                            eventParticipants={eventParticipants}
                                                        />
                                                    )}
                                                />
                                                <Route
                                                    path={`/w/:webinarLink/${routes[1]}`}
                                                    render={() => (
                                                        <People
                                                            data={selectedWebinar}
                                                            selectPerson={setSelectedPersonId}
                                                            setDefaultTab={setDefaultTab}
                                                            updateWebinars={(webinar) => {
                                                                eventsCreatedByMe.forEach((item) => {
                                                                    if (item.id === webinar.id) {
                                                                        item = webinar;
                                                                        setSelectedWebinarId(item.id);
                                                                    }
                                                                });
                                                                eventsAttendedByMe.forEach((item) => {
                                                                    if (item.id === webinar.id) {
                                                                        item = webinar;
                                                                        setSelectedWebinarId(item.id);
                                                                    }
                                                                });
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <Route
                                                    path={`/w/:webinarLink/${routes[2]}`}
                                                    render={() => (
                                                        <Discussion
                                                            eventParticipants={eventParticipants}
                                                            webinarId={selectedWebinar.id}
                                                            webinarName={selectedWebinar.name}
                                                            webinarLink={selectedWebinar.lastLink}
                                                            chatId={selectedWebinar.twilioGroupChatId}
                                                            selectPerson={setSelectedPersonId}
                                                            loggedInUserEventParticipant={selectedWebinar.loggedInUserEventParticipant}
                                                        />
                                                    )}
                                                />
                                                <Route
                                                    path={`/w/:webinarLink/${routes[3]}`}
                                                    render={() => (
                                                        <QnA
                                                            eventParticipants={eventParticipants}
                                                            webinarId={selectedWebinar.id}
                                                            webinarName={selectedWebinar.name}
                                                            webinarLink={selectedWebinar.lastLink}
                                                            selectMessage={setSelectedMessage}
                                                            selectPerson={setSelectedPersonId}
                                                            loggedInUserEventParticipant={selectedWebinar.loggedInUserEventParticipant}
                                                        />
                                                    )}
                                                />
                                                <Route
                                                    path={`/w/:webinarLink/${routes[4]}`}
                                                    render={() => (
                                                        <Resources
                                                            webinarId={selectedWebinar.id}
                                                            resourceUploadAccess={selectedWebinar.resourceUploadAccess}
                                                            loggedInUserEventParticipant={selectedWebinar.loggedInUserEventParticipant}
                                                            selectPerson={setSelectedPersonId}
                                                        />
                                                    )}
                                                />
                                                <Route
                                                    path={`/w/:webinarLink/${routes[5]}`}
                                                    render={() => (
                                                        <Registrants
                                                            webinarId={selectedWebinar.id}
                                                            selectPerson={setSelectedPersonId}
                                                            loggedInUserEventParticipant={selectedWebinar.loggedInUserEventParticipant}
                                                        />
                                                    )}
                                                />
                                                {selectedMessage ? (
                                                    <Answers
                                                        selectedMessage={selectedMessage}
                                                        webinarId={selectedWebinar.id}
                                                        webinarName={selectedWebinar.name}
                                                        webinarLink={selectedWebinar.lastLink}
                                                        eventParticipants={eventParticipants}
                                                        loggedInUserEventParticipant={selectedWebinar.loggedInUserEventParticipant}
                                                        backAction={() => setSelectedMessage(null)}
                                                        selectPerson={setSelectedPersonId}
                                                    />
                                                ) : null}
                                            </>
                                        ) : null}
                                    </>
                                )}
                            </PageContent>
                        ) : null
                    )}
                />
            </Switch>
            {createWebinar ? (
                <CreateWebinar
                    handleCreate={(webinar) => {
                        setEventsCreatedByMe([...eventsCreatedByMe, webinar]);
                        setSelectedTab(0);
                        setSelectedWebinarId(webinar.id);
                        history.push(`/w/${webinar.lastLink.split('/w/')[1]}`);
                    }}
                    setConfirmationToast={setConfirmationToast}
                    handleClose={() => setCreateWebinar(null)}
                />
            ) : null}
            {editWebinar ? (
                <CreateWebinar
                    webinar={editWebinar}
                    handleCreate={(webinar) => {
                        setEventsCreatedByMe(eventsCreatedByMe.map((item) => (item.id === webinar.id ? webinar : item)));
                        setSelectedTab(0);
                        setSelectedWebinar(webinar);
                        setSelectedWebinarId(webinar.id);
                        history.push(`/w/${webinar.lastLink.split('/w/')[1]}`);
                    }}
                    setConfirmationToast={setConfirmationToast}
                    handleClose={() => setEditWebinar(null)}
                />
            ) : null}
            {confirmationToast ? (
                <div className='toast--confirmation'>
                    {confirmationToast}
                </div>
            ) : null}
            {/*{joinToWebinar ? (
                <div className='webinar-join'>
                    <Join
                        onJoinToWebinar={(joinWebinar) => {
                            const data = {
                                eventId: joinWebinar,
                                participantUserId: currentUser.id
                            };
                            api.post(`/api/v1/event/participants`, data).then(({data}) => {
                                const existingWebinar = eventsAttendedByMe.filter(({id}) => id === data.id)[0] || eventsCreatedByMe.filter(({id}) => id === data.id)[0];
                                if (existingWebinar) {
                                    setSelectedWebinarId(existingWebinar.id);
                                } else {
                                    setEventsAttendedByMe([...eventsAttendedByMe, data]);
                                    setSelectedWebinarId(data.id);
                                }
                                history.push(`/w/${data.lastLink.split('/w/')[1]}`);
                                setJoinToWebinar(false);
                            });
                            localStorage.removeItem('joinWebinar');
                        }}
                    />
                </div>
            ) : null}*/}
            {!gridView && !selectedWebinar ? (
                <Default
                    handleCreate={(webinar) => {
                        setEventsCreatedByMe([...eventsCreatedByMe, webinar]);
                        setSelectedTab(0);
                        setSelectedWebinarId(webinar.id);
                        history.push(`/w/${webinar.lastLink.split('/w/')[1]}`);
                    }}
                    setConfirmationToast={setConfirmationToast}
                    setCreateWebinar={setCreateWebinar}
                />
            ) : null}
            {gridView && !selectedWebinar ? (
                <GridView
                    search={search}
                    setSearch={setSearch}
                    eventsAttendedByMe={eventsAttendedByMe}
                    eventsCreatedByMe={eventsCreatedByMe}
                    openedGroup={openedGroup}
                    setOpenedGroup={setOpenedGroup}
                    setCreateWebinar={setCreateWebinar}
                    setGridView={setGridView}
                    setConfirmationToast={setConfirmationToast}
                    selectWebinar={(item) => {
                        setSelectedPersonId(null);
                        setSelectedMessage(null);
                        setSelectedWebinarId(null);
                        setSelectedOrganizationId(null);
                        setSelectedTab(0);
                        history.push(`/w/${item.lastLink.split('/w/')[1]}`);
                        setTimeout(() => setSelectedWebinarId(item.id), 0);
                    }}
                />
            ) : null}
        </Page>
    );
};

export default withRouter(Webinars);