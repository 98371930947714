import React, {useState} from 'react';
import {LinkedIn} from 'react-linkedin-login-oauth2';
import {Helmet} from "react-helmet";
import {withRouter} from "react-router-dom";
import localization from "../../localization";
import InputPair from "../../components/form/InputPair";
import api from "../../api";



const NorFound = ({history}) => {
    return (
        <div className='notfound-page'>
            <Helmet>
                <title>Not Found | {process.env.REACT_APP_PROJECT_NAME}</title>
                <meta name="description" content="Log in to Last Link." />
            </Helmet>
            <div className='notfound-page--content'>
                <img alt='logout' src='/images/notfound.png' />
                <div className='notfound-page--title'>
                    Something went wrong
                </div>
                <div className='notfound-page--subtitle'>
                    Please return back to previous page.
                </div>
            </div>
        </div>
    );
};

export default withRouter(NorFound);