import React from 'react';
import localization from "../../../localization";



const EmptyPage = ({title, description, actionLabel, actionHandler}) => {
    return (
        <div className='empty-page'>
            <img alt='empty-page' src='/images/empty-page.svg' />
            <div className='empty-page--title'>{title}</div>
            <div className='empty-page--description'>{description}</div>
            {actionHandler ? (
                <button
                    className='action-button'
                    onClick={actionHandler}
                >
                    {actionLabel}
                </button>
            ) : null}
        </div>
    );
};

export default EmptyPage;
