import React, {useContext, useEffect, useRef, useState} from 'react';
import TextArea from "../../components/form/TextArea";
import api from "../../api";
import localization from "../../localization";
import ModalCentral from "../../components/modal/ModalCentral";
import ContentEditable from "react-contenteditable";

const messageContainer = {
    oldVersion: '',
    newVersion: ''
};

const CreateAnouncement = ({handleClose, sendMessage}) => {
    const [newMessage, setNewMessage] = useState('');
    const messagesInput = useRef();
    return (
        <ModalCentral
            className='announcement-dialog'
            title='Send a New Announcement'
            submitLabel='Send Announcement'
            handleClose={handleClose}
            onSubmit={() => {
                if (newMessage && newMessage.replace(/<[^>]*>?/gm, '')) {
                    sendMessage(newMessage.split('<div><br></div>').join('').replace(/<br><br>/g, '<br>').replace(/<br><br>/g, '<br>'));
                    handleClose()
                }
            }}
        >
            <div className='announcement-dialog--placeholder'>
                Write an announcement
            </div>
            <div className='announcement-dialog--input'>
                <ContentEditable
                    html={newMessage}
                    ref={messagesInput}
                    onChange={(event) => {
                        const message = event.target.value.replace(/<[^>]*>?/gm, '') ? event.target.value : event.target.value.replace(/<[^>]*>?/gm, '');
                        setNewMessage(message);
                        messageContainer.oldVersion = messageContainer.newVersion;
                        messageContainer.newVersion = message;
                    }}
                    onKeyDown={(event) => {
                        if (!messageContainer.oldVersion && event.keyCode === 13) {
                            event.preventDefault();
                        }
                    }}
                    // onKeyUp={(event) => {
                    //     if (event.keyCode === 13 && !event.shiftKey && (messageContainer.oldVersion || messageContainer.newVersion || '').replace(/<[^>]*>?/gm, '')) {
                    //         sendMessage(messageContainer.oldVersion.split('<div><br></div>').join('').replace(/<br><br>/g, '<br>').replace(/<br><br>/g, '<br>'));
                    //     }
                    // }}
                />
            </div>
        </ModalCentral>
    );
};

export default CreateAnouncement;