import React, {useContext, useEffect, useState} from 'react';
import "zipcan-video/zipcan-video.js";
import {store} from "../../store";
import localization from "../../localization";
import Avatar from "../../components/content/Avatar";
import Page from "../../components/page/Page";
import {Helmet} from "react-helmet";
import PageContent from "../../components/page/PageContent";
import ProfileDetails from "../../components/profile/ProfileDetails";
import api from "../../api";
import CreateTable from "./CreateTable";
import TableMeeting from "./TableMeeting";
import Dropdown from "../../components/content/Dropdown";
import MessagingActions from "../../components/messaging/MessagingActions";


const Tables = ({data, selectPerson}) => {
    const {currentUser} = useContext(store);
    const [event, setEvent] = useState(null);
    const [tables, setTables] = useState([]);
    const [isHost, setIsHost] = useState(false);
    const [isAddTable, setIsAddTable] = useState(false);
    const [isOpenMeeting, setIsOpenMeeting] = useState(null);
    const [meetingParticipantId, setMeetingParticipantId] = useState(null);

    const webinarId = 2;

    useEffect(() => {
        api.get(`/api/v1/event/${webinarId}`).then(({data}) => {
            setEvent(data);
            data.eventParticipants.forEach((item) => {
                if (item.host && item.participantUser.id === currentUser.id) {
                    setIsHost(true);
                }
            });
        });
        api.get(`/api/v1/event/${webinarId}/table`).then(({data}) => {
            setTables(data);
            data.forEach((table) => {
               table.eventTableParticipants.forEach((item) => {
                   if (item.participantUser.id === currentUser.id) {
                       setIsOpenMeeting(table.tableNo);
                       setMeetingParticipantId(item.id);
                   }
               });
            });
        });
        const interval = setInterval(() => {
            api.get(`/api/v1/event/${webinarId}/table`, {noLoader: true}).then(({data}) => {
                setTables(data);
            });
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    const tableItem = (className, item, index) => {
        return (
            <div className={className}>
                {item.eventTableParticipants[index] ? (
                    <div className='tables--item--placeholder'>
                        <Avatar
                            fullName={`${item.eventTableParticipants[index].participantUser.firstName} ${item.eventTableParticipants[index].participantUser.lastName}`}
                            image={item.eventTableParticipants[index].participantUser.image}
                        />
                    </div>
                ) : (
                    <div className='tables--item--placeholder' onClick={() => {
                        if (!isOpenMeeting) {
                            api.post(`/api/v1/event/${webinarId}/table/${item.id}/participant`, {
                                eventTableId: item.id,
                                participantUser: {id: currentUser.id}
                            }).then(({data}) => {
                                setIsOpenMeeting(item.id);
                                setMeetingParticipantId(data.id);
                                api.get(`/api/v1/event/${webinarId}/table`).then(({data}) => {
                                    setTables(data);
                                });
                            });
                        }
                    }}/>
                )}
            </div>
        )
    };

    return (
        <Page
            className='tables-page'
        >
            <Helmet>
                <title>Tables | {process.env.REACT_APP_PROJECT_NAME}</title>
                <meta name="description" content="Forum - All Posts" />
            </Helmet>
            {event && event.eventParticipants.filter((item) => item.participantUser.id === currentUser.id).length ? (
                <PageContent
                    title={`Tables`}
                    action={isHost ? (
                        <button className='action-button' onClick={() => setIsAddTable(true)}>
                            Add a Table
                        </button>
                    ) : null}
                    noBackOnDesktop
                >
                    <div className='tables--content'>
                        {tables.map((item, index) => (
                            <div
                                key={index}
                                className='tables--item'
                            >
                                <div className='tables--item--number'>
                                    {index + 1}
                                </div>
                                {isHost ? (
                                    <Dropdown
                                        button={(<img alt='elipses' src='/images/elipses.svg' />)}
                                    >
                                        <button
                                            onClick={() => {
                                                api.delete(`/api/v1/event/${webinarId}/table/${item.id}`).then(({data}) => {
                                                    api.get(`/api/v1/event/${webinarId}/table`).then(({data}) => {
                                                        setTables(data);
                                                    });
                                                });
                                            }}
                                        >
                                            Delete
                                        </button>
                                    </Dropdown>
                                ) : null}
                                <div className='tables--item--top'>
                                    {tableItem('tables--item--top', item, 0)}
                                    {tableItem('tables--item--top', item, 1)}
                                </div>
                                <div className='tables--item--content'>
                                    <div className='tables--item--left'>
                                        {tableItem('tables--item--top', item, 7)}
                                        {tableItem('tables--item--top', item, 6)}
                                    </div>
                                    <div className='tables--item--center'>
                                        <div className='tables--item--center--title'>
                                            {item.tableName}
                                        </div>
                                        {item.logo ? (
                                            <img alt='lock' src={item.logo} />
                                        ) : null}
                                    </div>
                                    <div className='tables--item--right'>
                                        {tableItem('tables--item--top', item, 2)}
                                        {tableItem('tables--item--top', item, 3)}
                                    </div>
                                </div>
                                <div className='tables--item--bottom'>
                                    {tableItem('tables--item--top', item, 5)}
                                    {tableItem('tables--item--top', item, 4)}
                                </div>
                            </div>
                        ))}
                    </div>
                </PageContent>
            ) : event ? (
                <div className='tables-empty'>
                    <div className='tables-empty--content'>
                        <div className='tables-empty--image'>
                            <img alt='lock' src='/images/no-access.png' />
                        </div>
                        <div className='tables-empty--title'>Restricted Access</div>
                        <div className='tables-empty--subtitle'>This area is open only to Participants and Administrators of 2021 Cohort</div>
                    </div>
                </div>
            ) : null}
            {isAddTable ? (
                <CreateTable
                    eventId={webinarId}
                    tableNo={tables.length + 1}
                    onCreate={(table) => setTables([...tables, table])}
                    handleClose={() => setIsAddTable(false)}
                />
            ) : null}
            {isOpenMeeting ? (
                <TableMeeting
                    eventId={webinarId}
                    roomId={isOpenMeeting}
                    onClose={() => {
                        setIsOpenMeeting(null);
                        api.delete(`/api/v1/event/${webinarId}/table/${isOpenMeeting}/participant/${meetingParticipantId}`).then(({data}) => {
                            api.get(`/api/v1/event/${webinarId}/table`).then(({data}) => {
                                setTables(data);
                            });
                        });
                    }}
                />
            ) : null}
        </Page>
    );
};

export default Tables;
