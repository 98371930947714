import React, {Fragment, useContext, useState} from 'react';
import Block from "../content/Block";
import Person from "../content/Person";
import {linkTypes} from '../../utils/constants';
import Avatar from "../content/Avatar";
import localization from "../../localization";
import api from "../../api";
import copyToClipboard from "../../utils/copyToClipboard";
import Page from "../page/Page";
import {store} from "../../store";
import SocialLinks from "./SocialLinks";



const Organizaton = ({organization, editOrganization}) => {
    const [organizationUserView, setOrganizationUserView] = useState(organization.loggedInUserOrganizationUserView);
    const [confirmationToast, setConfirmationToast] = useState(null);
    const {currentUser} = useContext(store);
    return (
        <div className='account-profile'>
            <div className='profile-main'>
                <Avatar
                    className='profile-picture'
                    image={organization.logo}
                    fullName={organization.name}
                />
                <div className='profile-name'>
                    {organization.name}
                    <button
                        className='profile-name--action'
                        onClick={() => {
                            copyToClipboard(organization.orgLastLink);
                            setConfirmationToast(localization.copiedToClipboard);
                            setTimeout(() => setConfirmationToast(null), 2000);
                        }}>
                        <img alt='filter' src='/images/share.svg' />
                    </button>
                </div>
                <div className='profile-location'>
                    <img alt='marker' src='/images/marker.svg'/>
                    <div>{organization.location}</div>
                </div>
                <div className='profile-actions'>
                    {organizationUserView.admin && editOrganization ? (
                        <button
                            className='edit-button'
                            onClick={editOrganization}
                        >
                            <img alt='mail' src='/images/edit.svg' />
                            <div>{localization.edit}</div>
                        </button>
                    ) : null}
                    {currentUser ? (
                        <button
                            className='submit-button'
                            onClick={() => {
                                api.post(
                                    `/api/v1/organization/${organization.id}/users`,
                                    {...organizationUserView, follower: !organizationUserView.follower, user: {id: currentUser.id}}
                                ).then(({data}) => {
                                    setOrganizationUserView(data);
                                })
                            }}
                        >
                            {organizationUserView.follower ? localization.accountOrganizationFollowing : localization.accountOrganizationFollow}
                        </button>
                    ) : null}
                </div>
            </div>
            <div className='separator'/>
            {organization.bio ? (
                <Fragment>
                    <Block
                        title={`${localization.about} ${organization.name}`}
                        isContentGray
                    >
                        <div>
                            {(organization.bio || '').split(/\r?\n/).map((item, index) => (<div key={index}>{item || (<br />)}</div>))}
                        </div>
                    </Block>
                    <div className='separator'/>
                </Fragment>
            ) : null}
            <Block
                title={localization.links}
            >
                <SocialLinks
                    socialLinks={organization.socialLinks}
                />
            </Block>
            {confirmationToast ? (
                <div className='toast--confirmation'>
                    {confirmationToast}
                </div>
            ) : null}
        </div>
    );
};

export default Organizaton;