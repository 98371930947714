import React from 'react';
import classNames from 'classnames';


const Block = ({count}) => {
    return count ? (
        <div className='badge'>
            <div>{count}</div>
        </div>
    ) : null;
};

export default Block;
