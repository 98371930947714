import React, {createRef, useContext, useEffect, useState} from 'react';
import {Link, withRouter} from "react-router-dom";
import {Helmet} from "react-helmet";
import api from "../../api";
import {store} from "../../store";
import localization from "../../localization";
import InputPair from "../../components/form/InputPair";
import toBase64 from "../../utils/toBase64";
import Input from "../../components/form/Input";



const PreOnboarding = ({history}) => {
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const {currentUser, setCurrentUser} = useContext(store);
    const uploadRef = createRef();
    useEffect(() => {
        if (currentUser.firstName && currentUser.lastName) {
            history.push('/onboarding')
        }
        setFormData({...currentUser});
    }, [currentUser]);
    return (
        <div className='page-landing pre-onboarding'>
            <Helmet>
                <title>Onboarding | {process.env.REACT_APP_PROJECT_NAME}</title>
                <meta name="description" content="Create an account for Last Link." />
            </Helmet>
            <div className='landing--content'>
                <div className='landing--logo'>
                    <img alt='logo' src={`/images/${process.env.REACT_APP_PROJECT}-logo-big.svg`} />
                </div>
                <div className='landing--title-smaller'>
                    {localization.preonboardingHi}
                </div>
                <div className='landing--subtitle'>
                    {localization.preonboardingLetsPut}
                </div>
                <div className='input-pair'>
                    <div className='input-pair--label'>{localization.preonboardingProfileImage}</div>
                    <div className='choose-image'>
                        {formData.image ? (
                            <div className='choose-image--logo'>
                                <img alt='img' src={formData.image} />
                            </div>
                        ) : null}
                        <div>
                            <button
                                onClick={() => uploadRef.current.click()}
                            >
                                {localization.preonboardingChooseImage}
                            </button>
                            <input
                                ref={uploadRef}
                                type='file'
                                accept='image/png,image/jpg,image/jpeg'
                                onChange={(event) =>{
                                    const file = event.target.files[0];
                                    if (file) {
                                        toBase64(file).then((base64) => {
                                            const data = {
                                                userId: currentUser.id,
                                                fileName: file.name,
                                                base64: base64.split('base64,')[1]
                                            };
                                            api.post(`/api/v1/files`, data).then(({data}) => {
                                                setFormData({...formData, image: data.presignedUrl})
                                            });
                                        })
                                    }
                                }}
                            />
                            <div style={{marginTop: 4}}>Recommended: 200px by 200px square image</div>
                        </div>
                    </div>
                </div>
                <InputPair
                    label={localization.preonboardingFirstName}
                    value={formData.firstName}
                    onChange={(value) => setFormData({...formData, firstName: value})}
                    error={formData.firstName && errors.firstName}
                />
                <InputPair
                    label={localization.preonboardingLastName}
                    value={formData.lastName}
                    onChange={(value) => setFormData({...formData, lastName: value})}
                    error={formData.lastName && errors.lastName}
                />
                <button
                    className='submit-button'
                    onClick={() => {
                        const errors = {};
                        if (!formData.firstName || !/^\p{L}+$/u.test(formData.firstName) || formData.firstName.length > 60) {
                            errors.firstName = localization.notValid;
                        }
                        if (!formData.lastName || !/^\p{L}+$/u.test(formData.lastName) || formData.lastName.length > 60) {
                            errors.lastName = localization.notValid;
                        }
                        setErrors(errors);
                        if (!Object.keys(errors).length) {
                            api.put(`/api/v1/users`, {
                                ...currentUser,
                                ...formData,
                                firstName: formData.firstName.trim(),
                                lastName: formData.lastName.trim(),
                            }).then(({data}) => {
                                setCurrentUser(data);
                            });
                        }
                    }}
                    disabled={!formData.firstName || !formData.lastName}
                >
                    Continue
                </button>
            </div>
        </div>
    );
};

export default withRouter(PreOnboarding);