import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';
import momentTimezone from "moment-timezone";
import Block from "../../../components/content/Block";
import Person from "../../../components/content/Person";
import copyToClipboard from '../../../utils/copyToClipboard';
import localization from "../../../localization";
import {store} from "../../../store";
import Avatar from "../../../components/content/Avatar";
import api from "../../../api";
import {addHttpsToLink, createLink} from "../../../utils/linkUtil";
import timezones from "timezones.json";
import Call from "./Call";



const dayOptions = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

const About = ({data, setConfirmationToast, selectPerson, setSelectedOrganizationId, eventParticipants}) => {
    const {currentUser, eventNotifications, setEventNotifications} = useContext(store);
    const [callInformation, setCallInformation] = useState(false);
    const [openedCall, setOpenedCall] = useState(false);
    useEffect(() => {
        const notifications = eventNotifications.filter(({objectId, notificationsType}) => objectId === data.id && notificationsType === 'EVENT_ABOUT');
        if (notifications.length) {
            api.put(`/api/v1/notifications/read`, {notificationsType: 'EVENT_ABOUT', objectId: data.id}).then(({data}) => {
                api.get(`/api/v1/notifications/unread`).then(({data}) => {
                    setEventNotifications(data);
                });
            });
        }
    }, []);
    let startTime = data.meetingStartTime ? momentTimezone(data.meetingStartTime).tz(currentUser.timeZoneId).toDate().getTime() : null;
    let endTime = data.meetingStartTime && data.meetingDuration ? (startTime + (data.meetingDuration * 60000)) : null;
    if (data.recurring && startTime && new Date().getTime() > startTime && (!endTime || new Date().getTime() > endTime) && new Date().getTime() < moment(data.recurringEvent.endsOn).toDate().getTime()) {
        let addWeeks = false;
        let dayIndex = 0;
        const weekOccurrence = moment(startTime).week() - moment(startTime).startOf('month').week();
        const dayOccurrence = moment(startTime).day();
        while (startTime < new Date().getTime() && new Date().getTime() > endTime) {
            if (data.recurringEvent.recurringType === 'DAILY') {
                startTime = moment(startTime).add(data.recurringEvent.numberOfOccurrence, 'd');
            } else if (data.recurringEvent.recurringType === 'WEEKLY') {
                if (addWeeks) {
                    startTime = moment(startTime).add(data.recurringEvent.numberOfOccurrence, 'w');
                    addWeeks = false;
                }
                const days = data.recurringEvent.repeatOn.split(',');
                const day = dayOptions.indexOf(days[dayIndex]);
                startTime = moment(startTime).day(day);
                if ((dayIndex + 1) === days.length) {
                    dayIndex = 0;
                    addWeeks = true;
                } else {
                    dayIndex = dayIndex + 1;
                }
            } else if (data.recurringEvent.recurringType === 'MONTHLY') {
                if (data.recurringEvent.repeatOn) {
                    startTime = moment(startTime).add(data.recurringEvent.numberOfOccurrence, 'M').startOf('month').add(weekOccurrence, 'w').day(dayOccurrence);
                } else {
                    startTime = moment(startTime).add(data.recurringEvent.numberOfOccurrence, 'M');
                }
            } else if (data.recurringEvent.recurringType === 'YEARLY') {
                startTime = moment(startTime).add(data.recurringEvent.numberOfOccurrence, 'y');
            }
            if (endTime) {
                endTime = startTime + (data.meetingDuration * 60000);
            }
        }
    }
    return (
        <div className='about'>
            <div className='about--content'>
                <div className='about--content--left'>
                    <div className='about--image'>
                        <img alt='webinar-background' src={data.coverImage} />
                    </div>
                    {data.description ? (
                        <Block
                            title={localization.webinarAboutAboutWebinar}
                            isNoPadding
                        >
                            {(data.description || '').split(/\r?\n/).map((item, index) => (<div key={index}>{item ? (<div dangerouslySetInnerHTML={{__html: addHttpsToLink(createLink(item))}}/>) : (<br />)}</div>))}
                        </Block>
                    ) : null}
                    {(data.eventSponsorsOrganizations || []).length ? (
                        <Block
                            title={localization.webinarAboutPresented}
                            isNoPadding
                        >
                            <div className='about--content--presented'>
                                {(data.eventSponsorsOrganizations || []).map((item) => (
                                    <div onClick={() => setSelectedOrganizationId(item.organization.id)}>
                                        <Avatar
                                            image={item.organization.logo}
                                            fullName={item.organization.name}
                                        />
                                        <div>{item.organization.name}</div>
                                    </div>
                                ))}
                            </div>
                        </Block>
                    ) : null}
                    {data.tags ? (
                        <Block
                            title={localization.webinarAboutTags}
                            isNoPadding
                        >
                            <div className='about--content--tags'>
                                {data.tags.split(',').map((item) => (
                                    <div>{item}</div>
                                ))}
                            </div>
                        </Block>
                    ) : null}
                </div>
                <div className='about--content--right'>
                    {startTime || data.meetingUrl ? (
                        <div className='about--content--call'>
                            {startTime && endTime && (new Date().getTime() > startTime && new Date().getTime() < endTime) ? (
                                <div className='about--content--call--live'>
                                    <div/> <div>{localization.webinarAboutLive}</div>
                                </div>
                            ) : null}
                            <Block
                                title={localization.webinarAboutCallDetails}
                            >
                                {startTime ? (
                                    <div className='about--content--call--start'>
                                        {moment(startTime).format('DD MMMM YYYY')}
                                    </div>
                                ) : null}
                                {startTime ? (
                                    <div className='about--content--call--duration'>
                                        {moment(startTime).format('hh:mm A')}
                                        {endTime ? (
                                            <span> - {moment(endTime).format('hh:mm A')}</span>
                                        ) : null}
                                        <span> {(timezones.filter(({utc}) => utc.indexOf(currentUser.timeZoneId) >= 0)[0] || {}).value}</span>
                                    </div>
                                ) : null}
                                {data.recurring ? (
                                    <div className='about--content--call--recurring'>
                                        {data.recurringEvent.recurringType === 'DAILY' ? (
                                            <div>Repeats {data.recurringEvent.numberOfOccurrence > 1 ? `every ${data.recurringEvent.numberOfOccurrence} days` : 'everyday'} until {moment(data.recurringEvent.endsOn).format('DD/MM/YYYY')}</div>
                                        ) : null}
                                        {data.recurringEvent.recurringType === 'WEEKLY' ? (
                                            <div>Repeats weekly on {data.recurringEvent.repeatOn.split(',').map((item, index) => (
                                                <span>{index ? (<span>, </span>) : null}<span className='capitalize'>{item.toLowerCase()}</span></span>
                                            ))} until {moment(data.recurringEvent.endsOn).format('DD/MM/YYYY')}</div>
                                        ) : null}
                                        {data.recurringEvent.recurringType === 'MONTHLY' || data.recurringEvent.recurringType ===  'YEARLY' ? (
                                            <div>Repeats on {moment(startTime).format('DD MMMM')} until {moment(data.recurringEvent.endsOn).format('DD/MM/YYYY')}</div>
                                        ) : null}
                                    </div>
                                ) : null}
                                <div className='flex'>
                                    {data.meetingUrl ? (
                                        /*<a
                                            className='about--content--call--join'
                                            href={(data.meetingUrl.indexOf('http://') < 0 && data.meetingUrl.indexOf('https://') < 0) ? `https://${data.meetingUrl}` : data.meetingUrl}
                                            target='_blank'
                                        >
                                            {localization.webinarAboutJoin}
                                        </a>*/
                                        <a
                                            className='about--content--call--join'
                                            onClick={() => setOpenedCall(true)}
                                        >
                                            {localization.webinarAboutJoin}
                                        </a>
                                    ) : null}
                                    {/*<div title="Add to Calendar" className="addeventatc">
                                        Add to Calendar
                                        {startTime ? (<span className="start">{moment(startTime).format('MM/DD/YYYY hh:mm A')}</span>) : null}
                                        {endTime ? (<span className="end">{moment(endTime).format('MM/DD/YYYY hh:mm A')}</span>) : null}
                                        <span className="timezone">{currentUser.timeZoneId}</span>
                                        <span className="title">{data.name}</span>
                                        <span className="description">{data.description}</span>
                                    </div>*/}
                                    <a className='go-to-bubble' href='https://meetonbubble.com/?s=CS2021' target='_blank'>Go to Bubble</a>
                                </div>
                                {data.callDetails ? (
                                    <div
                                        className='about--content--call--more'
                                        onClick={() => setCallInformation(!callInformation)}
                                    >
                                        <div>{localization.webinarAboutMore}</div>
                                        {callInformation ? (
                                            <div>
                                                <br/>
                                                <div>{data.callDetails}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                ) : null}
                            </Block>
                        </div>
                    ) : null}
                    {startTime || data.meetingUrl ? (<br />) : null}
                    {/*<Block
                        title={localization.webinarAboutSpreadWord}
                    >
                        <div className='about--copy-link'>
                            <div>{data.lastLink.split('//')[1]}</div>
                            <button onClick={() => {
                                copyToClipboard(data.lastLink);
                                setConfirmationToast(localization.copiedToClipboard);
                                setTimeout(() => setConfirmationToast(null), 2000);
                            }}>
                                {localization.webinarAboutCopyLink}
                            </button>
                        </div>
                    </Block>*/}
                    <br />
                    <Block
                        title={localization.webinarAboutHostedBy}
                    >
                        {data.creatorOrganization ? (
                            <Person
                                image={data.creatorOrganization.logo}
                                title={data.creatorOrganization.name}
                                selectPerson={() => setSelectedOrganizationId(data.creatorOrganization.id)}
                            />
                        ) : (
                            <Person
                                image={data.creator.image}
                                title={`${data.creator.firstName} ${data.creator.lastName}`}
                                description={data.creator.organization ? `${data.creator.position ? `${data.creator.position} ${localization.webinarAboutAt} ` : ''}${data.creator.organization}` : null}
                                selectPerson={() => selectPerson(data.creator.id)}
                            />
                        )}
                    </Block>
                </div>
            </div>
            {openedCall ? (
                <Call
                    data={data}
                    onClose={() => setOpenedCall(false)}
                    setSelectedOrganizationId={setSelectedOrganizationId}
                    eventParticipants={eventParticipants}
                />
            ) : null}
        </div>
    );
};

export default About;
