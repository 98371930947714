import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {withRouter} from "react-router-dom";
import InputPair from "../../components/form/InputPair";
import api from "../../api";
import ExpandableBlock from "./ExpandableBlock";
import ImageUpload from "../../components/form/ImageUpload";
import Input from "../../components/form/Input";
import localization from "../../localization";
import Block from "../../components/content/Block";
import Select from "react-select";
import DatePicker from "../../components/form/DatePicker";
import RichTextInput from "../../components/form/RichTextInput";
import TextArea from "../../components/form/TextArea";

const organizationOptions = [{
    label: 'Yes',
    value: true
}, {
    label: 'No',
    value: false
}];

const CreativeSolutionsCompleted = ({history, setLogOut, formData, setFormData, isApplicant}) => {
    return (
        <div>
            <div className='creative-solutions--header'>
            </div>
            <div className='creative-solutions--separator'/>
            <div className='creative-solutions--completed'>
                {isApplicant ? (
                    <div className='creative-solutions--completed--title'>
                        Your application has been submitted! You can continue to edit and re-submit your application until May 8th, 2021. Click the button below to start editing.
                    </div>
                ) : (
                    <div className='creative-solutions--completed--title'>
                        Thank you for agreeing to be a mentor! We are really excited for the program. We will be in touch with you shortly. Click the button below to edit your registration.
                    </div>
                )}
                <button onClick={() => {
                    if (isApplicant) {
                        const payload = {
                            ...formData,
                            submissionStatus: 'DRAFT',
                        }
                        api.post(`/api/v1/application-form`, payload).then(({data}) => {
                            if (data.submissionStatus === 'DRAFT') {
                                setFormData(data);
                                history.push(`/apply/applicant${window.location.search}`);
                            }
                        });
                    } else {
                        history.push(`/apply/mentor${window.location.search}`);
                    }
                }}>
                    Edit My Application
                </button>
                <div className='creative-solutions--completed--note'>
                    <strong>NOTE</strong>: Submit the application again to complete your application process.
                </div>
                <img alt='logo' src={`/images/timeline.png`} />
            </div>
        </div>
    );
};

export default withRouter(CreativeSolutionsCompleted);