import React, {useContext, useEffect, useState} from 'react';
import PageContent from "../../components/page/PageContent";
import localization from "../../localization";
import Messaging from "../../components/messaging/Messaging";
import api from "../../api";
import ModalCentral from "../../components/modal/ModalCentral";
import TextArea from "../../components/form/TextArea";
import InputPair from "../../components/form/InputPair";
import ImageUpload from "../../components/form/ImageUpload";
import Block from "../../components/content/Block";
import Input from "../../components/form/Input";
import LogoUpload from "../../components/form/LogoUpload";
import classNames from "classnames";
import Page from "../../components/page/Page";
import {store} from "../../store";


const PostAnswers = ({eventId, roomId, onClose}) => {
    const {currentUser} = useContext(store);
    return (
        <div className='table-meeting'>
            <button className='table-meeting--close' onClick={onClose}>
                ×
            </button>
            <zipcan-video token="xwpLKiTKeAXq" id={`${eventId}I${roomId}`} name={`${currentUser.firstName} ${currentUser.lastName}`}></zipcan-video>
        </div>
    );
};

export default PostAnswers;
