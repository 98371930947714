import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router";
import api from "../../api";
import queryString from "query-string";
import {store} from "../../store";
import PageContent from "../../components/page/PageContent";
import {Helmet} from "react-helmet";
import Profile from "../../components/profile/Profile";
import Organizaton from "../../components/organization/Organizaton";



const OrganizationPage = ({location, history}) => {
    const [suffix, setSuffix] = useState(null);
    const [organization, setOrganization] = useState(null);
    const {currentUser} = useContext(store);
    useEffect(() => {
        const id = localStorage.getItem('organizationSuffix') || location.pathname.split('/o/')[1];
        setSuffix(id);
        const code = queryString.parse(location.search).code || localStorage.getItem('organizationCode');
        if (code) {
            if (currentUser) {
                api.get(`/api/v1/organization/join-as-member-by-code?code=${code}`).then(({data}) => {
                    localStorage.removeItem('organizationCode');
                    history.push('/account');
                });
            } else {
                localStorage.setItem('organizationCode', code);
                localStorage.setItem('organizationSuffix', id);
                history.push('/');
            }
        } else {
            api.get(`/api/v1/organization/lastlink/${id}`).then(({data}) => {
                setOrganization(data);
            });
        }
    }, []);
    return organization ? (
        <PageContent
            title={organization.name}
            className='profile-details'
            backAction={() => {
                localStorage.removeItem('organizationSuffix');
                history.push('/w');
            }}
        >
            <Helmet>
                <title>{organization.name} | {process.env.REACT_APP_PROJECT_NAME}</title>
                <meta name="description" content={organization.description} />
            </Helmet>
            <Organizaton
                organization={organization}
            />
        </PageContent>
    ) : null;
};

export default withRouter(OrganizationPage);
