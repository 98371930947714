import React, {useState, useContext, useEffect} from 'react';
import {Route, withRouter} from "react-router-dom";
import Page from '../../components/page/Page';
import Navigation from '../../components/navigation/Navigation';
import NavigationGroup from '../../components/navigation/NavigationGroup';
import NavigationItem from '../../components/navigation/NavigationItem';
import Person from "../../components/content/Person";
import Profile from "../../components/profile/Profile";
import EditProfile from "./components/EditProfile";
import {store} from "../../store";
import PageContent from "../../components/page/PageContent";
import {Helmet} from "react-helmet";
import localization from "../../localization";
import ManageOrganization from "./components/ManageOrganization";
import api from "../../api";
import OrganizationDetails from "./components/OrganizationDetails";
import ModalConfirmation from "../../components/modal/ModalConfirmation";



const Account = ({history, location}) => {
    const [organizations, setOrganizations] = useState([]);
    const [confirmationToast, setConfirmationToast] = useState(null);
    const [logOut, setLogOut] = useState(false);
    const {currentUser, setCurrentUser} = useContext(store);
    useEffect(() => {
        api.get(`/api/v1/organization`).then(({data}) => {
            setOrganizations(data);
        });
    }, []);
    return (
      <Page
          className='page-account'
      >
          <Helmet>
              <title>{currentUser.firstName || ''} {currentUser.lastName || ''} | {process.env.REACT_APP_PROJECT_NAME}</title>
              <meta name="description" content='Everything about your account on Last Link' />
          </Helmet>
          <Navigation
              title={(
                  <div className='flex-between'>
                      <div>{localization.accountTitle}</div>
                      <button onClick={() => setLogOut(true)}>
                          <img alt='logout' src='/images/logout.png' />
                      </button>
                  </div>
              )}
          >
              <NavigationGroup>
                  <NavigationItem
                      content={(
                          <Person
                              image={currentUser.image}
                              title={`${currentUser.firstName} ${currentUser.lastName}`}
                              description={currentUser.email}
                              isBlackDescription
                          />
                      )}
                      onClick={() => {
                          history.push('/account/profile');
                      }}
                      isActive={location.pathname.split('/')[2] === 'profile'}
                      isWithoutBorder
                  />
                  <NavigationItem
                      image={(<img alt='edit' src='/images/edit.svg' />)}
                      content={localization.accountEdit}
                      onClick={() => {
                          history.push('/account/edit');
                      }}
                      isActive={location.pathname.split('/')[2] === 'edit'}
                      isWithoutBorder
                      isSmaller
                  />
              </NavigationGroup>
              {/*<NavigationGroup
                  title='Settings'
              >
                  <NavigationItem
                      image={(<img alt='invite' src='/images/invite.svg' />)}
                      content='Signup Information'
                      isWithoutBorder
                      isSmaller
                  />
                  <NavigationItem
                      image={(<img alt='notification' src='/images/notification.svg' />)}
                      content='Notifications'
                      isWithoutBorder
                      isSmaller
                  />
                  <NavigationItem
                      image={(<img alt='email' src='/images/email.svg' />)}
                      content='Email Preferences'
                      isWithoutBorder
                      isSmaller
                  />
              </NavigationGroup>*/}
          </Navigation>
          <Route
              path='/account/profile'
              render={() => (
                  <PageContent
                      title={`${currentUser.firstName}${localization.profile}`}
                      backAction={() => history.push('/account')}
                      noBackOnDesktop
                      isWhiteBody
                  >
                      <Profile
                          user={currentUser}
                      />
                  </PageContent>
              )}
          />
          <Route
              path='/account/edit'
              render={() => (
                  <PageContent
                      title={localization.accountEdit}
                      backAction={() => history.push('/account')}
                      isWhiteBody
                  >
                      <EditProfile
                          backAction={() => history.push('/account')}
                      />
                  </PageContent>
              )}
          />
          <Route
              path='/account/manage-organization'
              exact
              render={() => (
                  <PageContent
                      title={localization.accountOrganizationsCreate}
                      backAction={() => history.push('/account')}
                  >
                      <ManageOrganization
                          backAction={() => history.push('/account')}
                          onSave={(data) => {
                              setOrganizations(
                                  [...organizations, data]
                              );
                              history.push(`/account/organization/${data.orgLastLinkSuffix}`);
                              setConfirmationToast(localization.accountOrganizationCreated);
                              setTimeout(() => setConfirmationToast(null), 2500);
                          }}
                      />
                  </PageContent>
              )}
          />
          <Route
              path='/account/manage-organization/:orgLastLinkSuffix'
              render={() => {
                  const organization = organizations.filter(({orgLastLinkSuffix}) => orgLastLinkSuffix.toLowerCase() === location.pathname.split('/')[3].toLowerCase())[0];
                  return organization ? (
                      <PageContent
                          title={localization.accountOrganizationsEdit}
                          backAction={() => history.push('/account')}
                      >
                          <ManageOrganization
                              organization={organization}
                              backAction={() => history.push('/account')}
                              onSave={(data) => setOrganizations(
                                  organizations.map((item) => (item.id === organization.id ? data : item))
                              )}
                          />
                      </PageContent>
                  ) : null
              }}
          />
          <Route
              path='/account/organization/:orgLastLinkSuffix'
              render={() => {
                  const organization = organizations.filter(({orgLastLinkSuffix}) => orgLastLinkSuffix.toLowerCase() === location.pathname.split('/')[3].toLowerCase())[0];
                  return organization ? (
                      <OrganizationDetails
                          organization={organization}
                          backAction={() => history.push('/account')}
                          editOrganization={() => {
                              history.push(`/account/manage-organization/${organization.orgLastLinkSuffix}`);
                          }}
                      />
                  ) : null
              }}
          />
          {confirmationToast ? (
              <div className='toast--confirmation'>
                  {confirmationToast}
              </div>
          ) : null}
          {logOut ? (
              <ModalConfirmation
                  title={localization.accountLogoutTile}
                  description={localization.accountLogoutDescription}
                  confirm={localization.accountLogoutYes}
                  cancel={localization.accountLogoutNo}
                  onCancel={() => setLogOut(false)}
                  onConfirm={() => {
                      localStorage.clear();
                      setCurrentUser(null);
                      history.push('/');
                  }}
              />
          ) : null}
      </Page>
    );
};

export default withRouter(Account);