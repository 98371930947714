import React, {useState} from 'react';
import {LinkedIn} from 'react-linkedin-login-oauth2';
import {Helmet} from "react-helmet";
import {Link, withRouter} from "react-router-dom";
import localization from "../../localization";
import InputPair from "../../components/form/InputPair";
import api from "../../api";



const NoMessages = ({history}) => {
    return (
        <div className='notfound-page'>
            <Helmet>
                <title>Chat | {process.env.REACT_APP_PROJECT_NAME}</title>
                <meta name="description" content="Log in to Last Link." />
            </Helmet>
            <div className='notfound-page--content'>
                <img alt='logout' src='/images/direct-messages.png' />
                <div className='notfound-page--title'>
                    Direct messages
                </div>
                <div className='notfound-page--subtitle'>
                    Start chatting with people from the <Link to='/contacts'>People section</Link> and from <Link to='/w'>Rooms</Link>
                </div>
            </div>
        </div>
    );
};

export default withRouter(NoMessages);