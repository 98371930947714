import React, {useContext, useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Route, withRouter} from "react-router-dom";
import api from "../../api";
import CreativeSolutionsGeneral from "./CreativeSolutionsGeneral";
import CreativeSolutionsApplicant from "./CreativeSolutionsApplicant";
import CreativeSolutionsMentor from "./CreativeSolutionsMentor";
import CreativeSolutionsCompleted from "./CreativeSolutionsCompleted";
import ModalConfirmation from "../../components/modal/ModalConfirmation";
import localization from "../../localization";
import {store} from "../../store";
import Page from "../../components/page/Page";
import momentTimezone from "moment-timezone";
import PageContent from "../../components/page/PageContent";



const CreativeSolutions = ({history}) => {
    const [logOut, setLogOut] = useState(false);
    const [confirmationToast, setConfirmationToast] = useState(null);
    const [applicantData, setApplicantData] = useState({});
    const [mentorData, setMentorData] = useState({});
    const {setCurrentUser} = useContext(store);
    useEffect(() => {
        api.get(`/api/v1/application-form`).then(({data}) => {
            setApplicantData(data);
            if (data.submissionStatus === 'SUBMITTED') {
                history.push(`/apply/completed${window.location.search}`);
            }
        });
        api.get(`/api/v1/mentor-registration`).then(({data}) => {
            setMentorData(data);
            if (data) {
                history.push(`/apply/completed${window.location.search}`);
            }
        });
    }, []);

    return (
        <Page
            className='creative-solutions'
        >
            <Helmet>
                <title>Application | {process.env.REACT_APP_PROJECT_NAME}</title>
                <meta name="description" content="Application | Creative Solutions" />
            </Helmet>
            <PageContent
                title={`Application - Creative Solutions Program 2021`}
                className='creative-solutions--content'
                noBackOnDesktop
            >
                <Route
                    exact
                    path={`/apply`}
                    render={() => (
                        <CreativeSolutionsGeneral setLogOut={setLogOut}/>
                    )}
                />
                <Route
                    exact
                    path={`/apply/applicant`}
                    render={() => (
                        <CreativeSolutionsApplicant
                            initialData={applicantData}
                            setInitialData={setApplicantData}
                            setConfirmationToast={setConfirmationToast}
                            setLogOut={setLogOut}
                        />
                    )}
                />
                <Route
                    exact
                    path={`/apply/mentor`}
                    render={() => (
                        <CreativeSolutionsMentor
                            initialData={mentorData}
                            setInitialData={setMentorData}
                            setConfirmationToast={setConfirmationToast}
                            setLogOut={setLogOut}
                        />
                    )}
                />
                <Route
                    exact
                    path={`/apply/completed`}
                    render={() => mentorData.id ? (
                        <CreativeSolutionsCompleted
                            setLogOut={setLogOut}
                            formData={mentorData}
                            setFormData={setMentorData}
                        />
                    ) : (
                        <CreativeSolutionsCompleted
                            setLogOut={setLogOut}
                            formData={applicantData}
                            setFormData={setApplicantData}
                            isApplicant
                        />
                    )}
                />
                {confirmationToast ? (
                    <div className='toast--confirmation'>
                        {confirmationToast}
                    </div>
                ) : null}
                {logOut ? (
                    <ModalConfirmation
                        title={localization.accountLogoutTile}
                        description={localization.accountLogoutDescription}
                        confirm={localization.accountLogoutYes}
                        cancel={localization.accountLogoutNo}
                        onCancel={() => setLogOut(false)}
                        onConfirm={() => {
                            localStorage.clear();
                            setCurrentUser(null);
                            history.push(`/${window.location.search}`);
                        }}
                    />
                ) : null}
            </PageContent>
        </Page>
    );
};

export default withRouter(CreativeSolutions);