import React from 'react';
import classNames from 'classnames';


const TextArea = ({value, onChange, placeholder, rows, error}) => {
    return (
        <div className={classNames('form-input', {'form-input--error': error})}>
            <textarea
                onChange={(event) => onChange(event.target.value)}
                value={value}
                rows={rows}
            />
            {placeholder && !value ? (
                <div className='form-input--placeholder'>{placeholder}</div>
            ) : null}
            {error ? (
                <div className='form-input--message'>{error}</div>
            ) : null}
        </div>
    );
};

export default TextArea;
