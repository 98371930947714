import React from 'react';
import Input from "./Input";


const SearchInput = ({value, onChange, placeholder}) => {
    return (
        <div className='search-input'>
            <Input
                value={value}
                onChange={onChange}
                placeholder={placeholder}
            />
            <img alt='search-gray' src='/images/search-gray.svg' />
        </div>
    );
};

export default SearchInput;
