import React, {useState} from 'react';
import classNames from "classnames";

const NavigationGroup = ({title, titleActon, children, collapsable, opened, setOpened}) => {
    return (
        <div className='navigation-group'>
            {title ? (
                <div
                    className='navigation-group--title'
                    onClick={setOpened}
                >
                    {collapsable ? (
                        <div className={classNames('navigation-item--title--collapse', {'create-webinar--advanced--collapse--closed': !opened})}>
                            <img alt='arrow' src='/images/triangle-arrow.svg'/>
                        </div>
                    ) : null}
                    {title}
                    <div className='navigation-item--title--action'>
                        {titleActon}
                    </div>
                </div>
            ) : null}
            {children && (!collapsable || opened) ? (
                <div className='navigation-group--content'>
                    {children}
                </div>
            ) : null}
        </div>
    );
};

export default NavigationGroup;