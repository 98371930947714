import React from 'react';
import {withRouter} from "react-router-dom";
import {Helmet} from "react-helmet";
import localization from "../../localization";



const EmailExpired = ({history}) => {
    return (
        <div className='page-landing'>
            <Helmet>
                <title>Link expired | {process.env.REACT_APP_PROJECT_NAME}</title>
                <meta name="description" content="Link expired." />
            </Helmet>
            <div className='landing--content'>
                <div className='landing--logo'>
                    <img alt='logo' src={`/images/${process.env.REACT_APP_PROJECT}-logo-big.svg`} />
                </div>
                <div className='landing--title-smaller'>
                    {localization.landingLinkExpiredTitle}
                </div>
                <div className='landing--subtitle'>
                    {localization.landingLinkExpiredDescription}
                </div>
                <button
                    className='submit-button'
                    onClick={() => history.push('/login')}
                >
                    {localization.landingCheckOk}
                </button>
            </div>
        </div>
    );
};

export default withRouter(EmailExpired);