import React, {useContext, useEffect, useState} from 'react';
import Person from "../../../components/content/Person";
import {store} from "../../../store";
import api from "../../../api";
import localization from "../../../localization";
import Dropdown from "../../../components/content/Dropdown";
import Block from "../../../components/content/Block";
import copyToClipboard from "../../../utils/copyToClipboard";
import Page from "../../../components/page/Page";


const InviteMembers = ({organization}) => {
    const [confirmationToast, setConfirmationToast] = useState(null);
    const [link, setLink] = useState(null);
    useEffect(() => {
        api.get(`/api/v1/organization/${organization.id}/generate-share-link`).then(({data}) => {
            setLink(data.shareLink);
        });
    }, []);
    return (
        <div className='invite-members'>
            <div className='invite-members--title'>
                {localization.accountOrganizationInviteTitle}
            </div>
            <div className='lock-block'>
                <img alt='lock' src='/images/lock.svg' />
                <div>
                    {localization.accountOrganizationInviteDescription}
                </div>
            </div>
            <div className='invite-members--copy'>
                <div>{link}</div>
                <button
                    className='submit-button'
                    onClick={() => {
                        copyToClipboard(link);
                        setConfirmationToast(localization.copiedToClipboard);
                        setTimeout(() => setConfirmationToast(null), 2000);
                    }}
                >
                    {localization.copy}
                </button>
            </div>
            {confirmationToast ? (
                <div className='toast--confirmation'>
                    {confirmationToast}
                </div>
            ) : null}
        </div>
    );
};

export default InviteMembers;
