import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router";
import moment from "moment";
import Page from "../../components/page/Page";
import Navigation from "../../components/navigation/Navigation";
import NavigationItem from "../../components/navigation/NavigationItem";
import Person from "../../components/content/Person";
import ProfileDetails from "../../components/profile/ProfileDetails";
import {store} from "../../store";
import api from "../../api";
import {sortArray} from '../../utils/sortingService';
import {Helmet} from "react-helmet";
import localization from "../../localization";
import PageContent from "../../components/page/PageContent";
import NoMessages from "./NoMessages";


const Messages = ({}) => {
    const [messagingDetails, setMessagingDetails] = useState(null);
    const [allChats, setAllChats] = useState([]);
    const [chats, setChats] = useState([]);
    const [chatCount, setChatCount] = useState(null);
    const {currentUser, twilioClient, oneToOneUnread} = useContext(store);
    useEffect(() => {
        if (twilioClient) {
            twilioClient.getSubscribedChannels().then((paginator) => {
                const allChats = [];
                allChats.push(...paginator.items);
                const loadChanel = (paginator) => {
                    if (paginator.hasNextPage) {
                        paginator.nextPage().then((paginator) => {
                            allChats.push(...paginator.items);
                            loadChanel(paginator);
                        });
                    } else {
                        setAllChats([...allChats]);
                    }
                };
                loadChanel(paginator);
            });
        }
    }, [twilioClient]);
    useEffect(() => {
        if (allChats.length) {
            api.get(`/api/v1/chat/details/${currentUser.id}`).then(({data}) => {
                const chats = [];
                setChatCount(data.filter(({twilioChannelSid}) => allChats.filter(({sid}) => sid === twilioChannelSid)[0]).length);
                data.forEach((item) => {
                    const chat = allChats.filter(({sid}) => sid === item.twilioChannelSid)[0];
                    if (chat) {
                        chat.getMessages().then((messages) => {
                            if (messages.items.length) {
                                const message = messages.items[messages.items.length - 1];
                                const userId = currentUser.id !== item.senderUserId ? item.senderUserId : item.receiverUserId;
                                api.get(`/api/v1/users/${userId}`).then(({data}) => {
                                    chats.push({
                                        chatId: chat.sid,
                                        body: message.state.body,
                                        timestamp: message.state.timestamp,
                                        senderSid: item.createdBy === currentUser.email ? item.senderTwilioMemberSid : item.receiverTwilioMemberSid,
                                        user: data,
                                        read: chat.lastConsumedMessageIndex === messages.items.length
                                    });
                                    sortArray(chats, 'desc', 'timestamp');
                                    setChats([...chats]);
                                });
                            }
                        });
                        chat.on('messageAdded', (message) => {
                            chats.forEach((item, index) => {
                               if (item.chatId === chat.sid) {
                                   chats[index] = {
                                       ...item,
                                       body: message.state.body,
                                       timestamp: message.state.timestamp,
                                       read: !oneToOneUnread[chat.sid],
                                   }
                               }
                            });
                            sortArray(chats, 'desc', 'timestamp');
                            setChats(chats);
                        });
                    }
                })
            });
        }
    }, [allChats]);
    useEffect(() => {
        if (chatCount && chats.length === chatCount) {
            sortArray(chats, 'desc', 'timestamp');
            setChats(chats.map((chat) => ({
                ...chat,
                read: !oneToOneUnread[chat.chatId]
            })));
        }
    }, [chatCount, oneToOneUnread]);
    return (
        <Page
            className='page-messages'
        >
            <Helmet>
                <title>Messages | {process.env.REACT_APP_PROJECT_NAME}</title>
                <meta name="description" content="Message your contact, video chat with them and stay connected." />
            </Helmet>
            <Navigation
                title={(
                    <div className='flex-between'>
                        <div>{localization.messagesTitle}</div>
                        {/*<div>
                            <button
                                className='button--search'
                                onClick={() => {
                            }}>
                                <img alt='search' src='/images/search.svg' />
                            </button>
                            <button
                                className='button--filter'
                                onClick={() => {
                            }}>
                                <img alt='filter' src='/images/filter.svg' />
                            </button>
                        </div>*/}
                    </div>
                )}
            >
                {chats.map((item, index) => (
                    <NavigationItem
                        key={index}
                        content={(
                            <Person
                                image={item.user.image}
                                title={`${item.user.firstName || ''} ${item.user.lastName || ''}`}
                                description={(<div dangerouslySetInnerHTML={{__html: (item.body || '').replace(/style="[^"]*"/g, '')}}/>)}
                            />
                        )}
                        action={(
                            <div>
                                <div>{new Date().getTime() - item.timestamp < (1000 * 60 * 10) ? 'Now' : moment(item.timestamp).format('hh:mm A')}</div>
                                {oneToOneUnread[item.chatId] ? (
                                    <div/>
                                ) : null}
                            </div>
                        )}
                        onClick={() => {
                            setMessagingDetails(null);
                            setTimeout(() => setMessagingDetails(item), 0);
                        }}
                        isActive={messagingDetails && messagingDetails.user.id === item.user.id}
                    />
                ))}
            </Navigation>
            {messagingDetails ? (
                <ProfileDetails
                    messagingDetails={messagingDetails}
                    defaultTab={1}
                    backAction={() => setMessagingDetails(null)}
                    noBackOnDesktop
                />
            ) : (
                <NoMessages/>
            )}
        </Page>
    );
};

export default withRouter(Messages);
