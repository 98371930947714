import React, {useContext, useEffect, useState} from 'react';
import {withRouter, Switch, Route, Redirect} from 'react-router-dom';
import api from './api';
import Loader from './components/loader/Loader';
import Root from './pages/landing/Root';
import Account from './pages/account/Account';
import UserProfile from './pages/profile/UserProfile';
import LinkedinSignUp from './pages/linkedin/LinkedinSignUp';
import LinkedinLogIn from './pages/linkedin/LinkedinLogIn';
import ZoomLogIn from './pages/zoom/ZoomLogIn';
import ZoomSignUp from './pages/zoom/ZoomSignUp';
import EmailExpired from './pages/landing/EmailExpired';
import LogIn from './pages/landing/LogIn';
import SignIn from './pages/landing/SignIn';
import Webinars from './pages/webinars/Webinars';
import Contacts from "./pages/contacts/Contacts";
import Messages from "./pages/messages/Messages";
import Join from './pages/join/Join';
import Onboarding from "./pages/onboarding/Onboarding";
import PreOnboarding from "./pages/onboarding/PreOnboarding";
import CheckInbox from "./pages/landing/CheckInbox";
import EmailSignIn from "./pages/landing/EmailSignIn";
import OrganizationPage from "./pages/organization/OrganizationPage";
import Reception from "./pages/reception/Reception";
import Notifications from "./components/notifications/Notifications";
import {store} from './store';
import localization from './localization';
import CreativeSolutions from "./pages/apply/CreativeSolutions";
import Announce from "./pages/announce/Announce";
import Forum from "./pages/forum/Forum";
import Tables from "./pages/table/Tables";



const App = ({location, history}) => {
    const [isInitialized, setIsInitialized] = useState(false);
    const [isShowing, setIsShowing] = useState(0);
    const {currentUser, setCurrentUser} = useContext(store);
    useEffect(() => {
        localization.setLanguage('en');
        // config of api and loader and default headers
        api.interceptors.request.use((config) => {
            const accessToken = localStorage.getItem('accessToken');
            if (accessToken) {
                config.headers['Authorization'] = `Bearer ${accessToken}`;
            }
            config.headers['Content-Type'] = config.contentType || 'application/json';
            config.headers['applicationKey'] = process.env.REACT_APP_API_KEY;
            config.headers['applicationSecret'] = process.env.REACT_APP_API_SECRET;
            config.headers['tenant'] = 'CREATIVE_SOLUTIONS';
            if (!config.noLoader) {
                setIsShowing(isShowing + 1);
            }
            return config;
        }, (error) => {
            setIsShowing(isShowing - 1);
            return Promise.reject(error)
        });
        api.interceptors.response.use((config) => {
            if (!config.noLoader) {
                setTimeout(() => setIsShowing(isShowing - 1), 500);
            }
            return config;
        }, (error) => {
            setIsShowing(isShowing - 1);
            return Promise.reject(error)
        });
        localStorage.setItem('activeChat', null);
        // check if user is authenticated
        if (localStorage.getItem('accessToken')) {
            api.get(`/api/v1/users/me`, ).then(({data}) => {
                setCurrentUser(data);
                setIsInitialized(true);
                if (!data.firstName || !data.lastName) {
                    history.push('/pre-onboarding');
                } else if (!data.position || !data.organization || !data.timeZoneId) {
                    history.push('/onboarding');
                } else if (['/', '/login'].indexOf(location.pathname) >= 0) {
                    history.push('/');
                }
            }).catch(() => {
                setIsInitialized(true);
                localStorage.removeItem('accessToken');
                history.push('/');
            });
        } else {
            if (['/w', '/contacts', '/messages', '/account', '/onboarding', '/pre-onboarding'].indexOf(location.pathname) >= 0) {
                history.push('/');
            }
            setIsInitialized(true);
        }
    }, []);
    return (
        <main className={`app-${process.env.REACT_APP_PROJECT}`}>
            {isInitialized && currentUser ? (
                <Notifications/>
            ) : null}
            {isInitialized ? (
                currentUser ? (
                    <Switch>
                        <Route
                            exact
                            path='/'
                            component={Reception}
                        />
                        <Route
                            path='/apply'
                            component={CreativeSolutions}
                        />
                        <Route
                            path='/announce'
                            component={Announce}
                        />
                        <Route
                            path='/forum'
                            component={Forum}
                        />
                        <Route
                            path='/w'
                            component={Webinars}
                        />
                        <Route
                            exact
                            path='/contacts'
                            component={Contacts}
                        />
                        <Route
                            exact
                            path='/messages'
                            component={Messages}
                        />
                        <Route
                            exact
                            path='/table'
                            component={Tables}
                        />
                        <Route
                            path='/account'
                            component={Account}
                        />
                        <Route
                            exact
                            path='/onboarding'
                            component={Onboarding}
                        />
                        <Route
                            exact
                            path='/pre-onboarding'
                            component={PreOnboarding}
                        />
                        <Route
                            exact
                            path='/email-signin'
                            component={EmailSignIn}
                        />
                        <Route
                            exact
                            path='/o/:id'
                            component={OrganizationPage}
                        />
                        <Route
                            exact
                            path='/zoom-signup'
                            component={ZoomSignUp}
                        />
                        <Route
                            exact
                            path='/zoom-login'
                            component={ZoomLogIn}
                        />
                        <Route
                            exact
                            path='/:id'
                            component={UserProfile}
                        />
                    </Switch>
                ) : (
                    <Switch>
                        <Route
                            exact
                            path='/'
                            render={() => <Redirect to='/login' />}
                        />
                        <Route
                            exact
                            path='/login'
                            component={LogIn}
                        />
                        <Route
                            exact
                            path='/email-expired'
                            component={EmailExpired}
                        />
                        <Route
                            exact
                            path='/check-inbox'
                            component={CheckInbox}
                        />
                        <Route
                            exact
                            path='/email-signin'
                            component={EmailSignIn}
                        />
                        <Route
                            exact
                            path='/linkedin-signup'
                            component={LinkedinSignUp}
                        />
                        <Route
                            exact
                            path='/linkedin-login'
                            component={LinkedinLogIn}
                        />
                        <Route
                            exact
                            path='/zoom-signup'
                            component={ZoomSignUp}
                        />
                        <Route
                            exact
                            path='/zoom-login'
                            component={ZoomLogIn}
                        />
                        <Route
                            path='/w'
                            component={Join}
                        />
                        <Route
                            exact
                            path='/o/:id'
                            component={OrganizationPage}
                        />
                        <Route
                            exact
                            path='/:id'
                            component={UserProfile}
                        />
                    </Switch>
                )
            ) : null}
            <Loader isShowing={isShowing > 0} />
        </main>
    )
};

export default withRouter(App);