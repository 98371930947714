import React from 'react';
import classNames from 'classnames';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/index.min.css';
import localization from "../../localization";


const LocationInput = ({initialValue, error, onChange}) => {
    return (
        <div className={classNames('location-input', {'location-input--error': error})}>
            <div className='location-input--image'>
                <img alt='marker' src='/images/marker.svg'/>
            </div>
            <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_KEY}
                initialValue={initialValue}
                placeholder={localization.yourLocation}
                onSelect={({description}) => onChange(description)}
            />
            {error ? (
                <div className='location-input--message'>{error}</div>
            ) : null}
        </div>
    );
};

export default LocationInput;
