import React, {createContext, useState} from 'react';
import api from "./api";
import Chat from "twilio-chat";

const store = createContext({});
const {Provider} = store;

const StateProvider = ( { children } ) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [favorites, setFavorites] = useState([]);
    const [activeChat, setActiveChat] = useState(null);
    const [twilioClient, setTwilioClient] = useState(null);
    const [eventNotifications, setEventNotifications] = useState([]);
    const [oneToOneUnread, setOneToOneUnread] = useState({});
    const [discussionUnread, setDiscussionUnread] = useState({});

    return (
        <Provider
            value={{
                currentUser,
                setCurrentUser: (user) => {
                    setCurrentUser(user);
                    api.get(`/api/v1/users/twilio/access-token`, ).then(({data}) => {
                        Chat.create(data.accessToken).then(client => {
                            setTwilioClient(client);
                        });
                    });
                },
                twilioClient,
                favorites,
                setFavorites,
                activeChat,
                setActiveChat: (chat) => {
                    setActiveChat(chat);
                    localStorage.setItem('activeChat', chat);
                },
                eventNotifications,
                setEventNotifications,
                oneToOneUnread,
                setOneToOneUnread,
                discussionUnread,
                setDiscussionUnread
            }}
        >
            {children}
        </Provider>
    );
};

export {store, StateProvider}