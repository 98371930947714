import React, {useState} from 'react';
import localization from "../../../localization";
import DataPair from "../../../components/content/DataPair";
import Input from "../../../components/form/Input";
import api from "../../../api";



const JoinRegistration = ({data, onSubmit}) => {
    const [formData, setFormData] = useState(data.dynamicFormRequests.map(() => ''));
    const [errors, setErrors] = useState(data.dynamicFormRequests.map(() => ''));
    return (
        <div className='join-registration'>
            <div className='join-registration--content'>
                <div className='join-registration--title'>
                    {localization.webinarJoinRegistrationTitle}
                </div>
                <div className='join-registration--description'>
                    {localization.webinarJoinRegistrationDescription}
                </div>
                <div className='join-registration--questions'>
                    {data.dynamicFormRequests.map((item, index) => (
                        <DataPair
                            key={index}
                            title={`${item.fieldNameOrQuestion}${item.required ? '*' : ''}`}
                            content={(
                                <Input
                                    value={formData[index]}
                                    onChange={(value) => setFormData(formData.map((item, index2) => index === index2 ? value : item))}
                                    error={formData[index] && errors[index]}
                                    placeholder={item.defaultValue}
                                />
                            )}
                        />
                    ))}
                </div>
                <button
                    className='submit-button'
                    onClick={() => {
                        const errors = [];
                        data.dynamicFormRequests.forEach(({required}, index) => {
                            if (required && !formData[index]) {
                                errors[index] = localization.mandatory;
                            }
                        });
                        setErrors(errors);
                        if (!errors.length) {
                            const payload = data.dynamicFormRequests.map((item, index) => ({
                                dynamicFormRequest: {id: item.id},
                                value: formData[index]
                            }));
                            api.post(`/api/v1/event/${data.id}/dynamic-form-values`, payload).then(() => {
                                onSubmit({
                                    ...data,
                                    dynamicFormValuesAdded: 'DONE'
                                });
                            });
                        }
                    }}
                >
                    {localization.webinarJoinRegistrationSubmit}
                </button>
            </div>
        </div>
    );
};

export default JoinRegistration;
