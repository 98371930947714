import React from 'react';


const NotApproved = ({title, description}) => {
    return (
        <div className='to-be-approved'>
            <div className='to-be-approved--content'>
                <div className='to-be-approved--title'>
                    {title}
                </div>
                <div className='to-be-approved--description'>
                    {description}
                </div>
            </div>
        </div>
    );
};

export default NotApproved;
