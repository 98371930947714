import React, {useEffect, useState} from 'react';
import {createPortal} from 'react-dom'

const MessagingActions = ({children}) => {
    const [element, setElement] = useState(document.createElement('div'));
    useEffect(() => {
        const pageActions = document.getElementById('page-actions');
        pageActions.appendChild(element);
        return () => pageActions.removeChild(element);
    }, []);
    return createPortal(
        children,
        element
    );
};

export default MessagingActions;