import React, {useContext, useEffect, useState} from 'react';
import ProfileDetails from "../../components/profile/ProfileDetails";
import {withRouter} from "react-router";
import {store} from "../../store";
import PageContent from "../../components/page/PageContent";
import {Helmet} from "react-helmet";
import Profile from "../../components/profile/Profile";
import api from "../../api";
import queryString from "query-string";
import NorFound from "../notfound/NorFound";



const UserProfile = ({location, history}) => {
    const [suffix, setSuffix] = useState(null);
    const [user, setUser] = useState(null);
    const [is404, setIs404] = useState(false);
    const {currentUser} = useContext(store);
    useEffect(() => {
        const id = localStorage.getItem('profileSuffix') || location.pathname.split('/')[1];
        setSuffix(id);
        if (!currentUser) {
            fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/last-link/${id}`, {
                method: 'GET',
                headers: {
                    ['Content-Type']: 'application/json',
                    ['applicationKey']: process.env.REACT_APP_API_KEY,
                    ['applicationSecret']: process.env.REACT_APP_API_SECRET
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (data.message) {
                        setIs404(true);
                    } else {
                        setUser(data);
                    }
                });
        }
    }, []);
    return suffix ? (
        currentUser ? (
            <ProfileDetails
                suffix={suffix}
                backAction={() => {
                    localStorage.removeItem('profileSuffix');
                    history.push('/w');
                }}
                defaultTab={queryString.parse(location.search).tab === 'chat' ? 1 : 0}
            />
        ) : (
            user ? (
                <div
                    className='profile-details--unauthorized'
                    onClick={() => {
                        localStorage.setItem('profileSuffix', suffix);
                        history.push('/');
                    }}
                >
                    <PageContent
                        title={`${user.firstName} ${user.lastName}`}
                        className='profile-details'
                    >
                        <Helmet>
                            <title>{user.firstName} {user.lastName} | {process.env.REACT_APP_PROJECT_NAME}</title>
                            <meta name="description" content={`${user.position} | ${user.organization}`} />
                        </Helmet>
                        <Profile
                            user={user}
                        />
                    </PageContent>
                </div>
            ) : is404 ? (
                <NorFound/>
            ) : null
        )
    ) : null;
};

export default withRouter(UserProfile);