export const createLink = (string) => {
    const urlRegex = /"?[\w:/]+\.+[\w/.]+/g;
    return string.replace(urlRegex, (url) => url.indexOf('"') === 0 ? url : (`<a href="${url}" target="_blank">${url}</a>`));
};

export const addHttpsToLink = (string) => {
    let result = '';
    const array = string.split('"');
    for (let index = 0; index < array.length; index++) {
        result = `${result}${index ? '"' : ''}${array[index]}`;
        if (array[index].indexOf('<a href=') >= 0) {
            const item = array[index + 1];
            if (item.indexOf('http://') < 0 && item.indexOf('https://') < 0 && item.indexOf('mailto:') !== 0) {
                result = `${result}"https://${item}`;
                index++;
            }
        }
    }
    return result;
};