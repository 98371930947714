import React, {useState} from 'react';
import classNames from 'classnames';
import RichTextEditor from 'react-rte';


const RichTextInput = ({value, onChange, placeholder, error, disabled}) => {
    const [richTextValue, setRichTextValue] = useState(RichTextEditor.createValueFromString(value || '', 'html'));
    return (
        <div className={classNames('rich-text-input', {'rich-text-input--error': error})}>
            <RichTextEditor
                onChange={(value) => {
                    setRichTextValue(value);
                    onChange(value.toString('html'));
                }}
                value={richTextValue}
                placeholder={placeholder}
                disabled={disabled}
            />
            {error ? (
                <div className='rich-text-input--message'>{error}</div>
            ) : null}
        </div>
    );
};

export default RichTextInput;
