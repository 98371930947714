import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {withRouter} from "react-router-dom";
import InputPair from "../../components/form/InputPair";
import api from "../../api";
import ExpandableBlock from "./ExpandableBlock";



const CreativeSolutionsGeneral = ({history, setLogOut}) => {
    const [formData, setFormData] = useState({});
    return (
        <div>
            {/*<div className='creative-solutions--header'>
                <div className='creative-solutions--header--content'>
                    <div className='creative-solutions--tabs'>
                        <button className='creative-solutions--tab creative-solutions--tab--active' onClick={() => history.push('/apply')}>
                            Application
                        </button>
                        <div className='creative-solutions--tab' onClick={() => history.push('/apply/evaluation')}>
                            Evaluation
                        </div>
                    </div>
                </div>
            </div>*/}
            <div className='creative-solutions--actions' style={{marginTop: 20}}>
                <button onClick={() => history.push(`/apply/applicant${window.location.search}`)} disabled>
                    Application Closed
                </button>
                <button onClick={() => history.push(`/apply/mentor${window.location.search}`)}>
                    Register to be a Mentor
                </button>
            </div>
            <div id='section_2' className='creative-solutions--block'>
                <div className='creative-solutions--block--title'>
                    Judging Criteria
                </div>
                <div>
                    The criteria will be presented as statements. Each of the criteria has the same weight.
                </div>
                <div>
                    The judges will respond by indicating how strongly they accept these statements.
                </div>
                <br/>
                <div>1.	Strength of the idea – The idea is adventurous, well-developed, demonstrates strong vision and application.</div>
                <div>2.	Originality of the idea – The idea is out of the box and likely to push boundaries for immersive content.</div>
                <div>3.	Functionality – The idea will be very well represented through the end prototype.</div>
                <div>4.	Suitability/Relevance – The idea described uses suitable immersive technology and promises a good benchmark. The idea is also likely to be suitable for further funding opportunities.</div>
                <div>5.	Feasibility of prototype delivery – The delivery of the proposal is clear and achievable in the time frame and budget.</div>
                <div>6.	Potential to reach a chosen target audience – The proposal shows a good understanding of a clearly identified target audience and their unique characteristics.</div>
            </div>
            {/*<div id='section_2' className='creative-solutions--block'>
                <div className='creative-solutions--block--title'>
                    The Open Call application deadline is 11:59 PM AST on May 8, 2021, but we are still accepting late applications.
                </div>
                <div>
                    <strong>Feb 16</strong> | Applications Open
                </div>
                <div>
                    <strong>May 8</strong> | Deadline
                </div>
                <div>
                    <strong>Late May</strong> | Last Invite Sent
                </div>
            </div>*/}
            <div id='section_3' className='creative-solutions--block'>
                <div className='creative-solutions--block--title'>
                    FAQs
                </div>
                <ExpandableBlock title="What is Creative Solutions?">
                    <strong>Creative Solutions</strong> is a yearly thematic innovation program for multi-disciplinary professionals (art, science, technology, etc.) that are eager to collaborate with others in the development of a viable solution to an identified opportunity or challenge. Participants of the Creative Solutions program follow the Idea Lab processes (Design Thinking, Co-creation, Open Innovation) and are guided by experts in creativity, innovation, advisors, mentors and facilitators throughout an idea to prototype journey.
                </ExpandableBlock>
                <ExpandableBlock title="What are the objectives of the program?">
                    <u>Program Main goals</u>:
                    <ul>
                        <li>Develop original creative content, experiences and marketable solutions.</li>
                        <li>Build an ecosystem of innovators that support the growth of the creativeeconomy in the Kingdom of Saudi Arabia.</li>
                        <li>Support professional skills development.</li>
                        <li>Promote knowledge sharing</li>
                    </ul>
                </ExpandableBlock>
                <ExpandableBlock title="What is the Creative Solutions theme in 2021?">
                    In the 2021 edition, Creative Solutions will embrace the theme Digital Immersive Content Creation. The aim is to ignite the development of an ecosystem in Saudi Arabia of creatives and innovators that uses digital technologies, such as VirtualReality (VR), Augmented Reality (AR), Mixed Realities (MR), Extended Realities (XR), Immersive Audio, and Haptics to create innovative content, experiences and solutions.
                </ExpandableBlock>
                <ExpandableBlock title="What are the benefits of Creative Solutions?">
                    <ul>
                        <li>Masterclasses on relevant topics to support skills development and idea development towards prototype stage</li>
                        <li>Lab Infrastructure – For the duration of the program, participants will have access toa state of the art Extended Realities Lab in the Idea Lab facilities in Ithra</li>
                        <li>Peer to peer learning – Part of a cohort of up to 30 talented creators from across thecreative and technology sector</li>
                        <li>Business expertise – Support network of industry experts, high calibre peers and commissioning bodies to help develop impactful experiences and build new business models</li>
                        <li>Mentorship with experts</li>
                        <li>Demo Day – Opportunity to participate, pitch and host private demos and meetings with key stakeholder that can support further development and opportunities</li>
                        <li>International Bootcamp (for the selected five ideas/projects)</li>
                    </ul>
                </ExpandableBlock>
                <ExpandableBlock title="What are the key* dates for the program?">
                    <ul>
                        <li>Creative Solutions Launching Event: February 18th 2021</li>
                        <li>Open call live: February 23rd – May 2nd 2021</li>
                        <li>Announce selected companies: May 8th 2021</li>
                        <li>Onboarding Event: May 20th 2021</li>
                        <li>Masterclasses: Weekend from May – July 2021</li>
                        <li>International Bootcamp: August</li>
                        <li>Prototyping development phase: August – November 2021</li>
                        <li>Creative Solutions Demo Day event: December 16th – 18th 2021</li>
                    </ul>
                    <br/>
                    <div>*Timelines are approximate and can be subject to change</div>
                </ExpandableBlock>
                <ExpandableBlock title="How the participants will engage in the program and how much time the participants will need to commit?">
                    <ul>
                        <li>The program was designed to be delivered mostly in a hybrid (physically and virtually) way.</li>
                        <li>The selected cohort of participants will have the option to attend the Onboarding, Masterclasses and other events in person or virtually. Costs for in-person activities will be covered by the Creative Solutions program. </li>
                        <li>To support a high engagement from the cohort, all masterclasses will be delivered over the weekends.</li>
                        <li>The development phase is four months long, and we will be flexible to accommodate the prototype development plans in a way that does not disturb other personal and professional commitments of the participants.</li>
                    </ul>
                </ExpandableBlock>
                <ExpandableBlock title="Who can apply?">
                    <div>Creative Solutions program is designed to support any 18+ year old Saudi Arabia citizen or resident creative and innovator in Saudi who has ideas, concepts and/or projects that can leverage immersive technologies such as VR, AR, XR, Haptics, and Immersive Audio to develop new and authentic content, experiences and viable solutions for the creative economy. The potential participants can be, for instance, artists, entrepreneurs, technologists, professionals, university students or academic researchers, among others.</div>
                    <ul>
                        <li>The program will be delivered in English as the main language, so fluency in English is mandatory to join the program.</li>
                        <li>Expertise in immersive content development and creations is not necessary.</li>
                        <li>The participant must be able to attend all the activities, physically or virtually (for example, workshop and final Demo Day hosted in Ithra for the duration of the programme).</li>
                        <li>You may enter as a collaborative team, but there must be a lead individual that will engage in the program.</li>
                        <li>All other collaborators involved must be named in the application process.</li>
                    </ul>
                </ExpandableBlock>
                <ExpandableBlock title="How do I apply to join the program?">
                    <div>Complete the full application form</div>
                    <ul>
                        <li>After your application has been received, it will be analyzed and, if needed, a phone call might be arranged to discuss your application.</li>
                    </ul>
                </ExpandableBlock>
                <ExpandableBlock title="What information do we ask for in the application form?">
                    <ul>
                        <li>Contact information (for example, Full Name, email address, telephone number, date of birth, etc.)</li>
                        <li>The idea (for example, strength, originality, relevance)</li>
                        <li>The opportunity or challenge identified</li>
                        <li>Background and Motivations</li>
                        <li>Ability to commit to attending our workshops and events</li>
                    </ul>
                </ExpandableBlock>
                <ExpandableBlock title="How does the Ithra Creative Solutions program deal with non-confidentiality and IP (intellectual property)?">
                    <ul>
                        <li>There is no requirement to disclose existing IP or 3rd Party IP as part of the Creative Solutions selection process.</li>
                        <li>All disclosures made at the Creative Solutions program shall be considered non-confidential (i.e. “in the open”), so you choose what you disclose to others. If you do share information then be aware that you are sharing it openly and participants are free to talk to others about their experiences.</li>
                        <li>The Ithra Creative Solutions program will provide an environment where ideas are generated, and sharing is encouraged.  You agree to be respectful of others' thoughts and ideas, and that the ideas generated at the program shall be freely available for use and disclosure by other attendees with attribution where appropriate.</li>
                        <li>If you use someone else’s idea disclosed at the Ithra Creative Solutions program, you agree not to claim or register that idea as your own.</li>
                        <li>If you use an idea disclosed at the Ithra Creative Solutions program, you agree to do so at your own risk.</li>
                        <li>If you do reveal any background intellectual property (IPR) in the form of closed software, you must clearly advise others that it is your background IPR, other participants agree not to reverse engineer it, unless they obtain it lawfully other than at the Ithra Creative Solutions program.</li>
                        <li>If there comes a point in the Ithra Creative Solutions program when you feel that it is appropriate for a conversation to move into a “closed” mode, let a member of the program team know and appropriate arrangements will be made.</li>
                        <li>Intellectual Property includes proprietary know-how, copyrighted material, inventions, patent rights, and any other type of intellectual property.</li>
                    </ul>
                </ExpandableBlock>
                <ExpandableBlock title="Participant assessment criteria?">
                    <ul>
                        <li>The criteria will be presented as statements. Each of the criteria has the same weight.</li>
                        <li>The judges will respond by indicating how strongly they accept these statements.</li>
                        <li><strong>Strength and originality of the idea</strong> – The participant presents an original strong idea that uses digital immersive technologies.</li>
                        <li><strong>Functionality</strong> – The idea could be well represented through the end prototype.</li>
                        <li><strong>Challenge and Opportunity</strong> – The participant presents a defined challenge and/or opportunity that can be addressed by the presented idea.</li>
                        <li><strong>Potential to reach a chosen target audience</strong> – The proposal shows a clear understanding of an identified target audience.</li>
                    </ul>
                </ExpandableBlock>
            </div>
        </div>
    );
};

export default withRouter(CreativeSolutionsGeneral);