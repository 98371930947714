import React, {useContext, useEffect, useState} from 'react';
import PageContent from "../page/PageContent";
import Tabs from "../tabs/Tabs";
import Profile from "./Profile";
import api from "../../api";
import {store} from "../../store";
import OneToOne from "../messaging/OneToOne";
import {Helmet} from "react-helmet";
import localization from "../../localization";
import NorFound from "../../pages/notfound/NorFound";



const ProfileDetails = ({messagingDetails, personId, suffix, defaultTab, backAction, noBackOnDesktop}) => {
    const [selectedTab, setSelectedTab] = useState(defaultTab || 0);
    const [user, setUser] = useState(null);
    const [chatId, setChatId] = useState(null);
    const [senderSid, setSenderSid] = useState(null);
    const [is404, setIs404] = useState(false);
    const {currentUser} = useContext(store);
    useEffect(() => {
        if (messagingDetails) {
            setUser(messagingDetails.user);
            setChatId(messagingDetails.chatId);
            setSenderSid(messagingDetails.senderSid);
        } else {
            if (suffix) {
                if (['linkedin-login', 'login-email'].indexOf(suffix) < 0) {
                    fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/last-link/${suffix}`, {
                        method: 'GET',
                        headers: {
                            ['Content-Type']: 'application/json',
                            ['applicationKey']: process.env.REACT_APP_API_KEY,
                            ['applicationSecret']: process.env.REACT_APP_API_SECRET
                        }
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data.message) {
                                setIs404(true);
                            } else {
                                setUser(data);
                                api.get(`/api/v1/chat/details?user_id_1=${currentUser.id}&user_id_2=${data.id}`).then(({data}) => {
                                    setChatId(data.twilioChannelSid);
                                    setSenderSid(data.createdBy === currentUser.email ? data.senderTwilioMemberSid : data.receiverTwilioMemberSid);
                                });
                            }
                        });
                }
            } else {
                api.get(`/api/v1/users/${personId}`).then(({data}) => {
                    setUser(data);
                });
                api.get(`/api/v1/chat/details?user_id_1=${currentUser.id}&user_id_2=${personId}`).then(({data}) => {
                    setChatId(data.twilioChannelSid);
                    setSenderSid(data.createdBy === currentUser.email ? data.senderTwilioMemberSid : data.receiverTwilioMemberSid);
                })
            }
        }
    }, []);
    return user ? (
        <PageContent
            title={`${user.firstName} ${user.lastName}`}
            className='profile-details'
            subtitle={(
                <Tabs
                    items={[localization.tabAbout, localization.tabChat]}
                    selected={selectedTab}
                    onChange={setSelectedTab}
                />
            )}
            backAction={backAction}
            isFullWidth={[1].indexOf(selectedTab) >= 0}
            isNoPadding={[1].indexOf(selectedTab) >= 0}
            noBackOnDesktop={noBackOnDesktop}
            isWhiteBody
        >
            <Helmet>
                <title>{user.firstName} {user.lastName} | {process.env.REACT_APP_PROJECT_NAME}</title>
                <meta name="description" content={`${user.position} | ${user.organization}`} />
            </Helmet>
            {selectedTab === 0 ? (
                <Profile
                    user={user}
                />
            ) : null}
            {selectedTab === 1 ? (
                <div className='profile-details--discussion'>
                    {/*<div className='profile-details--call'>
                        <div className='profile-details--call--description'>
                            <img alt='webinar-white' src='/images/webinar-white.svg' />
                            <div>Interested to meet 1-on-1 on video?</div>
                        </div>
                        <button>
                            Call on Zoom
                        </button>
                    </div>*/}
                    {chatId ? (
                        <OneToOne
                            chatId={chatId}
                            setChatId={setChatId}
                            sender={currentUser}
                            receiver={user}
                            senderSid={senderSid}
                        />
                    ) : null}
                </div>
            ) : null}
        </PageContent>
    ) : is404 ? (
        <NorFound/>
    ) : null;
};

export default ProfileDetails;