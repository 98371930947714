import React, {useContext, useEffect, useState} from 'react';
import {store} from "../../../store";
import api from "../../../api";
import Dropdown from "../../../components/content/Dropdown";
import ModalConfirmation from "../../../components/modal/ModalConfirmation";
import localization from "../../../localization";
import SearchInput from "../../../components/form/SearchInput";
import momentTimezone from "moment-timezone";
import EmptyPage from "./EmptyPage";
import InfiniteScroll from "../../../components/content/InfiniteScroll";


const statuses = [{
    label: 'Registered',
    value: null
},{
    label: 'To Be Approved',
    value: 'TO_BE_APPROVED'
},{
    label: 'Approved',
    value: 'APPROVED'
},{
    label: 'Denied',
    value: 'DENIED'
},{
    label: 'Removed',
    value: 'REMOVED'
},{
    label: 'Blocked',
    value: 'BLOCKED'
}];

const Registrants = ({webinarId, selectPerson, loggedInUserEventParticipant}) => {
    const [status, setStatus] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [block, setBlock] = useState(null);
    const [remove, setRemove] = useState(null);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState({pageNo: 0, totalElements: 0});
    const {currentUser, eventNotifications, setEventNotifications} = useContext(store);
    useEffect(() => {
        const notifications = eventNotifications.filter(({objectId, notificationsType}) => objectId === webinarId && notificationsType === 'EVENT_REGISTRANTS');
        if (notifications.length) {
            api.put(`/api/v1/notifications/read`, {notificationsType: 'EVENT_REGISTRANTS', objectId: webinarId}).then(({data}) => {
                api.get(`/api/v1/notifications/unread`).then(({data}) => {
                    setEventNotifications(data);
                });
            });
        }
    }, []);
    useEffect(() => {
        api.get(`/api/v2/event/${webinarId}/participants?query=&pageNo=0&pageSize=25`).then(({data}) => {
            setPage({pageNo: data.pageNo, totalElements: data.totalElements});
            setParticipants(data.content);
        });
    }, []);
    const filteredParticipants = participants.filter((participant) => (!status || status === participant.status));
    return (
        <div className='registrants'>
            <div className='flex-between'>
                <SearchInput
                    value={search}
                    onChange={(value) => {
                        setSearch(value);
                        if (!value.length || value.length > 2) {
                            api.get(`/api/v2/event/${webinarId}/participants?query=${value}&pageNo=0&pageSize=25`).then(({data}) => {
                                setPage({pageNo: data.pageNo, totalElements: data.totalElements});
                                setParticipants(data.content);
                            });
                        }
                    }}
                    placeholder={localization.webinarRegistrantsSearch}
                />
                <button
                    className='action-button'
                    onClick={() => {
                        api.get(`/api/v1/event/${webinarId}/dynamic-form-values/all`, {
                            headers: {
                                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            }
                        }).then(({data}) => {
                            const bytes = new Uint8Array(data);
                            const blob = new Blob([bytes], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
                            const link = document.createElement('a');
                            link.download = `registrants.csv`;
                            link.href = URL.createObjectURL(blob);
                            link.click();
                        })
                    }}
                >
                    {localization.webinarRegistrantsCSV}
                </button>
            </div>
            <div className='registrants--header'>
                {localization.formatString(localization.webinarRegistrantsShowing, page.totalElements - 1)}
                {status ? ` > ${filteredParticipants.length} ${statuses.filter(({value}) => value === status)[0].label}`: null}
            </div>
            {participants.filter((participant) => ((participant.participantUser.id !== currentUser.id))).length ? (
                <div className='registrants--content'>
                    <table>
                        <thead>
                        <tr>
                            <td>{localization.webinarRegistrantsTableName}</td>
                            <td>{localization.webinarRegistrantsTableEmail}</td>
                            <td>
                                <div className='flex-between'>
                                    {localization.webinarRegistrantsTableStatus}
                                    <Dropdown
                                        button={(<img alt='filter-black' src='/images/filter-black.svg' />)}
                                    >
                                        {statuses.map((item) => (
                                            <button
                                                onClick={() => setStatus(item.value)}
                                            >
                                                {item.label} ({participants.filter((participant) => ((participant.participantUser.id !== currentUser.id) && (!item.value || item.value === participant.status))).length})
                                            </button>
                                        ))}
                                    </Dropdown>
                                </div>
                            </td>
                            <td>{localization.webinarRegistrantsTableRegistration}</td>
                            <td>{localization.webinarRegistrantsTableTicket}</td>
                            <td>{localization.webinarRegistrantsTableActions}</td>
                        </tr>
                        </thead>
                        <tbody>
                        {filteredParticipants.map((item) => (
                            <tr>
                                <td>{item.participantUser.firstName} {item.participantUser.lastName}</td>
                                <td>{item.participantUser.email}</td>
                                <td>
                                    {!item.host ? (
                                        <Dropdown
                                            className={`dropdown--${item.status}`}
                                            button={statuses.filter(({value}) => value === item.status)[0].label}
                                        >
                                            <button
                                                onClick={() => {
                                                    api.patch(`/api/v1/event/participants/status`, {
                                                        eventParticipantId: item.id,
                                                        status: 'APPROVED'
                                                    }).then(() => {
                                                        setParticipants(
                                                            participants.map((participant) => {
                                                                if (participant.id === item.id) {
                                                                    return {
                                                                        ...participant,
                                                                        status: 'APPROVED'
                                                                    }
                                                                } else {
                                                                    return participant
                                                                }
                                                            })
                                                        );
                                                    });
                                                }}
                                            >
                                                {localization.webinarRegistrantsApprove}
                                            </button>
                                            <button
                                                onClick={() => {
                                                    api.patch(`/api/v1/event/participants/status`, {
                                                        eventParticipantId: item.id,
                                                        status: 'DENIED'
                                                    }).then(() => {
                                                        setParticipants(
                                                            participants.map((participant) => {
                                                                if (participant.id === item.id) {
                                                                    return {
                                                                        ...participant,
                                                                        status: 'DENIED'
                                                                    }
                                                                } else {
                                                                    return participant
                                                                }
                                                            })
                                                        );
                                                    });
                                                }}
                                            >
                                                {localization.webinarRegistrantsDeny}
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setRemove(item)
                                                }}
                                            >
                                                {localization.webinarRegistrantsRemove}
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setBlock(item)
                                                }}
                                            >
                                                {localization.webinarRegistrantsBlock}
                                            </button>
                                        </Dropdown>
                                    ) : null}
                                </td>
                                <td>{momentTimezone(item.createdDateStr).tz(currentUser.timeZoneId).format('DD MMM YYYY hh:mm A')}</td>
                                <td></td>
                                <td>
                                    {item.participantUser.id !== currentUser.id && (loggedInUserEventParticipant.host || loggedInUserEventParticipant.admin) && !item.host ? (
                                        <Dropdown
                                            className='actions-dropdown'
                                            button={(<img alt='dropdown' src='/images/dropdown.svg' />)}
                                        >
                                            <button
                                                onClick={() => {
                                                    api.post(`/api/v1/event/participants`, {
                                                        eventId: webinarId,
                                                        participantUserId: item.participantUser.id,
                                                        admin: !item.admin
                                                    }).then(({data}) => {
                                                        api.get(`/api/v1/event/${webinarId}/participants`).then(({data}) => {
                                                            setParticipants(data.filter(({status}) => status === 'APPROVED'));
                                                        })
                                                    });
                                                }}
                                            >
                                                {item.admin ? localization.accountOrganizationAdminRemove : localization.accountOrganizationAdmin}
                                            </button>
                                        </Dropdown>
                                    ) : null}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <EmptyPage
                    title={localization.webinarRegistrantsEmptyTitle}
                    description={localization.webinarRegistrantsEmptyDescription}
                    actionLabel={localization.webinarRegistrantsEmptyAction}
                    actionHandler={() => {}}
                />
            )}
            {block ? (
                <ModalConfirmation
                    title={(
                        <>
                            <div>{localization.webinarRegistrantsBlock}</div>
                            <div>{block.participantUser.firstName} {block.participantUser.lastName}?</div>
                        </>
                    )}
                    description={localization.formatString(localization.webinarRegistrantsBlockQuestion, block.participantUser.firstName, block.participantUser.lastName)}
                    confirm={localization.webinarRegistrantsBlockConfirm}
                    cancel={localization.noCancel}
                    onCancel={() => setBlock(null)}
                    onConfirm={() => {
                        api.patch(`/api/v1/event/participants/status`, {
                            eventParticipantId: block.id,
                            status: 'BLOCKED'
                        }).then(() => {
                            setParticipants(
                                participants.map((participant) => {
                                    if (participant.id === block.id) {
                                        return {
                                            ...participant,
                                            status: 'BLOCKED'
                                        }
                                    } else {
                                        return participant
                                    }
                                })
                            );
                        });
                    }}
                />
            ) : null}
            {remove ? (
                <ModalConfirmation
                    title={(
                        <>
                            <div>{localization.webinarRegistrantsRemove}</div>
                            <div>{remove.participantUser.firstName} {remove.participantUser.lastName}?</div>
                        </>
                    )}
                    description={localization.formatString(localization.webinarRegistrantsRemoveQuestion, remove.participantUser.firstName, remove.participantUser.lastName)}
                    confirm={localization.webinarRegistrantsRemoveConfirm}
                    cancel={localization.noCancel}
                    onCancel={() => setRemove(null)}
                    onConfirm={() => {
                        api.patch(`/api/v1/event/participants/status`, {
                            eventParticipantId: remove.id,
                            status: 'REMOVED'
                        }).then(() => {
                            setParticipants(
                                participants.map((participant) => {
                                    if (participant.id === remove.id) {
                                        return {
                                            ...participant,
                                            status: 'REMOVED'
                                        }
                                    } else {
                                        return participant
                                    }
                                })
                            );
                        });
                    }}
                />
            ) : null}
            <InfiniteScroll
                callback={() => {
                    if (page.totalElements > ((page.pageNo + 1) * 25)) {
                        api.get(`/api/v2/event/${webinarId}/participants?query=${search}&pageNo=${page.pageNo + 1}&pageSize=25`).then(({data}) => {
                            setPage({pageNo: data.pageNo, totalElements: data.totalElements});
                            setParticipants([...participants, ...data.content]);
                        });
                    }
                }}
            />
        </div>
    );
};

export default Registrants;
