import React, {useContext, useEffect, useState} from 'react';
import PageContent from "../../components/page/PageContent";
import localization from "../../localization";
import Messaging from "../../components/messaging/Messaging";
import api from "../../api";
import ModalCentral from "../../components/modal/ModalCentral";
import TextArea from "../../components/form/TextArea";
import InputPair from "../../components/form/InputPair";
import ImageUpload from "../../components/form/ImageUpload";
import Block from "../../components/content/Block";
import Input from "../../components/form/Input";
import LogoUpload from "../../components/form/LogoUpload";
import classNames from "classnames";


const PostAnswers = ({eventId, tableNo, onCreate, handleClose}) => {
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    return (
        <ModalCentral
            className='tables-dialog'
            title='Create a Table'
            submitLabel='Create'
            handleClose={handleClose}
            onSubmit={() => {
                const data = {
                    eventId,
                    tableNo,
                    ...formData
                };
                const errors = {};
                if (!formData.tableName) {
                    errors.tableName = 'Please fill Table Name';
                }
                if (!formData.seatingCapacity || formData.seatingCapacity < 0 || formData.seatingCapacity > 11) {
                    errors.seatingCapacity = 'Please fill correct Table Capacity';
                }
                setErrors(errors);
                if (!Object.keys(errors).length) {
                    api.post(`/api/v1/event/${eventId}/table`, data).then(({data}) => {
                        onCreate(data);
                        handleClose();
                    });
                }
            }}
        >
            <InputPair
                label="Table Name"
                value={formData.tableName}
                onChange={(value) => setFormData({...formData, tableName: value})}
                error={!formData.tableName && errors.tableName}
                required
            />
            <div className='input-pair'>
                <div className='input-pair--label'>Table Image/Logo</div>
                <LogoUpload
                    value={formData.logo}
                    onChange={(value) => setFormData({...formData, logo: value})}
                    placeholder='No file chosen'
                />
            </div>
            <InputPair
                label="Table Capacity"
                value={formData.seatingCapacity}
                onChange={(value) => setFormData({...formData, seatingCapacity: value})}
                error={!formData.seatingCapacity && errors.seatingCapacity}
                type='number'
                min={6}
                max={11}
                required
            />
            <div className='tables-dialog--hint'>Maximum of 11 members at a table</div>
        </ModalCentral>
    );
};

export default PostAnswers;
