import React, {Fragment} from 'react';
import Block from "../content/Block";
import Person from "../content/Person";
import {linkTypes} from '../../utils/constants';
import FavoritesButton from "../form/FavoritesButton";
import Avatar from "../content/Avatar";
import localization from "../../localization";
import SocialLinks from "../organization/SocialLinks";


const Profile = ({user}) => {
    return (
        <div className='account-profile'>
            <div className='profile-main'>
                <Avatar
                    className='profile-picture'
                    image={user.image}
                    fullName={`${user.firstName} ${user.lastName}`}
                />
                <div className='profile-name'>{user.firstName} {user.lastName}</div>
                <div className='profile-location'>
                    <img alt='marker' src='/images/marker.svg'/>
                    <div>{user.location}</div>
                </div>
                <FavoritesButton
                    person={user}
                />
            </div>
            <div className='separator'/>
            <div className='profile-description'>
                <div>
                    <div className='profile-description--title'>
                        {user.position}
                    </div>
                    <div className='profile-description--content'>
                        {user.organization}
                    </div>
                </div>
                <Avatar
                    image={user.logo}
                    fullName={user.organization}
                />
            </div>
            <div className='separator'/>
            {user.bio ? (
                <Fragment>
                    <Block
                        title={`${localization.about} ${user.firstName}`}
                        isContentGray
                    >
                        <div>
                            {(user.bio || '').split(/\r?\n/).map((item, index) => (<div key={index}>{item || (<br />)}</div>))}
                        </div>
                    </Block>
                    <div className='separator'/>
                </Fragment>
            ) : null}
            <Block
                title={localization.links}
            >
                {user.linkedInProfileLink ? (
                    <a href={user.linkedInProfileLink} target='_blank'>
                        <Person
                            image='/images/linkedin.svg'
                            title='Linkedin'
                            isBlackDescription
                            isImageNotRounded
                            isSmaller
                        />
                    </a>
                ) : null}
                <SocialLinks
                    socialLinks={user.socialLinks}
                />
            </Block>
        </div>
    );
};

export default Profile;