import React, {useContext, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';
import api from '../../api';
import {store} from '../../store.js';



const LinkedinLogIn = ({location, history}) => {
    const {setCurrentUser} = useContext(store);
    useEffect(() => {
        const {code} = queryString.parse(location.search);
        api.get(`/linkedin/me?code=${code}`, {
            headers: {
                'isgetmydetailslinkedinrequest': 'yes',
                'linkedin-redirect-uri': `${window.location.origin}/linkedin-signup`
            }
        }).then(({data}) => {
            const {accessToken, user} = data;
            localStorage.setItem('accessToken', accessToken);
            setCurrentUser(user);
            if (!user.position || !user.organization || !user.timeZoneId) {
                history.push('/onboarding');
            } else {
                if (localStorage.getItem('profileSuffix')) {
                    history.push(`/${localStorage.getItem('profileSuffix')}`);
                } else if (localStorage.getItem('organizationSuffix')) {
                    history.push(`/o/${localStorage.getItem('organizationSuffix')}`);
                } else {
                    history.push('/w');
                }
            }
        }).catch(() => {
            history.push('/');
        });
    }, []);
    return (
        <div/>
    );
};

export default withRouter(LinkedinLogIn);