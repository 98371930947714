import React, {useContext, useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import PageContent from "../../../components/page/PageContent";
import Tabs from "../../../components/tabs/Tabs";
import {store} from "../../../store";
import localization from "../../../localization";
import Organizaton from "../../../components/organization/Organizaton";
import OrganizationMembers from "./OrganizationMembers";
import ProfileDetails from "../../../components/profile/ProfileDetails";
import InviteMembers from "./InviteMembers";



const OrganizationDetails = ({organization, defaultTab, backAction, editOrganization}) => {
    const [selectedTab, setSelectedTab] = useState(defaultTab || 0);
    const [selectedPersonId, setSelectedPersonId] = useState(null);
    const {currentUser} = useContext(store);
    return (
        <PageContent
            title={`${organization.name}`}
            className='profile-details'
            subtitle={(
                <Tabs
                    items={[localization.tabAbout, localization.tabMembers, ...(organization.loggedInUserOrganizationUserView.admin ? [localization.tabInviteMembers] : [])]}
                    selected={selectedTab}
                    onChange={setSelectedTab}
                />
            )}
            backAction={backAction}
            isFullWidth={[1].indexOf(selectedTab) >= 0}
            noBackOnDesktop
            dialog={(
                selectedPersonId ? (
                    <ProfileDetails
                        personId={selectedPersonId}
                        defaultTab={0}
                        backAction={() => {
                            setSelectedPersonId(null);
                        }}
                    />
                ) : null
            )}
            isWhiteBody
        >
            <Helmet>
                <title>{organization.name} | {process.env.REACT_APP_PROJECT_NAME}</title>
                <meta name="description" content={organization.bio} />
            </Helmet>
            {selectedTab === 0 ? (
                <Organizaton
                    organization={organization}
                    editOrganization={editOrganization}
                />
            ) : null}
            {selectedTab === 1 ? (
                <OrganizationMembers
                    organization={organization}
                    selectPerson={setSelectedPersonId}
                />
            ) : null}
            {selectedTab === 2 ? (
                <InviteMembers
                    organization={organization}
                />
            ) : null}
        </PageContent>
    );
};

export default OrganizationDetails;