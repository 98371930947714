import React from 'react';
import Select from 'react-select'
import countryCodes from './countryCodes';


const CodeSelect = ({value, onChange}) => {
    const options = countryCodes.map(({name, dial_code}) => ({label: `${name} (${dial_code})`, value: dial_code}));
    return (
        <div className='code-select'>
            {value ? (
                <div className='code-select--value'>
                    {value}
                </div>
            ) : null}
            <Select
                value={options.filter((item) => item.value === value)[0]}
                onChange={onChange}
                placeholder=' '
                options={options}
            />
        </div>
    );
};

export default CodeSelect;
