import React, {useContext, useEffect, useState} from 'react';
import {store} from "../../store";
import api from "../../api";


const Notifications = () => {
    const [enabled, setEnabled] = useState(false);
    const [chats, setChats] = useState(null);
    const [mutedChats, setMutedChats] = useState(null);
    const [userChatIds, setUserChatIds] = useState(null);
    const {twilioClient, currentUser, setEventNotifications, setOneToOneUnread, setDiscussionUnread} = useContext(store);
    useEffect(() => {
        setEnabled(window.Notification && window.Notification.permission === 'granted');
        api.get(`/api/v1/notifications/unread`).then(({data}) => {
            setEventNotifications(data.filter((item) => [1,2,3].indexOf(item.objectId) < 0));
        });
        api.get(`/api/v1/chat/details/${currentUser.id}`).then((userChats) => {
            setUserChatIds(userChats.data.map(({twilioChannelSid}) => twilioChannelSid));
        });
    }, []);
    useEffect(() => {
        if (twilioClient && enabled) {
            twilioClient.getSubscribedChannels().then((paginator) => {
                const chats = [];
                chats.push(...paginator.items);
                const loadChanel = (paginator) => {
                    if (paginator.hasNextPage) {
                        paginator.nextPage().then((paginator) => {
                            chats.push(...paginator.items);
                            loadChanel(paginator);
                        });
                    } else {
                        setChats([...chats]);
                    }
                };
                loadChanel(paginator);
            });
        }
    }, [enabled, twilioClient]);
    useEffect(() => {
        if (chats && userChatIds) {
            const oneToOne = {};
            const discussion = {};
            chats.forEach((chat) => {
                if (chat.sid !== currentUser.twilioAllEventChannelSid) {
                    chat.getMessages().then((data) => {
                        if (userChatIds.indexOf(chat.sid) >= 0) {
                            oneToOne[chat.sid] = data.items.length - (chat.lastConsumedMessageIndex || 0);
                            setOneToOneUnread({...oneToOne});
                        } else {
                            discussion[chat.sid] = data.items.length - (chat.lastConsumedMessageIndex || 0);
                            setDiscussionUnread({...discussion});
                        }
                    });
                }
                chat.on('messageAdded', ({state}) => {
                    // if (state.attributes.chatId !== localStorage.getItem('activeChat') && localStorage.getItem('mutedChats').indexOf(message.chatId) < 0) {
                    if (state.attributes.chatId !== localStorage.getItem('activeChat')) {
                        const notification = new window.Notification(state.attributes.title, {
                            icon: `${window.location.origin}/images/ithra-logo.png`,
                            body: state.body.replace(/<[^>]*>?/gm, ''),
                        });
                        notification.onclick = function() {
                            window.open(state.attributes.link);
                        };
                        if (chat.sid !== currentUser.twilioAllEventChannelSid) {
                            chat.getMessages().then((data) => {
                                if (userChatIds.indexOf(chat.sid) >= 0) {
                                    oneToOne[chat.sid] = data.items.length - (chat.lastConsumedMessageIndex || 0);
                                    setOneToOneUnread({...oneToOne});
                                } else {
                                    discussion[chat.sid] = data.items.length - (chat.lastConsumedMessageIndex || 0);
                                    setDiscussionUnread({...discussion});
                                }
                            });
                        }
                    }
                });
            });
        }
    }, [chats, userChatIds, mutedChats]);
    if (window.Notification && !enabled && (currentUser && currentUser.firstName && currentUser.lastName && currentUser.position && currentUser.organization && currentUser.timeZoneId)) {
        window.Notification.requestPermission();
    }
    return null;
};

export default Notifications;
