import React, {useContext, useEffect, useState} from 'react';
import Block from "../../../components/content/Block";
import Input from "../../../components/form/Input";
import TextArea from "../../../components/form/TextArea";
import ImageUpload from "../../../components/form/ImageUpload";
import LinkInput from "../../../components/form/LinkInput";
import {store} from "../../../store";
import api from "../../../api";
import {linkTypes, linkPrefixes} from '../../../utils/constants';
import LocationInput from "../../../components/form/LocationInput";
import LogoUpload from "../../../components/form/LogoUpload";
import CodeSelect from "../../../components/form/CodeSelect";
import localization from "../../../localization";
import SuffixInput from "../../../components/form/SuffixInput";




const EditProfile = ({backAction}) => {
    const [formData, setFormData] = useState({});
    const [socialLinks, setSocialLinks] = useState({});
    const [errors, setErrors] = useState({});
    const [suffixValid, setSuffixValid] = useState(true);
    const {currentUser, setCurrentUser} = useContext(store);
    useEffect(() => {
        setFormData({
            image: currentUser.image,
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
            bio: currentUser.bio,
            organization: currentUser.organization,
            position: currentUser.position,
            logo: currentUser.logo,
            location: currentUser.location,
            timeZoneId: currentUser.timeZoneId,
            timeZoneName: currentUser.timeZoneName,
            linkedInProfileLink: currentUser.linkedInProfileLink,
            lastLinkSuffix: currentUser.myLastLink.split('//')[1].split('/')[2]
        });
        setSocialLinks(
            linkTypes.reduce((object, linkType, index) => {
                const links = (currentUser.socialLinks || []).filter((item) => item.type === linkType);
                object[linkType] = links.length ? links.map((item) => ({...item, ...{value: item.url.substring(linkPrefixes[index].length, item.url.length)}})) : [{type: linkType}];
                return object;
            }, {})
         );
    }, [currentUser]);
    return (
        <div>
            <Block
                title={localization.profileInfo}
            >
                <ImageUpload
                    userId={currentUser.id}
                    image={currentUser.image}
                    imageType='PROFILE_IMAGE'
                    onChange={(value) => setFormData({...formData, image: value})}
                />
                <div style={{marginTop: -10, textAlign: 'center'}}>Recommended: 200px by 200px square image</div>
                <Input
                    value={formData.firstName}
                    error={errors.firstName}
                    onChange={(value) => setFormData({...formData, firstName: value})}
                    placeholder={localization.profileFirstName}
                />
                <Input
                    value={formData.lastName}
                    error={errors.lastName}
                    onChange={(value) => setFormData({...formData, lastName: value})}
                    placeholder={localization.profileLastName}
                />
                <TextArea
                    value={formData.bio}
                    onChange={(value) => setFormData({...formData, bio: value})}
                    placeholder={`${localization.profileWriteAbout} ${formData.firstName || localization.profileYourself}...`}
                    rows={3}
                />
                <LocationInput
                    initialValue={formData.location}
                    onChange={(value) => setFormData({...formData, location: value})}
                    error={errors.location}
                />
            </Block>
            <Block
                title={localization.profileLink}
            >
                <SuffixInput
                    value={formData.lastLinkSuffix}
                    onChange={(value) => setFormData({...formData, lastLinkSuffix: value})}
                    prefix={`${window.location.origin.split('//')[1]}/`}
                    initialValue={currentUser.myLastLink.split('//')[1].split('/')[1]}
                    getUrl={(value) => `/api/v1/users/mylastlink/${value}/exists`}
                    validate={(isValid) => setSuffixValid(isValid)}
                    error={errors.lastLinkSuffix}
                />
            </Block>
            <Block
                title={localization.profileCompany}
            >
                <Input
                    value={formData.organization}
                    error={errors.organization}
                    onChange={(value) => setFormData({...formData, organization: value})}
                    placeholder={localization.profileOrganization}
                />
                <Input
                    value={formData.position}
                    error={errors.position}
                    onChange={(value) => setFormData({...formData, position: value})}
                    placeholder={localization.profilePosition}
                />
                <LogoUpload
                    value={formData.logo}
                    onChange={(value) => setFormData({...formData, logo: value})}
                    placeholder={localization.profileUploadLogo}
                />
            </Block>
            <Block
                title={localization.profileContacts}
            >
                <div className='lock-block'>
                    <img alt='lock' src='/images/lock.svg' />
                    <div>
                        {localization.profileContactsDescription}
                    </div>
                </div>
                <LinkInput
                    image={(<img alt='linkedin' src='/images/linkedin.svg' />)}
                    placeholder={`Linkedin ${localization.url}`}
                    prefix={linkPrefixes[0]}
                    isShowPrefix
                    error={!formData.linkedInProfileLink && errors.linkedInProfileLink}
                    value={formData.linkedInProfileLink}
                    onChange={(value) => setFormData({...formData, linkedInProfileLink: value})}
                />
                {(socialLinks[linkTypes[1]] || []).map((item, index) => (
                    <LinkInput
                        key={index}
                        image={(<img alt='twitter' src='/images/twitter.svg' />)}
                        placeholder={`Twitter ${localization.handle}`}
                        value={item.value}
                        prefix={linkPrefixes[1]}
                        onChange={(value) => setSocialLinks({
                            ...socialLinks, ...{[linkTypes[1]]: socialLinks[linkTypes[1]].map((element, elementIndex) => (elementIndex === index ? ({...element, ...{value}}) : element))}
                        })}
                        addMore={() => setSocialLinks({...socialLinks, ...{[linkTypes[1]]: [...socialLinks[linkTypes[1]], {type: linkTypes[1]}]}})}
                    />
                ))}
                {(socialLinks[linkTypes[2]] || []).map((item, index) => (
                    <LinkInput
                        key={index}
                        image={(<img alt='instagram' src='/images/instagram.png' />)}
                        placeholder={`Instagram ${localization.handle}`}
                        value={item.value}
                        prefix={linkPrefixes[2]}
                        onChange={(value) => setSocialLinks({
                            ...socialLinks, ...{[linkTypes[2]]: socialLinks[linkTypes[2]].map((element, elementIndex) => (elementIndex === index ? ({...element, ...{value}}) : element))}
                        })}
                        addMore={() => setSocialLinks({...socialLinks, ...{[linkTypes[2]]: [...socialLinks[linkTypes[2]], {type: linkTypes[2]}]}})}
                    />
                ))}
                {(socialLinks[linkTypes[3]] || []).map((item, index) => (
                    <LinkInput
                        key={index}
                        image={(<img alt='facebook' src='/images/facebook.svg' />)}
                        placeholder={`Facebook ${localization.url}`}
                        value={item.value}
                        prefix={linkPrefixes[3]}
                        onChange={(value) => setSocialLinks({
                            ...socialLinks, ...{[linkTypes[3]]: socialLinks[linkTypes[3]].map((element, elementIndex) => (elementIndex === index ? ({...element, ...{value: value.replace(linkPrefixes[3], '').replace('/', '')}}) : element))}
                        })}
                        addMore={() => setSocialLinks({...socialLinks, ...{[linkTypes[3]]: [...socialLinks[linkTypes[3]], {type: linkTypes[3]}]}})}
                    />
                ))}
                {(socialLinks[linkTypes[4]] || []).map((item, index) => (
                    <LinkInput
                        key={index}
                        image={(
                            <div className='flex-between'>
                                <img alt='phone' src='/images/mobilenumber.png' />
                                <CodeSelect
                                    value={item.value ? item.value.split(' ')[0] : null}
                                    onChange={({value}) => setSocialLinks({
                                        ...socialLinks, ...{[linkTypes[4]]: socialLinks[linkTypes[4]].map((element, elementIndex) => (elementIndex === index ? ({...element, ...{value: `${value} ${(item.value || '').split(' ')[1] || ''}`}}) : element))}
                                    })}
                                />
                            </div>
                        )}
                        isShifted
                        placeholder={localization.phoneNumber}
                        value={item.value ? item.value.split(' ')[1] : null}
                        prefix={linkPrefixes[4]}
                        onChange={(value) => setSocialLinks({
                            ...socialLinks, ...{[linkTypes[4]]: socialLinks[linkTypes[4]].map((element, elementIndex) => (elementIndex === index ? ({...element, ...{value:`${(item.value || '').split(' ')[0] || ''} ${value}`}}) : element))}
                        })}
                        addMore={() => setSocialLinks({...socialLinks, ...{[linkTypes[4]]: [...socialLinks[linkTypes[4]], {type: linkTypes[4]}]}})}
                    />
                ))}
                {(socialLinks[linkTypes[5]] || []).map((item, index) => (
                    <LinkInput
                        key={index}
                        image={(<img alt='mail' src='/images/mail.svg' />)}
                        placeholder={localization.emailAddress}
                        value={item.value}
                        prefix={linkPrefixes[5]}
                        onChange={(value) => setSocialLinks({
                            ...socialLinks, ...{[linkTypes[5]]: socialLinks[linkTypes[5]].map((element, elementIndex) => (elementIndex === index ? ({...element, ...{value}}) : element))}
                        })}
                        addMore={() => setSocialLinks({...socialLinks, ...{[linkTypes[5]]: [...socialLinks[linkTypes[5]], {type: linkTypes[5]}]}})}
                    />
                ))}
                {(socialLinks[linkTypes[6]] || []).map((item, index) => (
                    <LinkInput
                        key={index}
                        image={(<img alt='skype' src='/images/skype.svg' />)}
                        placeholder={`Skype ${localization.username}`}
                        value={item.value}
                        prefix={linkPrefixes[6]}
                        onChange={(value) => setSocialLinks({
                            ...socialLinks, ...{[linkTypes[6]]: socialLinks[linkTypes[6]].map((element, elementIndex) => (elementIndex === index ? ({...element, ...{value}}) : element))}
                        })}
                        addMore={() => setSocialLinks({...socialLinks, ...{[linkTypes[6]]: [...socialLinks[linkTypes[6]], {type: linkTypes[6]}]}})}
                    />
                ))}
                {(socialLinks[linkTypes[7]] || []).map((item, index) => (
                    <LinkInput
                        key={index}
                        image={(<img alt='website' src='/images/website.png' />)}
                        placeholder={`Website ${localization.url}`}
                        value={item.value}
                        prefix={linkPrefixes[7]}
                        onChange={(value) => setSocialLinks({
                            ...socialLinks, ...{[linkTypes[7]]: socialLinks[linkTypes[7]].map((element, elementIndex) => (elementIndex === index ? ({...element, ...{value}}) : element))}
                        })}
                        addMore={() => setSocialLinks({...socialLinks, ...{[linkTypes[7]]: [...socialLinks[linkTypes[7]], {type: linkTypes[7]}]}})}
                    />
                ))}
                {(socialLinks[linkTypes[8]] || []).map((item, index) => (
                    <LinkInput
                        key={index}
                        image={(<img alt='blog' src='/images/blog.png' />)}
                        placeholder={`Blog ${localization.url}`}
                        value={item.value}
                        prefix={linkPrefixes[8]}
                        onChange={(value) => setSocialLinks({
                            ...socialLinks, ...{[linkTypes[8]]: socialLinks[linkTypes[8]].map((element, elementIndex) => (elementIndex === index ? ({...element, ...{value}}) : element))}
                        })}
                        addMore={() => setSocialLinks({...socialLinks, ...{[linkTypes[8]]: [...socialLinks[linkTypes[8]], {type: linkTypes[8]}]}})}
                    />
                ))}
                {(socialLinks[linkTypes[9]] || []).map((item, index) => (
                    <LinkInput
                        key={index}
                        image={(<img alt='blog' src='/images/snapchat.jpg' />)}
                        placeholder={`Snapchat ${localization.url}`}
                        value={item.value}
                        prefix={linkPrefixes[9]}
                        onChange={(value) => setSocialLinks({
                            ...socialLinks, ...{[linkTypes[9]]: socialLinks[linkTypes[9]].map((element, elementIndex) => (elementIndex === index ? ({...element, ...{value: value.replace(linkPrefixes[9], '').replace('/', '')}}) : element))}
                        })}
                        addMore={() => setSocialLinks({...socialLinks, ...{[linkTypes[9]]: [...socialLinks[linkTypes[9]], {type: linkTypes[9]}]}})}
                    />
                ))}
                {(socialLinks[linkTypes[10]] || []).map((item, index) => (
                    <LinkInput
                        key={index}
                        image={(<img alt='blog' src='/images/medium.png' />)}
                        placeholder={`Medium ${localization.url}`}
                        value={item.value}
                        prefix={linkPrefixes[10]}
                        onChange={(value) => setSocialLinks({
                            ...socialLinks, ...{[linkTypes[10]]: socialLinks[linkTypes[10]].map((element, elementIndex) => (elementIndex === index ? ({...element, ...{value}}) : element))}
                        })}
                        addMore={() => setSocialLinks({...socialLinks, ...{[linkTypes[10]]: [...socialLinks[linkTypes[10]], {type: linkTypes[10]}]}})}
                    />
                ))}
                {(socialLinks[linkTypes[11]] || []).map((item, index) => (
                    <LinkInput
                        key={index}
                        image={(<img alt='blog' src='/images/pinterest.png' />)}
                        placeholder={`Pinterest ${localization.url}`}
                        value={item.value}
                        prefix={linkPrefixes[11]}
                        onChange={(value) => setSocialLinks({
                            ...socialLinks, ...{[linkTypes[11]]: socialLinks[linkTypes[11]].map((element, elementIndex) => (elementIndex === index ? ({...element, ...{value}}) : element))}
                        })}
                        addMore={() => setSocialLinks({...socialLinks, ...{[linkTypes[11]]: [...socialLinks[linkTypes[11]], {type: linkTypes[11]}]}})}
                    />
                ))}
                {(socialLinks[linkTypes[12]] || []).map((item, index) => (
                    <LinkInput
                        key={index}
                        image={(
                            <div className='flex-between'>
                                <img alt='phone' src='/images/wechat.png' />
                                <CodeSelect
                                    value={item.value ? item.value.split(' ')[0] : null}
                                    onChange={({value}) => setSocialLinks({
                                        ...socialLinks, ...{[linkTypes[12]]: socialLinks[linkTypes[12]].map((element, elementIndex) => (elementIndex === index ? ({...element, ...{value: `${value} ${(item.value || '').split(' ')[1] || ''}`}}) : element))}
                                    })}
                                />
                            </div>
                        )}
                        isShifted
                        placeholder='WeChat'
                        value={item.value ? item.value.split(' ')[1] : null}
                        prefix={linkPrefixes[12]}
                        onChange={(value) => setSocialLinks({
                            ...socialLinks, ...{[linkTypes[12]]: socialLinks[linkTypes[12]].map((element, elementIndex) => (elementIndex === index ? ({...element, ...{value:`${(item.value || '').split(' ')[0] || ''} ${value}`}}) : element))}
                        })}
                        addMore={() => setSocialLinks({...socialLinks, ...{[linkTypes[12]]: [...socialLinks[linkTypes[12]], {type: linkTypes[12]}]}})}
                    />
                ))}
                {(socialLinks[linkTypes[13]] || []).map((item, index) => (
                    <LinkInput
                        key={index}
                        image={(
                            <div className='flex-between'>
                                <img alt='phone' src='/images/whatsapp.png' />
                                <CodeSelect
                                    value={item.value ? item.value.split(' ')[0] : null}
                                    onChange={({value}) => setSocialLinks({
                                        ...socialLinks, ...{[linkTypes[13]]: socialLinks[linkTypes[13]].map((element, elementIndex) => (elementIndex === index ? ({...element, ...{value: `${value} ${(item.value || '').split(' ')[1] || ''}`}}) : element))}
                                    })}
                                />
                            </div>
                        )}
                        isShifted
                        placeholder='WatsApp'
                        value={item.value ? item.value.split(' ')[1] : null}
                        prefix={linkPrefixes[13]}
                        onChange={(value) => setSocialLinks({
                            ...socialLinks, ...{[linkTypes[13]]: socialLinks[linkTypes[13]].map((element, elementIndex) => (elementIndex === index ? ({...element, ...{value:`${(item.value || '').split(' ')[0] || ''} ${value}`}}) : element))}
                        })}
                        addMore={() => setSocialLinks({...socialLinks, ...{[linkTypes[13]]: [...socialLinks[linkTypes[13]], {type: linkTypes[13]}]}})}
                    />
                ))}
                {(socialLinks[linkTypes[14]] || []).map((item, index) => (
                    <LinkInput
                        key={index}
                        image={(<img alt='blog' src='/images/youtube.png' />)}
                        placeholder={`YouTube ${localization.url}`}
                        value={item.value}
                        prefix={linkPrefixes[14]}
                        onChange={(value) => setSocialLinks({
                            ...socialLinks, ...{[linkTypes[14]]: socialLinks[linkTypes[14]].map((element, elementIndex) => (elementIndex === index ? ({...element, ...{value}}) : element))}
                        })}
                        addMore={() => setSocialLinks({...socialLinks, ...{[linkTypes[14]]: [...socialLinks[linkTypes[14]], {type: linkTypes[14]}]}})}
                    />
                ))}
                {(socialLinks[linkTypes[15]] || []).map((item, index) => (
                    <LinkInput
                        key={index}
                        image={(<img alt='blog' src='/images/calendly.png' />)}
                        placeholder={`Calendly ${localization.url}`}
                        value={item.value}
                        prefix={linkPrefixes[15]}
                        onChange={(value) => setSocialLinks({
                            ...socialLinks, ...{[linkTypes[15]]: socialLinks[linkTypes[15]].map((element, elementIndex) => (elementIndex === index ? ({...element, ...{value}}) : element))}
                        })}
                        addMore={() => setSocialLinks({...socialLinks, ...{[linkTypes[15]]: [...socialLinks[linkTypes[15]], {type: linkTypes[15]}]}})}
                    />
                ))}
                {(socialLinks[linkTypes[16]] || []).map((item, index) => (
                    <LinkInput
                        key={index}
                        image={(<img alt='blog' src='/images/researchgate.png' />)}
                        placeholder={`Researchgate ${localization.url}`}
                        value={item.value}
                        prefix={linkPrefixes[16]}
                        onChange={(value) => setSocialLinks({
                            ...socialLinks, ...{[linkTypes[16]]: socialLinks[linkTypes[16]].map((element, elementIndex) => (elementIndex === index ? ({...element, ...{value}}) : element))}
                        })}
                        addMore={() => setSocialLinks({...socialLinks, ...{[linkTypes[16]]: [...socialLinks[linkTypes[16]], {type: linkTypes[16]}]}})}
                    />
                ))}
                {(socialLinks[linkTypes[17]] || []).map((item, index) => (
                    <LinkInput
                        key={index}
                        image={(<img alt='blog' src='/images/angellist.jpg' />)}
                        placeholder={`Angellist ${localization.url}`}
                        value={item.value}
                        prefix={linkPrefixes[17]}
                        onChange={(value) => setSocialLinks({
                            ...socialLinks, ...{[linkTypes[17]]: socialLinks[linkTypes[17]].map((element, elementIndex) => (elementIndex === index ? ({...element, ...{value}}) : element))}
                        })}
                        addMore={() => setSocialLinks({...socialLinks, ...{[linkTypes[17]]: [...socialLinks[linkTypes[17]], {type: linkTypes[17]}]}})}
                    />
                ))}
                {(socialLinks[linkTypes[18]] || []).map((item, index) => (
                    <LinkInput
                        key={index}
                        image={(
                            <div className='flex-between'>
                                <img alt='phone' src='/images/desknumber.png' />
                                <CodeSelect
                                    value={item.value ? item.value.split(' ')[0] : null}
                                    onChange={({value}) => setSocialLinks({
                                        ...socialLinks, ...{[linkTypes[18]]: socialLinks[linkTypes[18]].map((element, elementIndex) => (elementIndex === index ? ({...element, ...{value: `${value} ${(item.value || '').split(' ')[1] || ''}`}}) : element))}
                                    })}
                                />
                            </div>
                        )}
                        isShifted
                        placeholder={localization.descNumber}
                        value={item.value ? item.value.split(' ')[1] : null}
                        prefix={linkPrefixes[18]}
                        onChange={(value) => setSocialLinks({
                            ...socialLinks, ...{[linkTypes[18]]: socialLinks[linkTypes[18]].map((element, elementIndex) => (elementIndex === index ? ({...element, ...{value:`${(item.value || '').split(' ')[0] || ''} ${value}`}}) : element))}
                        })}
                        addMore={() => setSocialLinks({...socialLinks, ...{[linkTypes[18]]: [...socialLinks[linkTypes[18]], {type: linkTypes[18]}]}})}
                    />
                ))}
            </Block>
            <Block>
                <button
                    className='submit-button'
                    onClick={() => {
                        const data = {
                            ...formData,
                            linkedInProfileLink: formData.linkedInProfileLink,
                            myLastLinkSuffix: formData.lastLinkSuffix,
                            socialLinks: linkTypes.reduce((array, linkType, index) => {
                                socialLinks[linkType].forEach((item) => {
                                    if (!item.id && item.value) {
                                        array.push({
                                            url: `${linkPrefixes[index]}${item.value}`,
                                            type: linkType,
                                            actionType: 'INSERT'
                                        });
                                    }
                                    if (item.id && item.value) {
                                        const url = `${linkPrefixes[index]}${item.value}`;
                                        if (item.url !== url) {
                                            array.push({
                                                id: item.id,
                                                url,
                                                type: linkType,
                                                actionType: 'UPDATE'
                                            });
                                        }
                                    }
                                    if (item.id && !item.value) {
                                        array.push({
                                            id: item.id,
                                            actionType: 'DELETE'
                                        });
                                    }
                                });
                                return array;
                            }, [])
                        };
                        const errors = ['firstName', 'lastName', 'organization', 'position', 'location'].filter((item) => !data[item]).reduce((obj, item) => {
                            obj[item] = localization.mandatory;
                            return obj;
                        }, {});
                        if (!formData.firstName || !/^\p{L}+$/u.test(formData.firstName) || formData.firstName.length > 60) {
                            errors.firstName = localization.notValid;
                        }
                        if (!formData.lastName || !/^\p{L}+$/u.test(formData.lastName) || formData.lastName.length > 60) {
                            errors.lastName = localization.notValid;
                        }
                        if (!formData.location || formData.location.length > 100) {
                            errors.location = localization.notValid;
                        }
                        if (!formData.organization || formData.organization.length > 100) {
                            errors.organization = localization.notValid;
                        }
                        if (!formData.position || formData.position.length > 100) {
                            errors.position = localization.notValid;
                        }
                        if (!formData.lastLinkSuffix || !/^\w+$/.test(formData.lastLinkSuffix) || formData.lastLinkSuffix.length > 32) {
                            errors.lastLinkSuffix = localization.notValid;
                        }
                        setErrors(errors);
                        if (!Object.keys(errors).length && suffixValid) {
                            api.put(`/api/v1/users`, data).then(({data}) => {
                                setCurrentUser(data);
                                backAction();
                            });
                        }
                    }}
                >
                    {localization.save}
                </button>
            </Block>
        </div>
    );
};

export default EditProfile;