import React from 'react';
import {linkTypes} from "../../utils/constants";
import Person from "../content/Person";
import localization from "../../localization";

const SocialLinks = ({socialLinks}) => {
    return (
        <>
            {(socialLinks || []).filter((item) => item.type === linkTypes[0]).map((item, index) => (
                <a href={item.url} target='_blank'>
                    <Person
                        key={index}
                        image='/images/linkedin.svg'
                        title='Linkedin'
                        isBlackDescription
                        isImageNotRounded
                        isSmaller
                    />
                </a>
            ))}
            {(socialLinks || []).filter((item) => item.type === linkTypes[1]).map((item, index) => (
                <a href={item.url} target='_blank'>
                    <Person
                        key={index}
                        image='/images/twitter.svg'
                        title='Twitter'
                        isBlackDescription
                        isImageNotRounded
                        isSmaller
                    />
                </a>
            ))}
            {(socialLinks || []).filter((item) => item.type === linkTypes[2]).map((item, index) => (
                <a href={item.url} target='_blank'>
                    <Person
                        key={index}
                        image='/images/instagram.png'
                        title='Instagram'
                        isBlackDescription
                        isImageNotRounded
                        isSmaller
                    />
                </a>
            ))}
            {(socialLinks || []).filter((item) => item.type === linkTypes[3]).map((item, index) => (
                <a href={item.url} target='_blank'>
                    <Person
                        key={index}
                        image='/images/facebook.svg'
                        title='Facebook'
                        isBlackDescription
                        isImageNotRounded
                        isSmaller
                    />
                </a>
            ))}
            {(socialLinks || []).filter((item) => item.type === linkTypes[4]).map((item, index) => (
                item.url && item.url.split(' ')[1] ? (
                    <a href={`tel:${item.url.replace(' ', '')}`} target='_blank'>
                        <Person
                            key={index}
                            image='/images/mobilenumber.png'
                            title={localization.phoneNumber}
                            isBlackDescription
                            isImageNotRounded
                            isSmaller
                        />
                    </a>
                ) : null
            ))}
            {(socialLinks || []).filter((item) => item.type === linkTypes[5]).map((item, index) => (
                <a href={`mailto:${item.url}`}>
                    <Person
                        key={index}
                        image='/images/mail.svg'
                        title={localization.emailAddress}
                        isBlackDescription
                        isImageNotRounded
                        isSmaller
                    />
                </a>
            ))}
            {(socialLinks || []).filter((item) => item.type === linkTypes[6]).map((item, index) => (
                <Person
                    key={index}
                    image='/images/skype.svg'
                    title='Skype'
                    isBlackDescription
                    isImageNotRounded
                    isSmaller
                />
            ))}
            {(socialLinks || []).filter((item) => item.type === linkTypes[7]).map((item, index) => (
                <a href={(item.url.indexOf('http://') < 0 && item.url.indexOf('https://') < 0) ? `https://${item.url}` : item.url} target='_blank'>
                    <Person
                        key={index}
                        image='/images/website.png'
                        title='Website'
                        isBlackDescription
                        isImageNotRounded
                        isSmaller
                    />
                </a>
            ))}
            {(socialLinks || []).filter((item) => item.type === linkTypes[8]).map((item, index) => (
                <a href={(item.url.indexOf('http://') < 0 && item.url.indexOf('https://') < 0) ? `https://${item.url}` : item.url} target='_blank'>
                    <Person
                        key={index}
                        image='/images/blog.png'
                        title='Blog'
                        isBlackDescription
                        isImageNotRounded
                        isSmaller
                    />
                </a>
            ))}
            {(socialLinks || []).filter((item) => item.type === linkTypes[9]).map((item, index) => (
                <a href={item.url} target='_blank'>
                    <Person
                        key={index}
                        image='/images/snapchat.jpg'
                        title='Snapchat'
                        isBlackDescription
                        isImageNotRounded
                        isSmaller
                    />
                </a>
            ))}
            {(socialLinks || []).filter((item) => item.type === linkTypes[10]).map((item, index) => (
                <a href={item.url} target='_blank'>
                    <Person
                        key={index}
                        image='/images/medium.png'
                        title='Medium'
                        isBlackDescription
                        isImageNotRounded
                        isSmaller
                    />
                </a>
            ))}
            {(socialLinks || []).filter((item) => item.type === linkTypes[11]).map((item, index) => (
                <a href={item.url} target='_blank'>
                    <Person
                        key={index}
                        image='/images/pinterest.png'
                        title='Pinterst'
                        isBlackDescription
                        isImageNotRounded
                        isSmaller
                    />
                </a>
            ))}
            {(socialLinks || []).filter((item) => item.type === linkTypes[12]).map((item, index) => (
                item.url && item.url.split(' ')[1] ? (
                    <a href={`tel:${item.url.replace(' ', '')}`} target='_blank'>
                        <Person
                            key={index}
                            image='/images/wechat.png'
                            title='WeChat'
                            isBlackDescription
                            isImageNotRounded
                            isSmaller
                        />
                    </a>
                ) : null
            ))}
            {(socialLinks || []).filter((item) => item.type === linkTypes[13]).map((item, index) => (
                item.url && item.url.split(' ')[1] ? (
                    <a href={`tel:${item.url.replace(' ', '')}`} target='_blank'>
                        <Person
                            key={index}
                            image='/images/whatsapp.png'
                            title='WatsApp'
                            isBlackDescription
                            isImageNotRounded
                            isSmaller
                        />
                    </a>
                ) : null
            ))}
            {(socialLinks || []).filter((item) => item.type === linkTypes[14]).map((item, index) => (
                <a href={item.url} target='_blank'>
                    <Person
                        key={index}
                        image='/images/youtube.png'
                        title='YouTube'
                        isBlackDescription
                        isImageNotRounded
                        isSmaller
                    />
                </a>
            ))}
            {(socialLinks || []).filter((item) => item.type === linkTypes[15]).map((item, index) => (
                <a href={item.url} target='_blank'>
                    <Person
                        key={index}
                        image='/images/calendly.png'
                        title='Calendly'
                        isBlackDescription
                        isImageNotRounded
                        isSmaller
                    />
                </a>
            ))}
            {(socialLinks || []).filter((item) => item.type === linkTypes[16]).map((item, index) => (
                <a href={item.url} target='_blank'>
                    <Person
                        key={index}
                        image='/images/researchgate.png'
                        title='Researchgate'
                        isBlackDescription
                        isImageNotRounded
                        isSmaller
                    />
                </a>
            ))}
            {(socialLinks || []).filter((item) => item.type === linkTypes[17]).map((item, index) => (
                <a href={item.url} target='_blank'>
                    <Person
                        key={index}
                        image='/images/angellist.jpg'
                        title='Angellist'
                        isBlackDescription
                        isImageNotRounded
                        isSmaller
                    />
                </a>
            ))}
            {(socialLinks || []).filter((item) => item.type === linkTypes[18]).map((item, index) => (
                item.url && item.url.split(' ')[1] ? (
                    <a href={`tel:${item.url.replace(' ', '')}`} target='_blank'>
                        <Person
                            key={index}
                            image='/images/desknumber.png'
                            title={localization.descNumber}
                            isBlackDescription
                            isImageNotRounded
                            isSmaller
                        />
                    </a>
                ) : null
            ))}
        </>
    );
};

export default SocialLinks;