import React, {useContext, useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import classNames from 'classnames';
import {Route, withRouter} from "react-router-dom";
import Page from "../../components/page/Page";
import PageContent from "../../components/page/PageContent";
import {store} from "../../store";
import api from "../../api";
import MessagingActions from "../../components/messaging/MessagingActions";
import Messaging from "../../components/messaging/Messaging";
import localization from "../../localization";
import {addHttpsToLink, createLink} from "../../utils/linkUtil";
import Avatar from "../../components/content/Avatar";
import ProfileDetails from "../../components/profile/ProfileDetails";
import momentTimezone from "moment-timezone";
import CreatePost from "./CreatePost";
import Answers from "./Answers";
import Dropdown from "../../components/content/Dropdown";



const Forum = ({history}) => {
    const [messages, setMessages] = useState([]);
    const [selectedPersonId, setSelectedPersonId] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isHost, setIsHost] = useState(false);
    const [selectedMessage, selectMessage] = useState(null);
    const [eventParticipants, setEventParticipants] = useState([]);
    const {currentUser} = useContext(store);

    const webinarId = 3;

    useEffect(() => {
        api.get(`/api/v1/event/${webinarId}/participants`).then(({data}) => {
            setEventParticipants(data);
            data.forEach((item) => {
                if (item.host && item.participantUser.id === currentUser.id) {
                    setIsHost(true);
                }
            });
            const eventParticipantss = data;
            api.get(`/api/v1/event/${webinarId}/qna`).then(({data}) => {
                setMessages(data.map((item) => ({
                    ...item,
                    body: item.message,
                    timestamp: item.createdDate,
                    user: (eventParticipantss.filter(({participantUser}) => participantUser.id === item.userId)[0] || {}).participantUser || {},
                    isCreator: currentUser.id === item.userId
                })));
            });
        });
    }, []);
    messages.sort((a, b) => {
            let comparison = 0;
            if (a.likedCount > b.likedCount) {
                comparison = -1;
            } else if (a.likedCount < b.likedCount) {
                comparison = 1;
            }
            return comparison;
        }
    );

    return (
        <Page
            className='forum-page'
        >
            <Helmet>
                <title>Forum - All Posts | {process.env.REACT_APP_PROJECT_NAME}</title>
                <meta name="description" content="Forum - All Posts" />
            </Helmet>
            {eventParticipants.filter((item) => item.participantUser.id === currentUser.id).length ? (
                <PageContent
                    title={`Forum - All Posts`}
                    dialog={selectedPersonId ? (
                        <ProfileDetails
                            personId={selectedPersonId}
                            defaultTab={0}
                            backAction={() => {
                                setSelectedPersonId(null);
                            }}
                        />
                    ) : null}
                    action={(
                        <button className='action-button' onClick={() => setIsOpen(true)}>
                            New Post +
                        </button>
                    )}
                    noBackOnDesktop
                >
                    <div className='messages'>
                        {messages.map((message) => (
                            <div className='message'>
                                <button
                                    className='message--like'
                                    onClick={() => {
                                        api.post(`/api/v1/event/${webinarId}/qna/${message.id}/like-unlike`, {userId: currentUser.id, liked: !message.loggedInUserLiked}).then(({data}) => {
                                            setMessages(messages.map((item) => item.id === message.id ? ({
                                                ...item,
                                                loggedInUserLiked: !item.loggedInUserLiked,
                                                likedCount: item.loggedInUserLiked ? (item.likedCount - 1) : (item.likedCount + 1)
                                            }) : item));
                                        });
                                    }}
                                >
                                    {message.loggedInUserLiked ? (
                                        <img alt='like' src='/images/like-arrow.png' />
                                    ) : (
                                        <img alt='like' src='/images/like-arrow-inactive.png' />
                                    )}
                                    {message.likedCount}
                                </button>
                                <div>
                                    <div className='message--body'>
                                        <div dangerouslySetInnerHTML={{__html: addHttpsToLink(createLink((message.body || '').replace(/style="[^"]*"/g, '')))}}/>
                                    </div>
                                    <div className='message--info'>
                                        <div>{`${message.user.firstName} ${message.user.lastName}`}</div>
                                        <div className='message--info--separator'>•</div>
                                        <div>{momentTimezone(message.timestamp).tz(currentUser.timeZoneId).format('DD MMM YYYY')}</div>
                                        <button
                                            className='message--answers'
                                            onClick={() => selectMessage(message)}
                                        >
                                            <img alt='chat' src='/images/answers.svg' />
                                            <div className='message--info--separator'></div>
                                            <div>{message.childThreads.length} Answers</div>
                                        </button>
                                    </div>
                                    {isHost ? (
                                        <button className='message--hover'>
                                            <Dropdown
                                                button={(<img alt='elipses' src='/images/elipses.svg' />)}
                                            >
                                                <button
                                                    onClick={() => {
                                                        api.delete(`/api/v1/event/${webinarId}/qna/${message.id}`).then(({data}) => {
                                                            const updated = messages.filter(({id}) => message.id !== id);
                                                            setMessages(updated);
                                                        });
                                                    }}
                                                >
                                                    Delete
                                                </button>
                                            </Dropdown>
                                        </button>
                                    ) : null}
                                </div>
                            </div>
                        ))}
                    </div>
                    {selectedMessage ? (
                        <Answers
                            selectedMessage={selectedMessage}
                            webinarId={webinarId}
                            webinarName='Answers'
                            eventParticipants={eventParticipants}
                            isHost={isHost}
                            backAction={() => selectMessage(null)}
                            selectPerson={setSelectedPersonId}
                        />
                    ) : null}
                </PageContent>
                ) : eventParticipants.length ? (
                <div className='tables-empty'>
                    <div className='tables-empty--content'>
                        <div className='tables-empty--image'>
                            <img alt='lock' src='/images/no-access.png' />
                        </div>
                        <div className='tables-empty--title'>Restricted Access</div>
                        <div className='tables-empty--subtitle'>This area is open only to Participants and Administrators of 2021 Cohort</div>
                    </div>
                </div>
            ) : null}
            {isOpen ? (
                <CreatePost
                    handleClose={() => setIsOpen(false)}
                    sendMessage={(message) => {
                        api.post(`/api/v1/event/${webinarId}/qna`, {userId: currentUser.id, message}).then(({data}) => {
                            setMessages([...messages, {
                                ...data,
                                body: data.message,
                                timestamp: data.createdDate,
                                user: currentUser
                            }])
                        });
                    }}
                />
            ) : null}
        </Page>
    );
};

export default withRouter(Forum);