import React, {useContext, useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import classNames from 'classnames';
import {Route, withRouter} from "react-router-dom";
import Page from "../../components/page/Page";
import PageContent from "../../components/page/PageContent";
import {store} from "../../store";
import api from "../../api";
import MessagingActions from "../../components/messaging/MessagingActions";
import Messaging from "../../components/messaging/Messaging";
import localization from "../../localization";
import {addHttpsToLink, createLink} from "../../utils/linkUtil";
import Avatar from "../../components/content/Avatar";
import ProfileDetails from "../../components/profile/ProfileDetails";
import momentTimezone from "moment-timezone";
import CreateAnouncement from "./CreateAnouncement";
import Dropdown from "../../components/content/Dropdown";



const Announce = ({history}) => {
    const [messages, setMessages] = useState([]);
    const [selectedPersonId, setSelectedPersonId] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isHost, setIsHost] = useState(false);
    const [eventParticipants, setEventParticipants] = useState([]);
    const {currentUser} = useContext(store);

    const webinarId = 2;

    useEffect(() => {
        api.get(`/api/v1/event/${webinarId}/participants`).then(({data}) => {
            setEventParticipants(data);
            data.forEach((item) => {
               if (item.host && item.participantUser.id === currentUser.id) {
                   setIsHost(true);
               }
            });
            const eventParticipantss = data;
            api.get(`/api/v1/event/${webinarId}/qna`).then(({data}) => {
                setMessages(data.map((item) => ({
                    ...item,
                    body: item.message,
                    timestamp: item.createdDate,
                    user: (eventParticipantss.filter(({participantUser}) => participantUser.id === item.userId)[0] || {}).participantUser || {},
                    isCreator: currentUser.id === item.userId
                })));
            });
        });
    }, []);
    messages.sort((a, b) => {
            let comparison = 0;
            if (new Date(a.createdDate).getTime() > new Date(b.createdDate).getTime()) {
                comparison = -1;
            } else if (new Date(a.createdDate).getTime() < new Date(b.createdDate).getTime()) {
                comparison = 1;
            }
            return comparison;
        }
    );
    const newestMessage = messages[0];
    messages.sort((a, b) => {
            let comparison = 0;
            if (a.likedCount > b.likedCount) {
                comparison = -1;
            } else if (a.likedCount < b.likedCount) {
                comparison = 1;
            }
            return comparison;
        }
    );

    return (
        <Page
            className='announcements-page'
        >
            <Helmet>
                <title>Announcements | {process.env.REACT_APP_PROJECT_NAME}</title>
                <meta name="description" content="Announcements" />
            </Helmet>
            {eventParticipants.filter((item) => item.participantUser.id === currentUser.id).length ? (
                    <PageContent
                        title={`Announcements`}
                        dialog={selectedPersonId ? (
                            <ProfileDetails
                                personId={selectedPersonId}
                                defaultTab={0}
                                backAction={() => {
                                    setSelectedPersonId(null);
                                }}
                            />
                        ) : null}
                        action={isHost ? (
                            <button className='action-button' onClick={() => setIsOpen(true)}>
                                New Announcement
                            </button>
                        ) : null}
                        noBackOnDesktop
                    >
                        <div className='messages'>
                            {(messages.length ? [
                                newestMessage,
                                ...messages.filter((item) => item !== newestMessage)
                            ] : []).map((message, index) => (
                                <div key={index} className='message'>
                                    <div className='message--header'>
                                        <div className='message--person'>
                                            <Avatar
                                                className='message--person--image'
                                                image={message.user.image}
                                                fullName={`${message.user.firstName} ${message.user.lastName}`}
                                                onClick={() => setSelectedPersonId && setSelectedPersonId(message.user.id)}
                                            />
                                            <div>
                                                <div>{`${message.user.firstName} ${message.user.lastName}`}</div>
                                                <div>{momentTimezone(message.timestamp).tz(currentUser.timeZoneId).format('DD MMM YYYY • hh:mm A')}</div>
                                            </div>
                                        </div>
                                        <button
                                            className='message--like'
                                            onClick={() => {
                                                api.post(`/api/v1/event/${webinarId}/qna/${message.id}/like-unlike`, {userId: currentUser.id, liked: !message.loggedInUserLiked}).then(({data}) => {
                                                    setMessages(messages.map((item) => item.id === message.id ? ({
                                                        ...item,
                                                        loggedInUserLiked: !item.loggedInUserLiked,
                                                        likedCount: item.loggedInUserLiked ? (item.likedCount - 1) : (item.likedCount + 1)
                                                    }) : item));
                                                });
                                            }}
                                        >
                                            <div className={classNames({'message--like--active': message.loggedInUserLiked})}>{message.likedCount}</div>
                                            {message.loggedInUserLiked ? (
                                                <img alt='like' src='/images/like.png' />
                                            ) : (
                                                <img alt='like' src='/images/like-inactive.png' />
                                            )}
                                        </button>
                                    </div>
                                    <div className='message--body'>
                                        <div dangerouslySetInnerHTML={{__html: addHttpsToLink(createLink((message.body || '').replace(/style="[^"]*"/g, '')))}}/>
                                    </div>
                                    {isHost ? (
                                        <button className='message--hover'>
                                            <Dropdown
                                                button={(<img alt='elipses' src='/images/elipses.svg' />)}
                                            >
                                                <button
                                                    onClick={() => {
                                                        api.delete(`/api/v1/event/${webinarId}/qna/${message.id}`).then(({data}) => {
                                                            const updated = messages.filter(({id}) => message.id !== id);
                                                            setMessages(updated);
                                                        });
                                                    }}
                                                >
                                                    Delete
                                                </button>
                                            </Dropdown>
                                        </button>
                                    ) : null}
                                </div>
                            ))}
                        </div>
                    </PageContent>
                ) : eventParticipants.length ? (
                <div className='tables-empty'>
                    <div className='tables-empty--content'>
                        <div className='tables-empty--image'>
                            <img alt='lock' src='/images/no-access.png' />
                        </div>
                        <div className='tables-empty--title'>Restricted Access</div>
                        <div className='tables-empty--subtitle'>This area is open only to Participants and Administrators of 2021 Cohort</div>
                    </div>
                </div>
            ) : null}
            {isOpen ? (
                <CreateAnouncement
                    handleClose={() => setIsOpen(false)}
                    sendMessage={(message) => {
                        api.post(`/api/v1/event/${webinarId}/qna`, {userId: currentUser.id, message}).then(({data}) => {
                            setMessages([...messages, {
                                ...data,
                                body: data.message,
                                timestamp: data.createdDate,
                                user: currentUser
                            }])
                        });
                    }}
                />
            ) : null}
        </Page>
    );
};

export default withRouter(Announce);